import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import CrewMember from "../../components/CrewMember/CrewMember";
import Loading from "../../components/Loading/Loading";
import _ from "lodash";
import { useEffect } from "react";
import {
  getAddCrewMemberApi,
  addCrewMemberApi,
} from "../../api/CreateiJHA.api";
const AddCrewMemberDynamic = ({
  setLoc,
  getLoc,
  setShowGMap,
  getFormData,
  getIJHForm,
  getNewReport,
  getAllCrewMemData,
  valreq,
}) => {
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [leadMemberType, setleadMemberType] = useState("");
  const [getKey, setKey] = useState(0);
  const [getLoading, setLoading] = useState(false);
  const [getErrMsg, setErrMsg] = useState({ msg: "", cls: "" });
  const [getStatus, setStatus] = useState([
    { freetext: false, dropdown: false },
  ]);
  const [getCrewLeadData, setCrewLeadData] = useState({
    crewmemberdata: [],
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    membertype: "",
    membertype_list: [],
  });
  const [getCrewFormData, setCrewFormData] = useState([
    {
      emailId: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      membertype: "",
    },
  ]);
  const [getLeadMemberType, setLeadMemberType] = useState(false);
  const [getCrewMemberType, setCrewMemberType] = useState(false);
  useEffect(() => {
    if (leadMemberType === "Climber") {
      setShowSuggestion(true);
    }
  }, [leadMemberType]);
  const checkClimberNonClimber = (_getCrewFormData) => {
    let regex_email =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let checkValidation = [];
    _getCrewFormData.map((i, j) => {
      if (
        i.emailId === "" &&
        i.firstName === "" &&
        (i.lastName !== "" || i.lastName === "") &&
        i.membertype === ""
      ) {
        checkValidation.push(true);
      } else if (
        i.emailId !== "" &&
        regex_email.test(String(i.emailId).toLowerCase()) &&
        i.firstName !== "" &&
        (i.lastName !== "" || i.lastName === "") &&
        i.membertype !== ""
      ) {
        checkValidation.push(true);
      } else {
        checkValidation.push(false);
      }
    });
    checkValidation.includes(false)
      ? setCrewMemberType(false)
      : setCrewMemberType(true);
  };

  const addCrewMember = () => {
    let _getCrewFormData = _.cloneDeep(getCrewFormData);
    _getCrewFormData[_getCrewFormData.length] = {
      emailId: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      membertype: "",
    };
    setCrewFormData(_getCrewFormData);
    setKey(_getCrewFormData.length);
    let _getCrewLeadData = _.cloneDeep(getCrewLeadData);
    _getCrewLeadData.crewmemberdata = _getCrewFormData;
    setCrewLeadData(_getCrewLeadData);
  };
  const deleteCrewMember = (_key) => {
    let _getCrewFormData = getCrewFormData.filter((obj, i) => i !== _key);
    setCrewFormData(_getCrewFormData);
    setKey(_getCrewFormData.length);
    let _getCrewLeadData = _.cloneDeep(getCrewLeadData);
    _getCrewLeadData.crewmemberdata = _getCrewFormData;
    setCrewLeadData(_getCrewLeadData);
    checkClimberNonClimber(_getCrewFormData);
  };
  const crewLeadChangeHandler = (_key, _value) => {
    let _getCrewLeadData = _.cloneDeep(getCrewLeadData);
    _getCrewLeadData[_key] = _value;
    setCrewLeadData(_getCrewLeadData);
  };

  const crewMemberChangeHandler = (
    _key,
    _s_key,
    _value,
    firstName,
    lastName
  ) => {
    let _getCrewFormData = _.cloneDeep(getCrewFormData);
    let crewMemberData = _getCrewFormData[_key];
    if (
      firstName !== "" &&
      (lastName !== "" || lastName === "") &&
      firstName !== undefined &&
      lastName !== undefined
    ) {
      crewMemberData.firstName = firstName || "";
      crewMemberData.lastName = lastName || "";
      crewMemberData.emailId = _value;
    } else {
      crewMemberData[_s_key] = _value;
    }
    setCrewFormData(_getCrewFormData);
    setKey(_getCrewFormData.length);
    let _getCrewLeadData = _.cloneDeep(getCrewLeadData);
    _getCrewLeadData.crewmemberdata = _getCrewFormData;
    setCrewLeadData(_getCrewLeadData);
    checkClimberNonClimber(_getCrewFormData);
  };

  useEffect(() => {
    if (!_.isEmpty(getAllCrewMemData)) {
      let _getCrewLeadData = _.cloneDeep(getAllCrewMemData);
      //  _getCrewLeadData.getCrewLeadData.crewmemberdata = _getCrewLeadData.getCrewLeadData.crewmemberdata.filter(obj => delete obj.employeeId)
      setKey(_getCrewLeadData.crewmemberdata.length);
      setCrewLeadData(_getCrewLeadData);
      setCrewFormData(_getCrewLeadData.crewmemberdata);
      if (_getCrewLeadData.membertype !== "") {
        setLeadMemberType(true);
      }
      checkClimberNonClimber(_getCrewLeadData.crewmemberdata);
    } else {
      if (!getNewReport) {
        getAddCrewMemberApi(setCrewLeadData, setLoading, setErrMsg);
        let _getCrewFormData = _.cloneDeep(getCrewFormData);
        checkClimberNonClimber(_getCrewFormData);
      }
    }
  }, [getAllCrewMemData]);

  const proceedToIjha = async () => {
    if (getNewReport) {
      if (getFormData.location_required === false) {
        setLoc({
          lat: getFormData.default_location.lat,
          lng: getFormData.default_location.long,
        });
        let _getCrewFormData = _.cloneDeep(getCrewFormData);
        _getCrewFormData.forEach((a) => delete a.employeeId);
        await addCrewMemberApi(
          !_.isEmpty(getCrewFormData) && getCrewFormData[0].emailId !== ""
            ? _getCrewFormData
            : "",
          setLoading,
          setErrMsg
        );
        const _getCrewLeadOldData = _.cloneDeep(getCrewLeadData);
        let _getCrewLeadData = _.cloneDeep(getCrewLeadData);
        _getCrewLeadData.crewmemberdata = getCrewFormData;
        const _getAddCrewMemberApi = await getAddCrewMemberApi(
          setCrewLeadData,
          setLoading,
          setErrMsg
        );
        const _crewEMPID = _getAddCrewMemberApi.crewmemberdata.map(
          (i) => i.employeeId
        );
        _getCrewLeadData.crewmemberdata.map(
          (i, k) => (i.employeeId = _crewEMPID[k])
        );
        setCrewLeadData(_getCrewLeadData);
        const existingObj = _getCrewLeadData;
        const updatedObj = { ...existingObj, status: getStatus };
        let res = getIJHForm(updatedObj, _getCrewLeadOldData);
      } else {
        if (getLoc.lat !== "" && getLoc.lng !== "") {
          let _getCrewFormData = _.cloneDeep(getCrewFormData);
          _getCrewFormData.forEach((a) => delete a.employeeId);
          await addCrewMemberApi(
            !_.isEmpty(getCrewFormData) && getCrewFormData[0].emailId !== ""
              ? _getCrewFormData
              : "",
            setLoading,
            setErrMsg
          );
          const _getCrewLeadOldData = _.cloneDeep(getCrewLeadData);
          let _getCrewLeadData = _.cloneDeep(getCrewLeadData);
          _getCrewLeadData.crewmemberdata = getCrewFormData;
          const _getAddCrewMemberApi = await getAddCrewMemberApi(
            setCrewLeadData,
            setLoading,
            setErrMsg
          );
          const _crewEMPID = _getAddCrewMemberApi.crewmemberdata.map(
            (i) => i.employeeId
          );
          _getCrewLeadData.crewmemberdata.map(
            (i, k) => (i.employeeId = _crewEMPID[k])
          );
          setCrewLeadData(_getCrewLeadData);
          const existingObj = _getCrewLeadData;
          const updatedObj = { ...existingObj, status: getStatus };
          let res = getIJHForm(updatedObj, _getCrewLeadOldData);
        } else {
          setShowGMap(true);
        }
      }

      // let counter = false;
      // let _getBlankData = _getCrewFormData;
      // _getBlankData = _getBlankData.filter(_blank => {
      //     if (!('employeeId' in _blank)) {
      //         return counter = true;
      //     }
      // });

      // if (counter) {
      //     _getCrewFormData = _getCrewFormData.filter(obj => !('employeeId' in obj));
      //     await addCrewMemberApi(getCrewFormData[0].emailId !== '' ? _getCrewFormData : '', setLoading, setErrMsg);
      //     await getIJHForm(getCrewFormData);
      // }
      // else {
      //     let res = await getIJHForm(getCrewFormData);
      // }
    } else {
      setLoading(true);
      // let _getCrewFormData = _.cloneDeep(getCrewLeadData);
      // _getCrewFormData.forEach(a => delete a.employeeId);
      await addCrewMemberApi(
        !_.isEmpty(getCrewFormData) && getCrewFormData[0].emailId !== ""
          ? getCrewFormData
          : "",
        setLoading,
        setErrMsg
      );
      let _getCrewLeadData = _.cloneDeep(getCrewLeadData);
      _getCrewLeadData.crewmemberdata = getCrewFormData;
      const _getAddCrewMemberApi = await getAddCrewMemberApi(
        setCrewLeadData,
        setLoading,
        setErrMsg
      );
      const _crewEMPID = _getAddCrewMemberApi.crewmemberdata.map(
        (i) => i.employeeId
      );
      const processedEmployeeIds = new Set();
      _getCrewLeadData.crewmemberdata.forEach((leadData) => {
        const matchingAddCrew = _getAddCrewMemberApi.crewmemberdata.find(
          (addCrew) =>
            addCrew.email === leadData.emailId &&
            addCrew.firstName === leadData.firstName
        );
        if (
          matchingAddCrew &&
          !processedEmployeeIds.has(matchingAddCrew.employeeId)
        ) {
          leadData.employeeId = matchingAddCrew.employeeId;
          processedEmployeeIds.add(matchingAddCrew.employeeId);
        }
      });
      setCrewLeadData(_getCrewLeadData);
      const existingObj = _getCrewLeadData;
      const updatedObj = { ...existingObj, status: getStatus };
      let res = await getIJHForm(updatedObj);
    }
  };

  return (
    <Container className="margin-container  ">
      <Row>
        <Col>
          <h4>Crew Details</h4>
        </Col>
      </Row>
      <Row xs={12} className="mb-5">
        <Form>
          <Row
            xs={12}
            className="customCremL m-1 p-3 d-flex justify-content-left shadow-lg rounded text-decoration-none"
          >
            <h5 className="mb-4">Crew Lead Details</h5>
            <Col className="customCol">
              <Form.Group controlId="form-2-1" className="mb-3">
                <Form.Label>
                  <span className="text-danger bold-font">*</span>First Name
                </Form.Label>
                <Form.Control
                  className="rounded-0 hide-blinking-cursor"
                  type="text"
                  value={getCrewLeadData.firstName}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col className="customCol">
              <Form.Group controlId="form-2-2" className="mb-3">
                <Form.Label>
                  <span className="text-danger bold-font">*</span>Last Name
                </Form.Label>
                <Form.Control
                  className="rounded-0 hide-blinking-cursor"
                  type="text"
                  value={getCrewLeadData.lastName}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col className="customCol">
              <Form.Group controlId="form-2-3" className="mb-3">
                <Form.Label>
                  <span className="text-danger bold-font">*</span>Email ID
                </Form.Label>
                <Form.Control
                  className="rounded-0 hide-blinking-cursor"
                  type="text"
                  value={getCrewLeadData.email}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col className="customCol">
              <Form.Group controlId="form-2-4" className="mb-3">
                <Form.Label>
                  <span className="text-danger bold-font"></span>Phone No.
                </Form.Label>
                <Form.Control
                  className="rounded-0 hide-blinking-cursor"
                  type="text"
                  value={getCrewLeadData.phone}
                  disabled
                />
              </Form.Group>
            </Col>
            {getFormData.climber_nonclimber_required !== "N" ? (
              <Col className="customCol">
                <Form.Group controlId="form-2-5" className="mb-3">
                  <Form.Label>
                    <span className="text-danger bold-font">*</span>Member type
                  </Form.Label>
                  {showSuggestion && leadMemberType === "Climber" && (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          All climbers must have PPEs
                        </Tooltip>
                      }
                    >
                      <span className="icon-info">i</span>
                    </OverlayTrigger>
                  )}
                  <Form.Select
                    className={
                      getLeadMemberType
                        ? "rounded-0 hide-blinking-cursor focusedOut"
                        : "rounded-0 hide-blinking-cursor focused"
                    }
                    value={getCrewLeadData.membertype}
                    onChange={(e) => {
                      crewLeadChangeHandler("membertype", e.target.value);
                      setleadMemberType(e.target.value);
                      if (e.target.value === "") {
                        setLeadMemberType(false);
                      } else {
                        setLeadMemberType(true);
                      }
                    }}
                  >
                    <option value="">Select</option>
                    {getCrewLeadData.membertype_list.map((i, k) => (
                      <option key={k} value={i}>
                        {i}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Label className="mt-2 text-danger">
                    {!getLeadMemberType && "This field is required"}
                  </Form.Label>
                </Form.Group>
              </Col>
            ) : (
              ""
            )}
            {/* {getLoading && <Loading width="32" height="32" />} */}
          </Row>
        </Form>
      </Row>

      <Row xs={12} className="shadow-lg m-1 p-3 mb-5 rounded ">
        <h5 className="mb-4">Add Crew Member</h5>
        {/* {getErrMsg.msg !== '' && getErrMsg.msg !== undefined &&
                    < Row className="d-flex justify-content-center">
                        <Col xs={12} lg={6} md={6}>
                            <Alert variant={getErrMsg.cls}
                                onClose={() => setErrMsg({ msg: '', cls: '' })}
                                dismissible>

                                {getErrMsg.msg}

                            </Alert>

                        </Col>
                    </Row>
                } */}
        <Form>
          {getCrewFormData.map((getCrewData, i) => (
            <CrewMember
              EditFlow={
                !_.isEmpty(valreq) ? valreq.crew_member_selection_type : null
              }
              getCrewFormData={getCrewFormData}
              setCrewMemberType={setCrewMemberType}
              setCrewFormData={setCrewFormData}
              setCrewLeadData={setCrewLeadData}
              getCrewLeadData={getCrewLeadData}
              getStatus={getStatus}
              setStatus={setStatus}
              getFormData={getFormData}
              key={i}
              emailId={getCrewData.emailId}
              firstName={getCrewData.firstName}
              lastName={getCrewData.lastName}
              phoneNumber={getCrewData.phoneNumber}
              membertype={getCrewData.membertype}
              crewMemberChangeHandler={crewMemberChangeHandler}
              _key={i}
              deleteCrewMember={deleteCrewMember}
              getKey={getKey}
              membertype_list={getCrewLeadData.membertype_list}
            />
          ))}
          <Row>
            <Col className="d-flex justify-content-center mb-3">
              <Button size={"sm"} disabled={getLoading} onClick={addCrewMember}>
                + Add More
              </Button>
            </Col>
          </Row>

          <Row>
            <Col className="d-flex justify-content-end">
              {/* <Button
                                // variant="success"
                                disabled={getLoading}
                                onClick={saveCrewMember}
                            >Save</Button> */}
              {/* {getErrMsg.cls === 'success' && */}

              <Button
                className="ms-5"
                variant="success"
                disabled={
                  getFormData.climber_nonclimber_required !== "N"
                    ? !getLeadMemberType || !getCrewMemberType
                    : null
                }
                onClick={proceedToIjha}
              >
                Proceed
              </Button>

              {/* } */}
            </Col>
          </Row>
        </Form>
        {getLoading && (
          <Col
            xs={12}
            className="m-5 p-5 d-flex justify-content-center"
            style={{ cursor: "pointer" }}
          >
            <Loading width="32" height="32" />
          </Col>
        )}
      </Row>
    </Container>
  );
};
export default AddCrewMemberDynamic;
