import { URL } from "../utils/Constant";
import { TOKEN } from "../utils/Variable";

export const getCrewmemberdeatils = async () => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };

  const resp = await fetch(URL.GetCrewMemberData, {
    method: "GET",
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    return data.crewdetails;
  }
};
