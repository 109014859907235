import {
  Row,
  Col,
  Form,
  Button,
  ButtonGroup,
  ToggleButton,
  OverlayTrigger,
  Tooltip,
  Nav,
} from "react-bootstrap";
import trashcan from "../../assets/Icons/trashcan.svg";
import { Names, emailValidation } from "../../utils/Helper";
import { getCrewmemberdeatils } from "../../api/CrewMemberdetails.api";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { useState } from "react";
import { useEffect } from "react";
import { UserGroupId } from "../../utils/Variable";
import { isEmpty } from "lodash";
export default function CrewMember({
  setCrewMemberType,
  getStatus,
  setStatus,
  getCrewDataForm,
  getFormData,
  emailId,
  getCrewData,
  firstName,
  lastName,
  phoneNumber,
  membertype,
  crewMemberChangeHandler,
  _key,
  deleteCrewMember,
  getKey,
  membertype_list,
  setCrewFormData,
  EditFlow,
  getCrewFormData,
  setCrewLeadData,
}) {
  const [leadMemberType, setleadMemberType] = useState("");
  const [showSuggestion, setShowSuggestion] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
  } = useForm();

  const [dropdownoptions, setdropdownoptions] = useState([]);
  const [selectedCrewMember, setselectedCrewMember] = useState(null);
  const [showdropdownreq, setshowdropdownreq] = useState(
    getFormData.crew_member_creation[0].dropdown
  );
  const [showfreetextreq, setshowfreetextreq] = useState(
    getFormData.crew_member_creation[0].free_text
  );

  useEffect(() => {
    if (leadMemberType === "Climber") {
      setShowSuggestion(true);
    }
  }, [leadMemberType]);

  useEffect(() => {
    const getApiResponse = async () => {
      const response = await getCrewmemberdeatils();
      const getRes = await response;

      const formattedValue = getRes.map((employee) => ({
        value: employee.email,
        label: `${employee.firstName} ${employee.lastName} <${employee.email}>`,
      }));
      setdropdownoptions(formattedValue);
    };
    if (showdropdownreq) {
      getApiResponse();
    }
  }, []);

  const handleStatusChange = (type) => {
    setCrewFormData((prevdata) => {
      return prevdata.map((crewmember, i) => {
        if (i === _key) {
          return {
            emailId: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            membertype: "",
          };
        }
        return crewmember;
      });
    });
    setCrewMemberType(true);
    setselectedCrewMember(null);
    if (type === "dropdown") {
      setshowdropdownreq(!true);
      setshowfreetextreq(true);
      setStatus((prevstate) => {
        return {
          ...prevstate,
          [`${_key}`]: { dropdown: false, freetext: true },
        };
      });
      setCrewFormData((prevdata) => {
        return prevdata.map((crewmember, i) => {
          if (i === _key) {
            return {
              emailId: "",
              firstName: "",
              lastName: "",
              phoneNumber: "",
              membertype: "",
            };
          }
          return crewmember;
        });
      });
      setCrewLeadData((prevData) => ({
        ...prevData,
        crewmemberdata: [],
        membertype_list: ["Climber", "Non-Climber"],
      }));
    } else {
      setshowfreetextreq(!true);
      setshowdropdownreq(true);
      setStatus((prevstate) => {
        return {
          ...prevstate,
          [`${_key}`]: { freetext: false, dropdown: true },
        };
      });
      setCrewFormData((prevdata) => {
        return prevdata.map((crewmember, i) => {
          if (i === _key) {
            return {
              emailId: "",
              firstName: "",
              lastName: "",
              phoneNumber: "",
              membertype: "",
            };
          }
          return crewmember;
        });
      });
      setCrewLeadData((prevData) => ({
        ...prevData,
        crewmemberdata: [],
        membertype_list: ["Climber", "Non-Climber"],
      }));
    }
  };

  useEffect(() => {
    if (showdropdownreq && showfreetextreq && EditFlow !== null) {
      if (isEmpty(EditFlow)) {
        setStatus((prevstate) => {
          return {
            ...prevstate,
            [`${_key}`]: { freetext: false, dropdown: true },
          };
        });
        setshowfreetextreq(false);
      } else {
        if (!(String(_key) in EditFlow)) {
          EditFlow[_key] = {
            dropdown: true,
            freetext: false,
          };
        }
        if (EditFlow && EditFlow[_key].dropdown) {
          setshowdropdownreq(true);
          setshowfreetextreq(false);
          setStatus((prevstate) => {
            return {
              ...prevstate,
              [`${_key}`]: { freetext: false, dropdown: true },
            };
          });
        } else {
          setshowdropdownreq(false);
          setshowfreetextreq(true);
          setStatus((prevstate) => {
            return {
              ...prevstate,
              [`${_key}`]: { freetext: true, dropdown: false },
            };
          });
        }
      }
    } else if (showdropdownreq && showfreetextreq) {
      setshowdropdownreq(true);
      setshowfreetextreq(false);
      setStatus((prevstate) => {
        return {
          ...prevstate,
          [`${_key}`]: { freetext: false, dropdown: true },
        };
      });
    }
  }, []);

  return (
    <>
      <Row
        xs={12}
        className="mb-5 m-1 p-3 d-flex justify-content-left shadow rounded text-decoration-none"
      >
        {showfreetextreq && (
          <>
            <Col className="customCol">
              <Form.Group controlId="form-2-1" className="mb-3">
                <Form.Label>
                  <span className="text-danger bold-font">*</span>First Name
                </Form.Label>
                <Form.Control
                  className="rounded-0 "
                  type="text"
                  value={firstName || ""}
                  onChange={(e) => {
                    crewMemberChangeHandler(_key, "firstName", e.target.value);
                    if (e.target.value === "") {
                      setError("firstName" + _key, {
                        message: "This field is required",
                      });
                    } else if (Names(e)) {
                      setError("firstName" + _key, {
                        message: "Please enter valid First Name",
                      });
                    } else {
                      setError("firstName" + _key, { message: "" });
                    }
                  }}
                />
                <Form.Label className="mt-2 text-danger">
                  {errors["firstName" + _key] &&
                    errors["firstName" + _key].message}
                </Form.Label>
              </Form.Group>
            </Col>
            <Col className="customCol">
              <Form.Group controlId="form-2-1" className="mb-3">
                <Form.Label>
                  <span className="text-danger bold-font">*</span>Last Name
                </Form.Label>
                <Form.Control
                  className="rounded-0 "
                  type="text"
                  value={lastName || ""}
                  onChange={(e) => {
                    crewMemberChangeHandler(_key, "lastName", e.target.value);
                    if (e.target.value === "") {
                      setError("lastName" + _key, {
                        message: "This field is required",
                      });
                    } else if (Names(e)) {
                      setError("lastName" + _key, {
                        message: "Please enter valid Last Name",
                      });
                    } else {
                      setError("lastName" + _key, { message: "" });
                    }
                  }}
                />
                <Form.Label className="mt-2 text-danger">
                  {errors["lastName" + _key] &&
                    errors["lastName" + _key].message}
                </Form.Label>
              </Form.Group>
            </Col>
            <Col className="customCol">
              <Form.Group controlId="form-2-1" className="mb-3">
                <Form.Label>
                  <span className="text-danger bold-font">*</span>Email ID
                </Form.Label>
                <Form.Control
                  className="rounded-0 "
                  type="email"
                  value={emailId || ""}
                  onChange={(e) => {
                    crewMemberChangeHandler(_key, "emailId", e.target.value);
                    //let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,3})+$/
                    if (e.target.value === "") {
                      setError("emailId" + _key, {
                        message: "This field is required",
                      });
                    } else if (emailValidation(e)) {
                      setError("emailId" + _key, {
                        message: "Please enter a valid Email ID",
                      });
                    } else {
                      setError("emailId" + _key, { message: "" });
                    }
                  }}
                />
                <Form.Label className="mt-2 text-danger">
                  {errors["emailId" + _key] && errors["emailId" + _key].message}
                </Form.Label>
              </Form.Group>
            </Col>
            <Col className="customCol">
              <Form.Group controlId="form-2-1" className="mb-3">
                <Form.Label>
                  <span className="text-danger bold-font"></span>Phone No.
                </Form.Label>
                <Form.Control
                  className="rounded-0 "
                  type="text"
                  value={phoneNumber || ""}
                  pattern="[0-9]*"
                  onChange={(e) => {
                    crewMemberChangeHandler(
                      _key,
                      "phoneNumber",
                      e.target.validity.valid ? e.target.value : phoneNumber
                    );
                    let regex =
                      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                    if (regex.test(e.target.value)) {
                      setError("phone" + _key, {
                        message: "",
                      });
                    } else {
                      setError("phone" + _key, {
                        message: "Please enter a valid phone number",
                      });
                    }
                  }}
                />
                <Form.Label className="mt-2 text-danger">
                  {errors["phone" + _key] && errors["phone" + _key].message}
                </Form.Label>
              </Form.Group>
            </Col>
          </>
        )}
        {showdropdownreq && (
          <Col className="customCol">
            <Form.Label>
              <span className="text-danger bold-font">*</span>Crew Member
            </Form.Label>
            <Select
              options={dropdownoptions}
              value={
                dropdownoptions.find((option) => option.value === emailId) ||
                selectedCrewMember
              }
              onChange={(selectedOption) => {
                if (selectedOption) {
                  setselectedCrewMember(selectedOption);
                  const [firstName, lastName] = selectedOption.label.split(" ");
                  const formattedValue = selectedOption.value;
                  crewMemberChangeHandler(
                    _key,
                    "emailId",
                    formattedValue,
                    firstName,
                    lastName
                  );
                }
              }}
              placeholder="Select Crew Member"
            />
          </Col>
        )}
        {getFormData.climber_nonclimber_required !== "N" && (
          <Col className="customCol">
            <Form.Group controlId="form-2-1" className="mb-3">
              <Form.Label>
                <span className="text-danger bold-font">*</span>Member type
              </Form.Label>
              {showSuggestion && leadMemberType === "Climber" && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      All climbers must have PPEs
                    </Tooltip>
                  }
                >
                  <span className="icon-info">i</span>
                </OverlayTrigger>
              )}
              <Form.Select
                className="rounded-0 hide-blinking-cursor"
                value={membertype}
                onChange={(e) => {
                  crewMemberChangeHandler(_key, "membertype", e.target.value);
                  setleadMemberType(e.target.value);
                  if (e.target.value === "") {
                    setError("membertype" + _key, {
                      message: "This field is required",
                    });
                  } else {
                    setError("membertype" + _key, { message: "" });
                  }
                }}
              >
                <option value="">Select</option>
                {membertype_list.map((i, k) => (
                  <option key={k} value={i}>
                    {i}
                  </option>
                ))}
              </Form.Select>
              <Form.Label className="mt-2 text-danger">
                {errors["membertype" + _key] &&
                  errors["membertype" + _key].message}
              </Form.Label>
            </Form.Group>
          </Col>
        )}
        {getKey !== 1 && getKey !== 0 ? (
          <div className="d-flex justify-content-end">
            <img
              className="icon-red-color pointer pt-1"
              src={String(trashcan)}
              onClick={() => deleteCrewMember(_key)}
              alt="delete icon"
            />
          </div>
        ) : (
          ""
        )}
        {getStatus[_key]?.freetext ? (
          <p>
            <span
              className="texthref"
              onClick={(e) => handleStatusChange("freetext")}
            >
              Select from existing members list
            </span>
          </p>
        ) : getStatus[_key]?.dropdown ? (
          <p>
            Not in the list?{" "}
            <span
              className="texthref"
              onClick={(e) => handleStatusChange("dropdown")}
            >
              Add New Member
            </span>
          </p>
        ) : null}
      </Row>
    </>
  );
}
