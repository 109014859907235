import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { GetDropdownValues } from "../../api/DropdownValues.api";
import Select from "react-select";
import {
  Row,
  Col,
  Nav,
  Form,
  Button,
  ButtonGroup,
  ToggleButton,
  ProgressBar,
  OverlayTrigger,
  Tooltip,
  FormLabel,
} from "react-bootstrap";
import { UserGroupId } from "../../utils/Variable";

function DropdownFromApi({
  onChangeFormTemplate,
  sectionId,
  questions_obj,
  handleDropdownfromApi,
  UpdatedValueofDropdown,
  emailError,
  setEmailError,
  SelectedOptionofDropdownApi,
  setSelectedOptionofDropdownApi,
}) {
  const [dropdownoptions, setdropdownoptions] = useState([]);
  const [countryId, setdropdownID] = useState("");

  const [BlankTextField, setBlankTextField] = useState(false);
  const [newDropdownValue, setnewDropdownValue] = useState("");
  const [EmailErrorrRequired, setEmailErrorRequired] = useState("");

  useEffect(() => {
    if (SelectedOptionofDropdownApi) {
      handleDropdownfromApi(SelectedOptionofDropdownApi);
    }
  }, [SelectedOptionofDropdownApi]);
  // useEffect(() => {

  // }, [SelectedOptionofDropdownApi])

  const param1 = {
    filter: questions_obj.userFilterSuggestion,
  };

  useEffect(() => {
    const getApiResponse = async () => {
      const response = await GetDropdownValues(param1);
      const getRes = await response;
      setdropdownoptions(await getRes);
    };
    getApiResponse();
  }, []);

  const handleDropdownChange = (SelectedOptionofDropdownApi) => {
    if (!BlankTextField) {
      setSelectedOptionofDropdownApi(null);
    }
    if (SelectedOptionofDropdownApi.value === "Other") {
      setEmailError("Please enter a valid email address");
      console.log("WQIWWWWWWWWWWWEwqeeew", SelectedOptionofDropdownApi);
      setSelectedOptionofDropdownApi(SelectedOptionofDropdownApi);
      setBlankTextField(true);
      // setdropdownoptions([...dropdownoptions, SelectedOptionofDropdownApi.value])
    } else {
      setEmailError("");
      setSelectedOptionofDropdownApi(SelectedOptionofDropdownApi);
      setdropdownID(SelectedOptionofDropdownApi.value);
      setBlankTextField(false);
      const emailRegex = /<(.+)>/;
      const match = SelectedOptionofDropdownApi.value.match(emailRegex);
      const email = match ? match[1] : SelectedOptionofDropdownApi.value;
      onChangeFormTemplate({
        sectionId,
        answer: SelectedOptionofDropdownApi.value,
        questionId: questions_obj.questionId,
        required: questions_obj.state,
        // subQuestionId: questions_obj.questionId,
        // updateSubQuestion: true,
      });

      // localStorage.setItem('SelectedOptionofDropdownApi', JSON.stringify(SelectedOptionofDropdownApi))
    }
  };

  useEffect(() => {
    if (BlankTextField) {
      onChangeFormTemplate({
        sectionId,
        answer: newDropdownValue,
        questionId: questions_obj.questionId,
        required: questions_obj.state,
        // subQuestionId: questions_obj.questionId,
        // updateSubQuestion: true,
      });
    }
  }, [BlankTextField]);

  const handleAddnewValue = () => {
    if (newDropdownValue) {
      const emailPattern = /^\S+@\S+\.\S+$/; // Regex pattern to validate email
      if (
        questions_obj.emailValidation === "Yes" &&
        !emailPattern.test(newDropdownValue)
      ) {
        setEmailError("Please enter a valid email address");
        setEmailErrorRequired("Please enter a valid email address");
        return;
      }

      const updateDropdown = [...dropdownoptions, newDropdownValue];
      setdropdownoptions(updateDropdown);
      setSelectedOptionofDropdownApi({
        value: newDropdownValue,
        label: newDropdownValue,
      });
      setdropdownID(newDropdownValue);
      setnewDropdownValue("");
      setBlankTextField(false);
      onChangeFormTemplate({
        sectionId,
        answer: newDropdownValue,
        questionId: questions_obj.questionId,
        required: questions_obj.state,
      });
      setEmailError("");
      setEmailErrorRequired("");
      // localStorage.setItem('SelectedOptionofDropdownApi', JSON.stringify({ value: newDropdownValue, label: newDropdownValue }))
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: 32,
      fontSize: 14,
    }),
  };

  const options = [
    ...dropdownoptions.map((option) => ({ value: option, label: option })),
  ];

  // useEffect(() => {

  // }, [SelectedOptionofDropdownApi])
  return (
    <>
      <Select
        options={options}
        value={
          SelectedOptionofDropdownApi === null
            ? questions_obj.answer
              ? options.find(
                  (option) => option.value === questions_obj.answer
                ) || {
                  value: questions_obj.answer,
                  label: questions_obj.answer,
                }
              : null
            : SelectedOptionofDropdownApi
        }
        onChange={handleDropdownChange}
        styles={customStyles}
        placeholder="--Select --"
        isSearchable
      />
      {BlankTextField ? <br /> : null}
      {BlankTextField && (
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <Form.Control
            type="text"
            placeholder={questions_obj.placeholder}
            value={newDropdownValue}
            onChange={(e) => setnewDropdownValue(e.target.value)}
            isInvalid={!!EmailErrorrRequired} // Set the isInvalid prop based on the emailError state variable
          />
          <Button
            onClick={handleAddnewValue}
            style={{ marginLeft: "10px", alignSelf: "flex-end" }}
          >
            Add
          </Button>
        </div>
      )}
      <Form.Control.Feedback type="invalid">
        {EmailErrorrRequired}
      </Form.Control.Feedback>
    </>
  );
}

export default DropdownFromApi;
