import { useCallback } from "react";
import { useState, useMemo, useEffect } from "react";
import { Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import SectionCompForIJHA from "./SectionCompForIJHA";
import FormProgressBarforDynamic from "../../components/FormProgressBar/FormProgressBarforDynamic";
import _ from "lodash";
import "../../index.scss";
import {
  ijhaFormSubmit,
  getAddCrewMemberApi,
  ijhaInCompleteFormSubmit,
} from "../../api/CreateiJHAforIJHADynamic.api";
import Loading from "../../components/Loading/Loading";
import { EMP_ID, DECRYPTED_DATA, TOKEN } from "../../utils/Variable";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import PhoneButton from "../../assets/Icons/PhoneButton.png";
import { useLocation } from "react-router-dom";
import ic_complete_ppe from "../../assets/Icons/ic_complete_ppe.png";
import image from "../../assets/Icons/image.png";
import {
  getCurrentDataTime,
  getTotalTime,
  getFileTypeImgDoc,
  removeTags,
} from "../../utils/Helper";
import SummaryForSiteInspection from "./SummaryForSiteInspection";
// import AddCrewMember from "../CrewMember/AddCrewMember";
import ModalMapTramaCenterforIJhaDynamic from "../../components/Modal/ModalMapTramaCenterforIJhaDynamic";
import ModalMapCustomforIjhaDynamic from "../../components/Modal/ModalMapCustomforIjhaDynamic";
export default function SelectedSectionForm({
  getLocationavailable,
  getLoc,
  setLoc,
  selectedSectionData,
  sectionCorrective,
  sectionDeficiency,
  sectionCustomCorrective,
  form_template_p,
  reportRequired,
  getCertificateOptionListD,
  questions_obj,
  getFormId,
  getSelectedSection,
  namesToDeleteSet,
  getFormData,
}) {
  let loc = useLocation();
  let isCallinProgress = false;
  const [api, setapi] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [videodisplayes, setvideodisplayes] = useState(false);
  const [videoCalldisplay, setVideoCalldisplay] = useState(false);
  const [getIjhaform, setIjhaform] = useState(loc.state.SiteInspectionForm);
  const [form_template, setForm_template] = useState(getIjhaform.form_template);
  const [getSubmittedParentId, setSubmittedParentId] = useState();
  const [getbutton_control, setbutton_control] = useState(true);
  const [getProceed, setProceed] = useState(0);
  const [getpbtn, setpbtn] = useState(false);
  const [Summarypage, setSummarypage] = useState(false);
  const [getSiteLandLord, setSiteLandLord] = useState(false);
  const [getRequired, setRequired] = useState(false);
  const [getLoading, setLoading] = useState(false);
  const [getLoading1, setLoading1] = useState(false);
  const [getOtherField, setOtherField] = useState(false);
  const [getCrewData, setCrewData] = useState(loc.state);
  const [getCrewMemData, setCrewMemData] = useState(loc.state.crewmemberdata);
  const [getLatitude, setLatitude] = useState(0);
  const [getCrewLeadData, setCrewLeadData] = useState(
    loc.state.getCrewLeadData
  );
  const [getLongitude, setLongitude] = useState(0);
  const [getImgMetaData, setImgMetaData] = useState();
  const [getCheckComplete, setCheckComplete] = useState(false);
  const [getAllFormsData, setAllFormsData] = useState([]);
  const [getReportCrewCheck, setReportCrewCheck] = useState(false);
  const [getStepSection, setStepSection] = useState(0);
  const [getSubmitMsg, setSubmitMsg] = useState("");
  const [getReportRequired, setReportRequired] = useState(reportRequired);
  const [showScroll, setShowScroll] = useState(false);
  const [EmailValidationforField, setEmailValidationforField] = useState(true);
  const [getBlankField, setBlankField] = useState(false);
  const [getSummarybutton, setSummarybutton] = useState(false);
  const [validationforfields, setvalidationforfields] = useState(false);
  const [validationofsubmit, setvalidationofsubmit] = useState(false);
  const [getValidation, setValidation] = useState(false);
  const [getTowerOwner, setTowerOwner] = useState(false);
  const [getStructureType, setStructureType] = useState(false);
  const [typeofstructure, settypeofstructure] = useState(false);
  const [getUpdateWeather, setUpdateWeather] = useState(false);
  // const [getCrewMemberProceed1, setCrewMemberProceed1] = useState(0);
  const [getProceed1, setProceed1] = useState(0);
  const [getErrMsg, setErrMsg] = useState("");
  const [getAddress, setAddress] = useState("");
  const [getCrewMemberData, setCrewMemberData] = useState([]);
  const [getCrewLead, setCrewLead] = useState(0);
  const [getCrewMember, setCrewMember] = useState(1);
  const [getBackRequired, setBackRequired] = useState(false);
  const [showMdl, setShowMdl] = useState(false);
  const [getAddEditCrew, setAddEditCrew] = useState([]);
  const [getCrewfill, setCrewfill] = useState(false);
  const [getIsCompleted, setIsCompleted] = useState([]);
  const [getDelCrew, setDelCrew] = useState(false);
  const [showTraumaMdl, setShowTraumaMdl] = useState(false);
  const [getUnit, setUnit] = useState("");
  // const [getLoc, setLoc] = useState(getLocationstate);
  const [getWeatherHours, setWeatherHours] = useState([]);
  const [getReportID, setReportId] = useState();
  const [radioValue, setRadioValue] = useState("imperial");
  const [getCrewMenProceed, setCrewMenProceed] = useState(false);
  const [getCrewPro, setCrewProd] = useState(0);
  const [getformTemplateCount, setformTemplateCount] = useState([0]);
  const [getReportIJHA, setReportIJHA] = useState(loc.state.reportIJHA);

  const [getDataMdl, setDataMdl] = useState({
    heading: "",
    body: "",
    showClose: false,
    saveBtnName: "",
  });
  const [directions, setDirections] = useState({});
  const [getCertifyData, setCertifyData] = useState({});
  const [getCertifySection, setCertifySection] = useState({});

  const changeFormTemplateData = (arr) => {
    onChangeFormTemplate({
      sectionId: arr[0],
      answer: arr[1],
      questionId: arr[2],
      required: arr[3],
      picture: arr[4],
    });
  };

  const getLocation = async () => {
    var _latlng;
    if (getLoc.lat === "" && getLoc.lng === "") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setUnit("imperial");
          _latlng = _.cloneDeep(getLoc);
          _latlng.lat = position.coords.latitude;
          _latlng.lng = position.coords.longitude;
          setLoc(_latlng);
        });
      } else {
        setErrMsg("Geolocation is not supported by this browser.");
      }
    }
  };
  useEffect(() => {
    start_datetime_filling();
  }, []);

  const Locationon = () => {
    alert("Please Turn Your Browser Location On.11");
    window.location.reload();
  };

  const start_datetime_filling = () => {
    let _getIjhaform = _.cloneDeep(getIjhaform);
    _getIjhaform["start_time_datetime"] = getCurrentDataTime();
    setIjhaform(_getIjhaform);
  };
  useEffect(() => {
    !_.isEmpty(getIjhaform.reportId)
      ? setCrewMemberData(loc.state.getCrewLeadData)
      : getAddCrewMemberApi(setCrewMemberData, setLoading, setErrMsg);
  }, []);

  const modalMapHandler = (
    heading,
    getAddress,
    changeFormTemplateDataforSpecificSection,
    lat,
    lng,
    _key,
    indextoEditforDirection,
    indexforLocation
  ) => {
    let dataMdl = {
      heading: heading,
      setAddress: setAddress,
      getLatitude: Number(lat),
      getLongitude: Number(lng),
      direction: false,
      indexforLocation: indexforLocation,
      body: "",
      changeFormTemplateDataforSpecificSection:
        changeFormTemplateDataforSpecificSection,
      indextoEditforDirection: indextoEditforDirection,
      showClose: false,
      saveBtnName: "Add",
    };
    setDataMdl(dataMdl);
    setShowMdl(true);
  };

  const modalMapCustomHandler = (data) => {
    let dataMdl = {
      heading: data.heading,
      getLatitude: Number(getLoc.lat),
      getLongitude: Number(getLoc.lng),
      data: data,
      showClose: false,
      saveBtnName: "Add",
    };

    setDataMdl(dataMdl);
    setShowMdl(true);
  }

  const wheather_radio = [
    { name: "°F", value: "imperial" },
    { name: "°C", value: "metric" },
  ];

  const sendData = {
    latitude: getLoc.lat,
    longitude: getLoc.lng,
    unit: radioValue,
    loadingTrue: useCallback(() => setLoading(true), [setLoading]),
    loadingFalse: useCallback(() => setLoading(false), [setLoading]),
  };

  const modalMapHandler1 = (
    heading,
    getAddress,
    changeFormTemplateData,
    _key,
    lat,
    lng
  ) => {
    let dataMdl = {
      heading: heading,
      setAddress: setAddress,
      getCurrLatitude: getLatitude === 0 ? getLoc.lat : getLatitude,
      getCurrLongitude: getLongitude === 0 ? getLoc.lng : getLongitude,
      getLatitude: getLatitude === 0 ? getLoc.lat : getLatitude,
      getLongitude: getLongitude === 0 ? getLoc.lng : getLongitude,
      direction: false,
      fullAdd: _.split(
        removeTags(form_template[getProceed].questions[_key].answer),
        "\n"
      ),
      body: "",
      changeFormTemplateData: changeFormTemplateData,
      _key: _key,
      showClose: false,
      saveBtnName: "Add",
    };

    setDataMdl(dataMdl);
    setShowTraumaMdl(true);
  };

  const modalMapDirectionHandler = (
    heading,
    getAddress,
    changeFormTemplateDataforSpecificSection,
    _key
  ) => {
    let dataMdl = {
      heading: heading[0],
      setAddress: setAddress,

      getLatitude: getLatitude === 0 ? getLoc.lat : getLatitude,
      getLongitude: getLongitude === 0 ? getLoc.lng : getLongitude,
      origin: heading[1],
      destination: heading[2],
      direction: true,
      body: "",
      changeFormTemplateDataforSpecificSection:
        changeFormTemplateDataforSpecificSection,
      _key: _key,
      showClose: false,
      saveBtnName: "Add",
    };

    setDataMdl(dataMdl);
    setShowMdl(true);
  };

  useEffect(() => {
    if (
      getIjhaform.reportId !== undefined &&
      getIjhaform.reportId !== "" &&
      getIjhaform.complete_status === "InProgress"
    ) {
      setReportId(getIjhaform.reportId);
    } else {
      setReportId(uuidv4());
    }
    if (
      getIjhaform.inProgressSection !== undefined &&
      getIjhaform.inProgressSection !== ""
    ) {
      if (getIjhaform.inProgressSection === form_template.length - 1) {
        setProceed1(15);
      }
      setProceed(getIjhaform.inProgressSection);

      let _emptyArr = _.cloneDeep(getformTemplateCount);
      for (let i = 0; i <= getIjhaform.inProgressSection; i++) {
        _emptyArr.push(i);
      }
      _emptyArr = [...new Set(_emptyArr)];
      setformTemplateCount(_emptyArr);
    }
  }, []);

  useEffect(() => {
    if (
      getIjhaform.parent_reportId !== undefined &&
      getIjhaform.reportId !== "" &&
      getIjhaform.Report_state === "submittedReport"
    ) {
      setSubmittedParentId(getIjhaform.parent_reportId);
    } else {
      setSubmittedParentId(getReportID);
    }
  }, []);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const removeImgData = (e) => {
    let _form_template = _.cloneDeep(form_template);
    _form_template.map((obj) => {
      if (obj.sectionId === e.sectionId) {
        return obj.questions.map((s_obj) => {
          if (s_obj.questionId === e.questionId) {
            if (s_obj.multiple_picture !== undefined)
              s_obj.multiple_picture = s_obj.multiple_picture.filter(
                (k, i) => i !== e.idx && k
              );
          }
          return s_obj;
        });
      }
      return obj;
    });
    setForm_template(_form_template);
  };

  useEffect(() => {
    return () => {
      if (api) {
        api.dispose();
        setapi(null);
      }
    };
  }, []);

  window.addEventListener("scroll", checkScrollTop);

  useEffect(() => {
    getLocation();
    let _getIjhaform = _.cloneDeep(getIjhaform);
    _getIjhaform["start_time_datetime"] = getCurrentDataTime();

    _getIjhaform["state"] = "";
    _getIjhaform["state_id"] = "";
    _getIjhaform["complete_status"] = "1";
    _getIjhaform["usergroupId"] = null;
    _getIjhaform["formId"] = getFormData.formId;
    _getIjhaform["crew_member_required"] = getFormData.crew_member_required;
    _getIjhaform["app_type"] = getFormData.app_type;
    _getIjhaform["form_name"] = getFormData.form_name;

    _getIjhaform["latitude"] = getLoc.lat;
    _getIjhaform["longitude"] = getLoc.lng;
    _getIjhaform["device_details"] = {
      app_version: null,
      device_token: null,
      build_number: null,
      os_version: null,
    };

    setIjhaform(_getIjhaform);
    scrollUp();
  }, [getProceed, getProceed1]);

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const _selectedSections = useMemo(() => {
    const _selectedSectionId = selectedSectionData
      .filter((obj) => obj.checked_val && obj.sectionId)
      .map((obj) => obj.sectionId);

    return _selectedSectionId;
  }, [selectedSectionData]);

  const proceedNxtSec = () => {
    InprocessFormSubmit();

    if (!getCrewMenProceed) {
      if (_selectedSections.length > getProceed) {
        const sectionData = Object.values(form_template).find(
          (section) => section.sectionId === _selectedSections[getProceed + 1]
        );
        if (sectionData && !sectionData.visibility) setProceed(getProceed + 2);
        else {
          setProceed(getProceed + 1);
        }
      }
      setRequired(false);
      if (!getReportRequired) {
        setStepSection(parseInt(getStepSection) + 1);
      }
      setBackRequired(true);
    } else {
      if (_selectedSections.length > 12) {
        let crewSec = form_template
          .map((i, k) => "section" in i && k)
          .filter((n) => n);

        if (crewSec[getCrewPro] !== undefined) {
          setProceed(crewSec[getCrewPro] + 1);

          if (crewSec[getCrewPro] === 12) {
            setCrewProd(1);
          } else {
            setCrewProd(getCrewPro + 1);
          }
        } else {
          setProceed(form_template.length);
          setCrewProd(0);
        }
      }

      setRequired(false);
      if (!getReportRequired) {
        setStepSection(parseInt(getStepSection) + 1);
      }
      setBackRequired(true);
    }
    if (!getReportRequired) {
      setStepSection(parseInt(getStepSection) + 1);
    }
  };

  const onBackSec = () => {
    if (getProceed > 0) {
      const sectionData = Object.values(form_template).find(
        (section) => section.sectionId === _selectedSections[getProceed - 1]
      );
      if (sectionData && !sectionData.visibility) setProceed(getProceed - 2);
      else {
        setProceed(getProceed - 1);
      }
    }
    getProceed !== 0
      ? setStepSection(parseInt(getStepSection) - 1)
      : setStepSection(parseInt(getStepSection));
    getProceed !== 0 ? setRequired(true) : setRequired(false);
    getProceed !== 1 ? setBackRequired(true) : setBackRequired(false);
  };
  const _selectedSection_render = form_template.filter((obj, i) => {
    return (
      _selectedSections.includes(obj.sectionId) &&
      obj.sectionId === _selectedSections[getProceed]
    );
  });

  const pageIndex = form_template.findIndex(
    (item) => item.sectionId === item.sectionId
  );

  const pageIndexofPPE = form_template.findIndex(
    (item) => item.sectionName === "Personal Protective Equipment"
  );
  const pageIndexofTermsofUse = form_template.findIndex(
    (item) => item.sectionName === "Terms of Use (Exit)"
  );

  const InprocessFormSubmit = () => {
    let _getProceedCount = getformTemplateCount.includes(getProceed)
      ? getformTemplateCount[getformTemplateCount.length - 1]
      : getProceed;

    let _getIjhaform = _.cloneDeep(getIjhaform);

    _getIjhaform["form_template"] = form_template;
    _getIjhaform["reportId"] = getReportID;
    _getIjhaform["teamLeadId"] = EMP_ID().replaceAll('"', "");
    _getIjhaform["crew_members"] = [EMP_ID().replaceAll('"', "")];
    _getIjhaform["inProgressSection"] = _getProceedCount;
    _getIjhaform["parent_reportId"] =
      _getIjhaform.hasOwnProperty("Report_state") &&
        getIjhaform.Report_state === "submittedReport"
        ? getSubmittedParentId
        : getReportID;
    _getIjhaform["latitude"] =
      getLatitude === 0
        ? getLocationavailable && getLocationavailable.lat !== undefined
          ? getLocationavailable.lat
          : getLoc.lat !== ""
            ? getLoc.lat
            : getIjhaform.latitude
        : getLatitude;
    _getIjhaform["longitude"] =
      getLongitude === 0
        ? getLocationavailable && getLocationavailable.lng !== undefined
          ? getLocationavailable.lng
          : getLoc.lng !== ""
            ? getLoc.lng
            : getIjhaform.longitude
        : getLongitude;
    _getIjhaform["device_details"] = {
      app_version: null,
      device_token: null,
      build_number: null,
      os_version: null,
    };
    _getIjhaform["weather"] = {};
    _getIjhaform["state"] = "";
    _getIjhaform["state_id"] = "";
    _getIjhaform["complete_status"] = "0";
    _getIjhaform["usergroupId"] = null;

    _getIjhaform["formId"] = getFormData.formId;
    _getIjhaform["crew_member_required"] = getFormData.crew_member_required;
    _getIjhaform["app_type"] = getFormData.app_type;
    _getIjhaform["form_name"] = getFormData.form_name;
    _getIjhaform["submitted_datetime"] = getCurrentDataTime();
    _getIjhaform["resubmit"] = !_.isEmpty(getIjhaform.reportId) ? "1" : "0";

    ijhaInCompleteFormSubmit(_getIjhaform, setLoading);
  };

  const onSORFormSubmit = (status) => {
    setLoading1(true);

    let _getIjhaform = _.cloneDeep(getIjhaform);
    _getIjhaform["form_template"] = form_template;

    _getIjhaform["reportId"] = getReportID;
    _getIjhaform["teamLeadId"] = EMP_ID().replaceAll('"', "");
    _getIjhaform["crew_members"] = [EMP_ID().replaceAll('"', "")];
    _getIjhaform["weather"] = {};

    _getIjhaform["latitude"] =
      getLatitude === 0
        ? getLocationavailable && getLocationavailable.lat !== undefined
          ? getLocationavailable.lat
          : getLoc.lat !== ""
            ? getLoc.lat
            : getIjhaform.latitude
        : getLatitude;
    _getIjhaform["longitude"] =
      getLongitude === 0
        ? getLocationavailable && getLocationavailable.lng !== undefined
          ? getLocationavailable.lng
          : getLoc.lng !== ""
            ? getLoc.lng
            : getIjhaform.longitude
        : getLongitude;
    _getIjhaform["device_details"] = {
      app_version: null,
      device_token: null,
      build_number: null,
      os_version: null,
    };

    _getIjhaform["state"] = "";
    _getIjhaform["state_id"] = "";
    _getIjhaform["complete_status"] = "1";
    _getIjhaform["usergroupId"] = null;

    _getIjhaform["parent_reportId"] =
      _getIjhaform.hasOwnProperty("Report_state") &&
        getIjhaform.Report_state === "submittedReport"
        ? getSubmittedParentId
        : getReportID;
    _getIjhaform["form_name"] = getFormData.form_name;
    _getIjhaform["formId"] = getFormData.formId;
    _getIjhaform["crew_member_required"] = getFormData.crew_member_required;
    _getIjhaform["app_type"] = getFormData.app_type;
    _getIjhaform["submitted_datetime"] = getCurrentDataTime();
    _getIjhaform["total_time_taken"] = getTotalTime(
      _getIjhaform["start_time_datetime"],
      _getIjhaform["submitted_datetime"]
    );
    _getIjhaform["resubmit"] = "0";
    if (status !== "") {
      _getIjhaform["ppe_status"] = status;
    } else {
      delete _getIjhaform["ppe_status"];
    }

    ijhaFormSubmit(_getIjhaform, setLoading, setSubmitMsg);
    setIjhaform(_getIjhaform);
  };

  function getSecondPart(str) {
    return str.split("-")[1];
  }
  function getFirstPart(str) {
    return str.split("-")[0];
  }
  const provideLatLngfrmStr = (text) =>
    text
      .split("(")[1]
      .split(")")
      .filter((obj) => obj !== "")[0]
      .split(",")
      .map((obj) => Number(obj));

  const changeFormTemplateDataforSpecificSection = (args) => {
    const [_type, _value, _key, _s_key, _key_name, _attri] = [...args];
    let first_part, second_part, latitude, longitude;
    let _form_template = _.cloneDeep(form_template);
    switch (_type) {
      case 1:
        const indexforLocationchange = form_template.findIndex(
          (item) => item.sectionId === _key_name
        );
        _form_template[indexforLocationchange].questions[_s_key].answer =
          _value;
        break;

      case 28:
        const indextoEditformSection = form_template.findIndex(
          (item) => item.sectionId === _s_key
        );

        const indextoEditform = _form_template[
          indextoEditformSection
        ].questions.findIndex((question) => question.questionId === _key);
        first_part = _value;
        if (_value === "Other") {
          setTowerOwner(true);
          second_part = "";

          _form_template[indextoEditformSection].questions[
            indextoEditform
          ].answer = first_part.concat("-", second_part);

          break;
        } else {
          setTowerOwner(false);

          second_part = getSecondPart(
            _form_template[indextoEditformSection].questions[indextoEditform]
              .answer
          );
          _form_template[indextoEditformSection].questions[
            indextoEditform
          ].answer = first_part;
        }
        break;

      case 29:
        const indextoEditformSectionOther = form_template.findIndex(
          (item) => item.sectionId === _s_key
        );
        const indextoEditform1 = _form_template[
          indextoEditformSectionOther
        ].questions.findIndex((question) => question.questionId === _key);
        first_part = getFirstPart(
          _form_template[indextoEditformSectionOther].questions[
            indextoEditform1
          ].answer
        );
        second_part = _value;

        _form_template[indextoEditformSectionOther].questions[
          indextoEditform1
        ].answer = first_part.concat("-", second_part);

        break;

      case 30:
        const indextoEditformSectionSite = form_template.findIndex(
          (item) => item.sectionId === _s_key
        );
        const indextoEditformsiteLand = _form_template[
          indextoEditformSectionSite
        ].questions.findIndex((question) => question.questionId === _key);
        first_part = _value;
        if (_value === "Other") {
          setSiteLandLord(true);
          second_part = "";
          _form_template[indextoEditformSectionSite].questions[
            indextoEditformsiteLand
          ].answer = first_part.concat(" - ", second_part);
        } else {
          setSiteLandLord(false);

          second_part = getSecondPart(
            _form_template[indextoEditformSectionSite].questions[
              indextoEditformsiteLand
            ].answer
          );
          _form_template[indextoEditformSectionSite].questions[
            indextoEditformsiteLand
          ].answer = first_part.concat("-", second_part);
        }
        break;

      case 31:
        const indextoEditformSectionSiteOther = form_template.findIndex(
          (item) => item.sectionId === _s_key
        );
        const indextoEditformsiteLandOther = _form_template[
          indextoEditformSectionSiteOther
        ].questions.findIndex((question) => question.questionId === _key);
        first_part = getFirstPart(
          _form_template[indextoEditformSectionSiteOther].questions[
            indextoEditformsiteLandOther
          ].answer
        );
        second_part = _value;
        _form_template[indextoEditformSectionSiteOther].questions[
          indextoEditformsiteLandOther
        ].answer = first_part.concat("-", second_part);
        break;

      case 26:
        const indexToEditforFireextin = _form_template[
          getProceed
        ].questions.findIndex((question) => question.questionId === _s_key);

        if (
          _form_template[getProceed].questions[indexToEditforFireextin]
            .methodType === "AI" &&
          _form_template[getProceed].questions[indexToEditforFireextin]
            .confidence !== "" &&
          _form_template[getProceed].questions[indexToEditforFireextin]
            .answer === "No"
        ) {
          _form_template[getProceed].questions[
            indexToEditforFireextin
          ].overridden = _value;
        } else {
          _form_template[getProceed].questions[
            indexToEditforFireextin
          ].overridden = _value;
        }
        break;

      case 15:
        const indexforLocation = form_template.findIndex(
          (item) => item.sectionId === _s_key
        );
        setAddress(
          removeTags(form_template[indexforLocation].questions[_key].answer)
        );

        modalMapHandler(
          _value,
          getAddress,
          changeFormTemplateDataforSpecificSection,
          form_template[indexforLocation].questions[_key].lat,
          form_template[indexforLocation].questions[_key].long,
          _s_key,
          _key,
          indexforLocation
        );

        break;
      case 7:
        const indexToEdit12 = _form_template[getProceed].section[
          getCrewLead
        ].questions.findIndex((question) => question.questionId === _s_key);

        if (
          _form_template[getProceed].section[getCrewLead].questions[
            indexToEdit12
          ].methodType === "AI" &&
          _form_template[getProceed].section[getCrewLead].questions[
            indexToEdit12
          ].confidence !== "" &&
          _form_template[getProceed].section[getCrewLead].questions[
            indexToEdit12
          ].answer === "No"
        ) {
          _form_template[getProceed].section[getCrewLead].questions[
            indexToEdit12
          ].overridden = _value;
        } else {
          _form_template[getProceed].section[getCrewLead].questions[
            indexToEdit12
          ].overridden = _value;
        }
        break;

      case 13:
        if (typeof _value === "object") {
          const indexToEdit = _form_template[getProceed].section[
            getCrewLead
          ].questions.findIndex((question) => question.questionId === _s_key);
          _form_template[getProceed].section[getCrewLead].questions[
            indexToEdit
          ] = {
            ..._form_template[getProceed].section[getCrewLead].questions[
            indexToEdit
            ],
            picture: _value[0],
            answer: _value[1],
            confidence: _value[2],
            threshold: _value[3],
            image_metadata: getImgMetaData,
          };
        }
        break;
      case 25:
        if (typeof _value === "object") {
          const indexToEdit = _form_template[getProceed].questions.findIndex(
            (question) => question.questionId === _s_key
          );
          _form_template[getProceed].questions[indexToEdit] = {
            ..._form_template[getProceed].questions[indexToEdit],
            picture: _value[0],
            answer: _value[1],
            confidence: _value[2],
            threshold: _value[3],
          };
        }
        break;

      case 32:
        const indexToEdit = _form_template[getProceed].section[
          getCrewLead
        ].questions.findIndex((question) => question.questionId === _s_key);

        _form_template[getProceed].section[getCrewLead].questions[indexToEdit] =
        {
          ..._form_template[getProceed].section[getCrewLead].questions[
          indexToEdit
          ],
          picture: "",
          image_metadata: "",
          confidence: "",
        };
        break;
      case 38:
        if (_form_template[getProceed].questions[0].picture !== "") {
          _form_template[getProceed].questions[0].picture = "";
          _form_template[getProceed].questions[0].image_metadata = "";
          _form_template[getProceed].questions[0].confidence = "";
        }
        break;
      case 4:
        const indexforLocation11 = form_template.findIndex(
          (item) => item.sectionId === _s_key
        );
        let data_loc = "";
        if (_form_template[_attri].questions[_key_name].answer !== "") {
          data_loc =
            _form_template[_attri].questions[_key_name].answer.split("(")[0];
        }

        latitude = getLatitude === 0 ? getLoc.lat : getLatitude;
        longitude = getLongitude === 0 ? getLoc.lng : getLongitude;
        if (typeof _value === "object") {
          _form_template[_attri].questions[_key_name].answer = _value[0];
          _form_template[_attri].questions[_key_name].distance = _value[1];
          _form_template[_attri].questions[_key_name].duration = _value[2];
        } else {
          _form_template[_attri].questions[_key_name].answer = _value;
        }
        let latLog = provideLatLngfrmStr(
          _form_template[_attri].questions[_key_name].answer
        );
        _form_template[_attri].questions[_key_name].lat = latLog[0];
        _form_template[_attri].questions[_key_name].long = latLog[1];

        break;
      case 16:
        setAddress(
          removeTags(form_template[getProceed].questions[_key].answer)
        );
        modalMapDirectionHandler(
          _value,
          getAddress,
          changeFormTemplateDataforSpecificSection,
          _key
        );
        break;

      case 20:
        const indexToEdit12311 =
          _form_template[18].questions[0].subQuestion.findIndex(
            (question) => question.questionId === _s_key
          );

        form_template[18].questions[0].subQuestion[indexToEdit12311][
          _key_name
        ] = _value;
        _form_template[18].questions[0].subQuestion[indexToEdit12311][
          "fileType"
        ] = getFileTypeImgDoc(_value);
        break;
      case 36:
        const pageIndex = form_template.findIndex(
          (item) => item.sectionId === _key_name
        );

        _form_template[pageIndex].questions[_key].options[_s_key] = _value;

        break;

      case 42:
        _form_template[getProceed].questions[_key].options[_s_key] = _value;
        _form_template[getProceed + 1].questions[0].multipleQuestion[
          _s_key
        ].questions[0].description = _value;
        break;
      case 35:
        _form_template[getProceed].questions[0].answer = _value;

        if (_value === "No") {
          getSelectedSection.find(
            (item) => item.sectionName === "Deficiencies and Compliance"
          ).checked_val = true;
        } else {
          getSelectedSection.find(
            (item) => item.sectionName === "Deficiencies and Compliance"
          ).checked_val = false;
        }

        break;
      case 55:
        const pageIndexofQuestion = form_template.findIndex(
          (item) => item.sectionId === _key_name
        );

        _form_template[pageIndexofQuestion].questions[_key].options[_s_key] =
          _value;

        break;

      case 43:
        const indextoEditfortypeofstructure = _form_template[
          getProceed
        ].questions.findIndex((question) => question.questionId === _key);

        first_part = _value;
        if (_value === "Other") {
          settypeofstructure(true);
          second_part = "";

          _form_template[getProceed].questions[
            indextoEditfortypeofstructure
          ].answer = first_part.concat("-", second_part);

          break;
        } else {
          settypeofstructure(false);

          second_part = getSecondPart(
            _form_template[getProceed].questions[indextoEditfortypeofstructure]
              .answer
          );
          _form_template[getProceed].questions[
            indextoEditfortypeofstructure
          ].answer = first_part;
        }
        break;

      case 44:
        const indextoEditfortypeofstructure1 = _form_template[
          getProceed
        ].questions.findIndex((question) => question.questionId === _key);
        first_part = getFirstPart(
          _form_template[getProceed].questions[indextoEditfortypeofstructure1]
            .answer
        );
        second_part = _value;

        _form_template[getProceed].questions[
          indextoEditfortypeofstructure1
        ].answer = first_part.concat("-", second_part);

        break;
      case 76:
        const indextoEditformSection1 = form_template.findIndex(
          (item) => item.sectionId === _s_key
        );

        const indextoEditform12 = _form_template[
          indextoEditformSection1
        ].questions.findIndex((question) => question.questionId === _key);

        first_part = _value;
        if (_value === "Other") {
          setStructureType(true);
          second_part = "";

          _form_template[indextoEditformSection1].questions[
            indextoEditform12
          ].answer = first_part.concat("-", second_part);

          break;
        } else {
          setStructureType(false);

          second_part = getSecondPart(
            _form_template[indextoEditformSection1].questions[indextoEditform12]
              .answer
          );
          _form_template[indextoEditformSection1].questions[
            indextoEditform12
          ].answer = first_part;
        }
        break;

      case 77:
        const indextoEditformSectionOther1 = form_template.findIndex(
          (item) => item.sectionId === _s_key
        );
        const indextoEditform123 = _form_template[
          indextoEditformSectionOther1
        ].questions.findIndex((question) => question.questionId === _key);
        first_part = getFirstPart(
          _form_template[indextoEditformSectionOther1].questions[
            indextoEditform123
          ].answer
        );
        second_part = _value;

        _form_template[indextoEditformSectionOther1].questions[
          indextoEditform123
        ].answer = first_part.concat("-", second_part);

        break;

      default:
        break;
    }

    setForm_template(_form_template);
  };

  const onChangeFormTemplate = (changeValArr) => {
    let _form_template = _.cloneDeep(form_template);
    let first_part, second_part;

    _form_template.map((obj) => {
      if (obj.sectionId === changeValArr.sectionId) {
        setStepSection(changeValArr.sectionId);

        return (
          obj.section && obj.section[getCrewLead]
            ? obj.section[getCrewLead]
            : obj
        ).questions.map((s_obj) => {
          if (s_obj.questionId === changeValArr.questionId) {
            if (changeValArr.updateSubQuestion) {
              const subQuestionIndexToUpdate = s_obj.subQuestion.findIndex(
                (item) => item.questionId === changeValArr.subQuestionId
              );

              if ("answer" in changeValArr) {
                s_obj.subQuestion[subQuestionIndexToUpdate].answer =
                  changeValArr.answer;
              } else if ("signature" in changeValArr) {
                s_obj.subQuestion[subQuestionIndexToUpdate].signature =
                  changeValArr.signature;
              } else if ("desc_text" in changeValArr) {
                s_obj.subQuestion[
                  subQuestionIndexToUpdate
                ].description_model[0].text = changeValArr.desc_text;
              } else if ("picture" in changeValArr) {
                s_obj.subQuestion[subQuestionIndexToUpdate].picture =
                  changeValArr.picture;
              } else if ("multiple_picture" in changeValArr) {
                s_obj.subQuestion[
                  subQuestionIndexToUpdate
                ].multiple_picture.push(changeValArr.multiple_picture);
              }
            } else {
              if (changeValArr.answer !== undefined) {
                if (
                  s_obj.hasOwnProperty("impacted_section_questions") &&
                  s_obj.impacted_section_questions.length !== 0
                ) {
                  const selectedAnswer = changeValArr.answer;
                  const impactedSectionQuestions =
                    s_obj.impacted_section_questions;

                  _form_template.forEach((sectionObj) => {
                    if (sectionObj.questions) {
                      sectionObj.questions.forEach((question) => {
                        const questionId = question.questionId.toString();
                        impactedSectionQuestions.forEach(
                          (impactedSectionQuestion) => {
                            const sectionId =
                              impactedSectionQuestion.sectionId.toString();
                            if (
                              sectionId === sectionObj.sectionId &&
                              impactedSectionQuestion.impactedQuestions.includes(
                                questionId
                              )
                            ) {
                              switch (impactedSectionQuestion.action) {
                                case 0:
                                  question.visibility = false;
                                  break;
                                case 1:
                                  question.visibility = true;
                                  break;
                                case 2:
                                  question.state = "Required";
                                  break;
                                case 3:
                                  question.state = "Optional";
                                  break;
                                case 4:
                                  question.answer = selectedAnswer;
                                  break;
                                default:
                              }
                            }
                          }
                        );
                      });
                    }
                  });

                  s_obj.answer = selectedAnswer;
                } else {
                  s_obj.answer = changeValArr.answer;
                }
              } else if ("addDescriptionVisibility" in changeValArr) {
                s_obj.description_model[0].visible = changeValArr.addDescriptionVisibility
                s_obj.addDescription = changeValArr.addDescriptionVal
                if (changeValArr.addDescriptionVisibility) {
                  s_obj.state = "Required";
                } else {
                  s_obj.state = "Optional";
                  s_obj.answer = "";
                }
              } else if ("checkbox_value" in changeValArr) {
                s_obj.checkbox_model[0].value = changeValArr.checkbox_value;
                s_obj.answer = changeValArr.checkbox_value;
              } else if ("Dropdown_answer" in changeValArr) {
                if (
                  changeValArr.Dropdown_answer === "Internal" &&
                  s_obj.impactQuestionId.includes("13")
                ) {
                  _form_template.map((sectionObj) => {
                    if (sectionObj.questions) {
                      const targetquestion = sectionObj.questions.find(
                        (q) => q.questionId === "13"
                      );
                      if (targetquestion) {
                        targetquestion.state = "Optional";
                      }
                    }
                  });
                  s_obj.answer = changeValArr.Dropdown_answer;
                } else if (
                  changeValArr.Dropdown_answer === "External" &&
                  s_obj.impactQuestionId.includes("13")
                ) {
                  _form_template.map((sectionObj) => {
                    if (sectionObj.questions) {
                      const targetquestion = sectionObj.questions.find(
                        (q) => q.questionId === "13"
                      );
                      if (targetquestion) {
                        targetquestion.state = "Required";
                      }
                    }
                  });
                  s_obj.answer = changeValArr.Dropdown_answer;
                } else {
                  s_obj.answer = changeValArr.Dropdown_answer;
                  first_part = changeValArr.Dropdown_answer;
                  if (changeValArr.Dropdown_answer === "Other") {
                    second_part = "";
                    s_obj.answer = first_part.concat("-", second_part);
                  } else {
                  }
                }
              } else if ("OtherOption" in changeValArr) {
                second_part = changeValArr.OtherOption;
                first_part = getFirstPart(s_obj.answer);
                s_obj.answer = first_part.concat("-", second_part);
              } else if (changeValArr.signature !== undefined) {
                s_obj.signature = changeValArr.signature;
              } else if (changeValArr.desc_text !== undefined)
                s_obj.description_model[0].text = changeValArr.desc_text;
              else if (changeValArr.picture !== undefined)
                s_obj.picture = changeValArr.picture;
              else if (changeValArr.multiple_picture !== undefined)
                s_obj.multiple_picture.push(changeValArr.multiple_picture);
            }
          }

          return s_obj;
        });
      }

      return obj;
    });

    setForm_template(_form_template);
  };

  const initiateVideoCall1 = (Data, answerRequired) => {
    let JitsiToken =
      sessionStorage.getItem("JitsiToken") !== null &&
        typeof sessionStorage.getItem("UserGroupId") !== "undefined"
        ? sessionStorage
          .getItem("JitsiToken")
          .toString(CryptoJS.enc.Utf8)
          .replace(/"/g, "")
        : "";
    if (answerRequired === "No") {
      setVideoCalldisplay(false);
      api?.dispose();
      setapi(null);
      return;
    }
    if (isCallinProgress) {
      return;
    }

    isCallinProgress = false;

    if (videodisplayes) {
      return;
    }

    setVideoCalldisplay(true);

    if (!api) {
      const domain = "8x8.vc";
      const user = {
        id: "123",
        name: DECRYPTED_DATA().firstName.replace(/\s+/g, ""),
        email: "",
        username: DECRYPTED_DATA().firstName.replace(/\s+/g, ""),
      };
      const key = `vpaas-magic-cookie-80eaacd4d81c4d4b9932b0ebeb83dc96/VideoCall-${user.username}`;
      let uuid = uuidv4();
      const roomName = `${key}-${uuid}`;
      const options = {
        roomName: roomName,
        jwt: JitsiToken,
        width: "100%",
        height: "100%",
        userInfo: {
          displayNameReadOnly: true,
          displayName:
            DECRYPTED_DATA().firstName.replace(/\s+/g, "") +
            " " +
            DECRYPTED_DATA().lastName.replace(/\s+/g, ""),
          role: "moderator",
        },
        participantsPane: {
          hideModeratorSettingsTab: false,
          hideMoreActionsButton: false,
          hideMuteAllButton: false,
        },

        configOverwrite: {
          enableFeaturesBasedOnToken: true,
          brandingRoomAlias:
            "VideoCall" +
            "-" +
            `${user.username}` +
            "-" +
            `${uuid}` +
            '#config.toolbarButtons=["camera","screenshot","chat","etherpad","fullscreen","hangup","microphone","noisesuppression", "participants-pane","profile","recording","security","settings","toggle-camera","videoquality"]',
          customToolbarButtons: [
            {
              icon: String(image),
              id: "custom-toolbar-button",
              text: "Screenshot",
            },
          ],
          prejoinPageEnabled: true,
          enableLobby: true,
          startWithVideoMuted: true,
          startWithAudioMuted: true,
          toolbarButtons: [
            "camera",
            "screenshot",
            "chat",
            "etherpad",
            "fullscreen",
            "hangup",
            "invite",
            "microphone",
            "noisesuppression",
            "participants-pane",
            "profile",
            "recording",
            "security",
            "settings",
            "toggle-camera",
            "videoquality",
          ],

          enableLobby: true,
          customParticipantMenuButtons: [
            {
              icon: "data:image/svg+xml;base64,...",
              id: "custom-button",
              text: "Custom Button",
            },
          ],
        },
      };

      const jitsiUrl = `https://${domain}/${roomName}#config.toolbarButtons=["camera","screenshot","chat","etherpad","fullscreen","hangup","microphone","noisesuppression", "participants-pane","profile","recording","security","settings","toggle-camera","videoquality"]`;
      const payload = {
        videoUrl: jitsiUrl,
      };
      fetch("https://sw-devtest-api.azurewebsites.net/videocall", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((data) => { })
        .catch((error) => {
          console.error("ERROR", error);
        });

      const formContainer = document.getElementById("form12");
      const videoCallContainer = document.getElementById("video-call12");

      let newApi = new window.JitsiMeetExternalAPI(domain, {
        ...options,
        parentNode: videoCallContainer,
      });
      setapi(newApi);
      videoCallContainer.style.display = "flex";
      formContainer.style.flexBasis = "50%";
      videoCallContainer.style.flexBasis = "50%";

      newApi.on("toolbarButtonClicked", (buttonId) => {
        if (buttonId.key === "custom-toolbar-button") {
          newApi
            .captureLargeVideoScreenshot()
            .then((data) => {
              if (data.dataURL === undefined) {
                alert("Please Turn On Camera");
              } else {
                const a = document.createElement("a");
                a.href = data.dataURL;
                a.download = "screenshot.png";
                a.click();
              }
            })
            .catch((error) => {
              console.error("Error capturing screenshot", error);
            });
        }
      });
      newApi.executeCommand("toggleVideo");
      newApi.executeCommand("toggleLobby", true);
      newApi.on("readyToClose", () => {
        newApi?.dispose();
        newApi = null;
        setapi(newApi);

        isCallinProgress = true;
        setVideoCalldisplay(false);
        videoCallContainer.style.display = "none";
        videoCallContainer.style.flexBasis = "";
        formContainer.style.flexBasis = "";
      });
    }
  };

  const checkFieldValidation = () => {
    if (emailError !== "") {
      return false;
    }
    const sectionData = form_template.find(
      (item) => item.sectionId === _selectedSections[getProceed]
    );
    let isValid = true;
    (sectionData.section && sectionData.section[getCrewLead]
      ? sectionData.section[getCrewLead]
      : sectionData
    ).questions.forEach((question) => {
      if (
        question.state === "Required" &&
        question.visibility === true &&
        (question.questionType === "Radio+Signature"
          ? !question.signature
          : question.questionType === "Description+Dropdown+Rearrange"
            ? question.options.some((value) => !value)
            : question.questionType === "Radio+Signature"
              ? !question.signature
              : question.questionType === "Multiple-questions"
                ? question.multipleQuestion.some((value) => !value)
                : question.questionType === "Radio+Signature"
                  ? !question.signature
                  : question.questionType === "Sub-question"
                    ? !question.subQuestion[0].answer ||
                    !question.subQuestion[2].answer ||
                    (question.emailValidation === "Yes"
                      ? !question.subQuestion[1].answer.includes("@")
                      : !question.subQuestion[1].answer) ||
                    !question.subQuestion[3].answer ||
                    !question.subQuestion[4].answer
                    : question.questionType === "Radio+Signature"
                      ? !question.signature
                      : question.questionType === "Description"
                        ? !question.answer
                        : question.questionType === "Radio+Signature"
                          ? !question.signature
                          : question.questionType === "Text"
                            ? !question.answer
                            : question.questionType === "Radio+Signature"
                              ? !question.signature
                              : question.questionType === "Signature"
                                ? !question.signature
                                : question.questionType === "Radio+Signature"
                                  ? !question.signature
                                  : question.questionType === "Image"
                                    ? !question.picture
                                    : question.questionType === "Radio+Signature"
                                      ? !question.signature
                                      : question.questionType === "Dropdown"
                                        ? question.answer &&
                                          !question.apiDependentDropdown &&
                                          question.answer.split("-")[0] === "Other"
                                          ? !question.answer.split("-")[1]
                                          : !question.answer
                                        : question.questionType === "Radio+Signature"
                                          ? !question.signature
                                          : question.questionType === "Multiple-Image"
                                            ? !question.multiple_picture || question.multiple_picture.length === 0
                                            : question.questionType === "Radio+Signature"
                                              ? !question.signature
                                              : question.questionType === "Terms&condition"
                                                ? question.answer
                                                  ? !question.checkbox_model[0].value === true
                                                  : !question.answer
                                                : !question.answer.trim())
      ) {
        isValid = false;
        return;
      }
    });
    return isValid;
  };

  const onChangeCertificate = (event) => {
    let _form_template = _.cloneDeep(form_template);
    _form_template.map((obj, k) => {
      if (obj.sectionId === getCertifySection.sectionId) {
        setStepSection(k);
        return obj.questions.map((s_obj) => {
          if (s_obj.questionId === getCertifySection.questionId) {
            if (!_.isEmpty(event.certificateModel)) {
              let multiImgNew = [];
              const multiImgOld = event.certificateModel.map((i) =>
                i.certificatePicList.map((j, idx) => j)
              );
              for (let i = 0; i < multiImgOld.length; i++) {
                multiImgOld[i][0].back = multiImgOld[i][0].back.split("?")[0];
                multiImgOld[i][0].front = multiImgOld[i][0].front.split("?")[0];
                multiImgNew.push(multiImgOld[i][0]);
              }
              s_obj.answer = event.certificateModel.map((i) =>
                i.certificateName !== undefined
                  ? [i.certificateName]
                  : i.certificateMultipleName
              );
              s_obj.multiple_picture = multiImgNew;
              s_obj.notes = event.certificateModel.map(
                (r) => r.certificateDate
              );
            } else {
              s_obj.answer = "";
              s_obj.multiple_picture = [];
              s_obj.notes = "";
            }
          }
          return s_obj;
        });
      }
      return obj;
    });
    setForm_template(_form_template);
  };

  console.log("WebApp", form_template);

  return (
    <Row>
      <Row>
        <Col>
          <FormProgressBarforDynamic
            inProgressSection={
              getIjhaform.inProgressSection !== "" &&
                getIjhaform.inProgressSection !== undefined
                ? true
                : false
            }
            getIsCompleted={getIsCompleted[getCrewLead]}
            getProgressCount={getProceed1 === 0 ? getProceed + 1 : 15}
            getCrewLead={getCrewLead}
            stepSection={getProceed}
            setRequired={setRequired}
            _selectedSectionJson={form_template.filter((obj, i) =>
              _selectedSections.includes(obj.sectionId)
            )}
            getsummary={_selectedSections.length === getProceed}
            setProceed={setProceed}
            form_template={form_template}
            getReportCrewCheck={getReportCrewCheck}
            getCrewfill={getCrewfill}
            getSubmitMsg={getSubmitMsg}
          />
        </Col>
        {showMdl ? (
          <ModalMapCustomforIjhaDynamic
            showMdl={showMdl}
            setShowMdl={setShowMdl}
            dataMdl={getDataMdl}
            getAddress={getAddress}
            setAddress={setAddress}
            setDirections={setDirections}
            directions={directions}
            form_template={form_template}
            setForm_template={setForm_template}
          />
        ) : (
          <ModalMapTramaCenterforIJhaDynamic
            showMdl={showTraumaMdl}
            setShowMdl={setShowTraumaMdl}
            dataMdl={getDataMdl}
            getAddress={getAddress}
            setAddress={setAddress}
            form_template={form_template}
            setForm_template={setForm_template}
          />
        )}
      </Row>
      <div>
        <Row>
          <div
            id="container12"
            className={videoCalldisplay ? "container12" : null}
          >
            <div id="form12" className={videoCalldisplay ? "form12" : null}>
              <Row className="shadow-lg p-3 mb-5 mt-3 rounded ">
                {_selectedSection_render.map((obj, i) => {
                  return (
                    <Row key={i}>
                      <Row className="mb-3 ">
                        <Col lg="12">
                          <div className="form-subheading-1">
                            {obj.sectionName}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        {obj.questions.map((s_obj, k) => {
                          return (
                            <Row xs={12} key={k}>
                              <Col className="sect">
                                <SectionCompForIJHA
                                  getReportID={getReportID}
                                  videodisplayes={videodisplayes}
                                  setvideodisplayes={setvideodisplayes}
                                  initiateVideoCall1={initiateVideoCall1}
                                  setImgMetaData={setImgMetaData}
                                  getImgMetaData={getImgMetaData}
                                  setErrMsg={setErrMsg}
                                  typeofstructure={typeofstructure}
                                  questions_obj={s_obj}
                                  sectionId={obj.sectionId}
                                  emailError={emailError}
                                  setEmailError={setEmailError}
                                  entiresection={obj}
                                  getLongitude={getLongitude}
                                  getLatitude={getLatitude}
                                  onChangeFormTemplate={onChangeFormTemplate}
                                  setLoading={setLoading}
                                  sectionDeficiency={sectionDeficiency}
                                  sectionCustomCorrective={sectionCustomCorrective}
                                  changeFormTemplateDataforSpecificSection={changeFormTemplateDataforSpecificSection}
                                  sectionName={obj.sectionName}
                                  setEmailValidationforField={setEmailValidationforField}
                                  setWeatherHours={setWeatherHours}
                                  sendData={sendData}
                                  getWeatherHours={getWeatherHours}
                                  radioValue={radioValue}
                                  getProceed={getProceed}
                                  questions_obj_key={k}
                                  form_template={form_template}
                                  setForm_template={setForm_template}
                                  setRadioValue={setRadioValue}
                                  modalMapHandler1={modalMapHandler1}
                                  removeImgData={removeImgData}
                                  getAddress={getAddress}
                                  changeFormTemplateData={changeFormTemplateData}
                                  getCertificateOptionListD={getCertificateOptionListD}
                                  setCertifyData={setCertifyData}
                                  getCrewLead={getCrewLead}
                                  getCertifyData={getCertifyData}
                                  setCertifySection={setCertifySection}
                                  onChangeCertificate={onChangeCertificate}
                                  modalMapCustomHandler={modalMapCustomHandler}
                                />
                              </Col>
                            </Row>
                          );
                        })}
                      </Row>
                    </Row>
                  );
                })}
                <Row>
                  {(_.isEmpty(getIjhaform.reportId) ||
                    (!_.isEmpty(getIjhaform.reportId) &&
                      (!getIsCompleted.every((obj) => obj === true) ||
                        getIsCompleted.every((obj) => obj === true)) &&
                      !getLoading1)) &&
                    _selectedSections.length === getProceed &&
                    !getSubmitMsg && (
                      <Row lg="12">
                        <Col>
                          <div className="form-subheading-1">Summary</div>
                          <br />
                          <SummaryForSiteInspection
                            form_template={form_template.filter((obj, i) =>
                              _selectedSections.includes(obj.sectionId)
                            )}
                            setForm_template={setForm_template}
                            setProceed1={setProceed1}
                            getCrewMemberData={getCrewMemberData}
                            setCrewfill={setCrewfill}
                            setIsCompleted={setIsCompleted}
                            setCrewLeadData={setCrewLeadData}
                            getCrewLeadData={getCrewLeadData}
                            setvalidationofsubmit={setvalidationofsubmit}
                            validationofsubmit={validationofsubmit}
                            setSummarybutton={setSummarybutton}
                            setSummarypage={setSummarypage}
                            getIsCompleted={getIsCompleted}
                            setDelCrew={setDelCrew}
                            setProceed={setProceed}
                            setCrewMenProceed={setCrewMenProceed}
                            setCrewLead={setCrewLead}
                            getCrewLead={getCrewLead}
                          />
                        </Col>
                      </Row>
                    )}

                  {getSubmitMsg === "" && getLoading1 && (
                    <div>
                      <Loading width="32" height="32" />
                      <h5 className="mt-3 d-flex justify-content-center text-center">
                        Please wait while we process your{" "}
                        {getFormData.form_name} submission.
                      </h5>
                      <h5 className="mt-1 p-2 d-flex justify-content-center text-center">
                        Do not refresh the page or navigate away while this is
                        happening.
                      </h5>
                    </div>
                  )}

                  {getSubmitMsg !== "" && (
                    <div>
                      <Row xs={12}>
                        <Col className="d-flex flex-row bd-highlight align-items-center mb-3 position-relative">
                          <img
                            src={String(ic_complete_ppe)}
                            className="p-2 m-2"
                            alt="check-success"
                            style={{ width: "74px" }}
                          />
                          <h5 className="p-2 bd-highlight">
                            {getSubmitMsg !== "" && getSubmitMsg}
                            {` Your ${getFormData.form_name}  will be available in `}

                            {getSubmitMsg !== "" && (
                              <a
                                href="/reports"
                                style={{ fontSize: "1.25rem" }}
                              >
                                Reports tab
                              </a>
                            )}
                          </h5>
                        </Col>
                      </Row>
                      <Row xs={12}>
                        <Col className="d-flex justify-content-center mt-3 mb-3">
                          Click to go&nbsp;<a href="/">Home Page</a>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <Col lg="6">
                    {getSubmitMsg === "" && (
                      <>
                        <Button
                          onClick={onBackSec}
                          disabled={getProceed === 0 ? true : false}
                        >
                          Back
                        </Button>
                        {_selectedSections.length > getProceed ? (
                          <Button
                            className="ms-3"
                            variant="success"
                            disabled={
                              (!checkFieldValidation() &&
                                !getReportRequired &&
                                !!getbutton_control) ||
                              getLoading ||
                              !EmailValidationforField
                            }
                            onClick={proceedNxtSec}
                          >
                            Proceed
                          </Button>
                        ) : (
                          <div className="d-inline-block ms-3">
                            {_.isEmpty(getIjhaform.reportId) && (
                              <>
                                {parseInt(getFormData.formId) === 34 ? (
                                  <Row>
                                    <Col>
                                      <Button
                                        className="ms-3"
                                        variant={"success"}
                                        onClick={() => onSORFormSubmit("2")}
                                        disabled={
                                          getLoading ||
                                          !getIsCompleted.every(
                                            (obj) => obj === true
                                          )
                                        }
                                      >
                                        {" "}
                                        Approve
                                      </Button>
                                    </Col>
                                    <Col>
                                      <Button
                                        className="ms-3"
                                        variant={"danger"}
                                        onClick={() => onSORFormSubmit("3")}
                                        disabled={
                                          getLoading ||
                                          !getIsCompleted.every(
                                            (obj) => obj === true
                                          )
                                        }
                                      >
                                        {" "}
                                        Reject
                                      </Button>
                                    </Col>
                                  </Row>
                                ) : (
                                  <Button
                                    variant={"success"}
                                    onClick={() => onSORFormSubmit("")}
                                    disabled={
                                      getLoading ||
                                      !getIsCompleted.every(
                                        (obj) => obj === true
                                      )
                                    }
                                  >
                                    {" "}
                                    Submit
                                  </Button>
                                )}
                              </>
                            )}
                            {!_.isEmpty(getIjhaform.reportId) &&
                              !getIsCompleted.every((obj) => obj === true) ? (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Please make sure all sections are reviewed
                                    for all members in the{" "}
                                    {getFormData.form_name}
                                  </Tooltip>
                                }
                              >
                                {parseInt(getFormData.formId) === 34 ? (
                                  <span className="d-inline-block">
                                    <Button
                                      className="ms-3"
                                      variant={"success"}
                                      onClick={() => onSORFormSubmit("2")}
                                      disabled={
                                        getLoading ||
                                        !getIsCompleted.every(
                                          (obj) => obj === true
                                        )
                                      }
                                    >
                                      {" "}
                                      Approve
                                    </Button>

                                    <Button
                                      className="ms-3"
                                      variant={"danger"}
                                      onClick={() => onSORFormSubmit("3")}
                                      disabled={
                                        getLoading ||
                                        !getIsCompleted.every(
                                          (obj) => obj === true
                                        )
                                      }
                                    >
                                      {" "}
                                      Reject
                                    </Button>
                                  </span>
                                ) : (
                                  <span className="d-inline-block">
                                    <Button
                                      variant={"success"}
                                      onClick={() => onSORFormSubmit("")}
                                      disabled={
                                        getLoading ||
                                        !getIsCompleted.every(
                                          (obj) => obj === true
                                        )
                                      }
                                    >
                                      {" "}
                                      Submit
                                    </Button>
                                  </span>
                                )}
                              </OverlayTrigger>
                            ) : (
                              ""
                            )}
                            {!_.isEmpty(getIjhaform.reportId) &&
                              getIsCompleted.every((obj) => obj === true) ? (
                              <>
                                <>
                                  {parseInt(getFormData.formId) === 34 ? (
                                    <Row>
                                      <Col>
                                        <Button
                                          variant={"success"}
                                          onClick={() => onSORFormSubmit("2")}
                                          disabled={
                                            getLoading ||
                                            !getIsCompleted.every(
                                              (obj) => obj === true
                                            )
                                          }
                                        >
                                          {" "}
                                          Approve
                                        </Button>
                                      </Col>
                                      <Col>
                                        <Button
                                          variant={"danger"}
                                          onClick={() => onSORFormSubmit("3")}
                                          disabled={
                                            getLoading ||
                                            !getIsCompleted.every(
                                              (obj) => obj === true
                                            )
                                          }
                                        >
                                          {" "}
                                          Reject
                                        </Button>
                                      </Col>
                                    </Row>
                                  ) : (
                                    <Button
                                      variant={"success"}
                                      onClick={() => onSORFormSubmit("")}
                                      disabled={
                                        getLoading ||
                                        !getIsCompleted.every(
                                          (obj) => obj === true
                                        )
                                      }
                                    >
                                      {" "}
                                      Submit
                                    </Button>
                                  )}
                                </>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </Col>
                  <Col lg="1"></Col>
                </Row>
                {_selectedSections.length > getProceed && (
                  <Row>
                    <Col>
                      {" "}
                      {getLoading && <Loading width="32" height="32" />}
                    </Col>
                  </Row>
                )}
              </Row>
            </div>

            <div
              id="video-call-container"
              className={videoCalldisplay ? "video-call-container" : null}
            >
              <div
                id="video-call12"
                className={videoCalldisplay ? "video-call12" : null}
              >
                <div className="video-call-header">
                  {/* <div className="custom-toolbar-button"></div> */}
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </Row>
  );
}
