import { URL } from "../utils/Constant";
import { TOKEN, DECRYPTED_DATA } from "../utils/Variable";
import _ from "lodash";

export const getReports = async (
  dataJSON,
  setLoading,
  setMsg,
  setReportsData
) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  setLoading(true);
  // dataJSON['crewleadId'] = EMP_ID.replace(/['"]+/g, '');
  setMsg("");
  //const navigate = useNavigate();
  let jsonData = JSON.stringify(dataJSON);

  const resp = await fetch(URL.GetCrewReports, {
    method: "POST",
    body: jsonData,
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    setReportsData(data.data);
    resp.status === 201 && setMsg(data.message);
    setLoading(false);
  }
};

export const downloadReports = async (url) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    // "Authorization": 'Basic ' + base64.encode(`swdashboarduser:swdashboard@2022`),
    "Content-Type": "application/json",
  };
  // setLoading(true);
  // dataJSON['crewleadId'] = EMP_ID.replace(/['"]+/g, '');
  // setMsg("");
  //const navigate = useNavigate();
  // let jsonData = JSON.stringify(dataJSON);
  // console.log(jsonData)

  const resp = await fetch(url, { method: "GET", headers: headersList });
  if (resp.ok) {
    const data = await resp;
    // console.log(data);
    window.open(
      url,
      "_blank" // <- This is what makes it open in a new window.
    );
    // resp.blob();
    // var file = window.URL.createObjectURL(blob);
    // window.location.assign(file);
    // resp.status === 201 && setMsg(data.message)
    // setLoading(false);
  }
};

export const isReportsUpdated = async (dataJSON, setMsg, setReportCount) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };

  // dataJSON['crewleadId'] = EMP_ID.replace(/['"]+/g, '');
  setMsg("");
  //const navigate = useNavigate();
  // let jsonData = JSON.stringify(dataJSON);
  // console.log(jsonData)
  const resp = await fetch(
    URL.GetCrewReports +
      `?start_date=${dataJSON["start_date"]}&end_date=${dataJSON["end_date"]}&status=[${dataJSON["status"]}]&formId=[${dataJSON["formId"]}]`,
    { method: "GET", headers: headersList }
  );
  if (resp.ok) {
    const data = await resp.json();
    setReportCount(data.count);
    resp.status === 201 && setMsg(data.message);
  }
};

export const getViewReportJSONApi = async (
  reportId,
  setLoading,
  setEditFormIjha,
  setCrewLeadData,
  Stateofform
) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  setLoading(true);
  const resp = await fetch(
    URL.GetViewReportJSON + `?reportId=${reportId}&AppType=Webapp`,
    { method: "GET", headers: headersList }
  );
  if (resp.ok) {
    const data1 = await resp.json();
    if (data1.status_code === 200) {
      data1.data["complete_status"] = "Submitted";
      data1.data["Report_state"] = Stateofform;
      const resp = await fetch(
        URL.GetEditCrewMember + `?reportId=${reportId}`,
        { method: "GET", headers: headersList }
      );
      if (resp.ok) {
        const data = await resp.json();
        if (data.status_code === 200) {
          let dataCD = {
            crewmemberdata: [],
            email: DECRYPTED_DATA().emailId,
            firstName: data.data.details[0].employeeName.split(" ")[0],
            lastName: data.data.details[0].employeeName.split(" ")[1],
            phone: "",
            // uncommented below to active climber and nonclimber
            membertype: data.data.details[0].membertype,
            membertype_list: data.data.membertype_list,
          };
          data.data.details.map((obj, i) => {
            if (i !== 0) {
              dataCD.crewmemberdata.push({
                emailId: obj.emailId,
                employeeId: obj.employeeId,
                firstName: obj.employeeName.split(" ")[0],
                lastName: obj.employeeName.split(" ")[1],
                phoneNumber: "",
                // uncommented below to active climber and nonclimber
                membertype: obj.membertype,
              });
            }
          });
          setCrewLeadData(dataCD);
          setEditFormIjha(data1.data);
        }
      }
    }
    setLoading(false);
  }
};

export const deleteInProcessReport = async (
  reportID,
  setLoading,
  setErrMsg
) => {
  setLoading(true);
  setErrMsg("");
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };

  const resp = await fetch(URL.DeleteProcessReports + `?reportId=${reportID}`, {
    method: "PUT",
    headers: headersList,
  });
  if (resp.ok) {
    const data = await resp.json();
    // console.log('data', data)
    setErrMsg(data.message);
  }
  setLoading(false);
};

export const getInProcessReportJSONApi = async (
  reportId,
  setLoading,
  setEditFormIjha,
  setCrewLeadData,
  Stateofform
) => {
  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + TOKEN(),
    "Content-Type": "application/json",
  };
  setLoading(true);
  const resp = await fetch(
    URL.Getincompletereport + `?reportId=${reportId}&AppType=Webapp`,
    { method: "GET", headers: headersList }
  );
  if (resp.ok) {
    const data1 = await resp.json();
    if (data1.status_code === 200) {
      data1.data["complete_status"] = "InProgress";
      data1.data["Report_state"] = Stateofform;
      const resp = await fetch(
        URL.GetCrewMemberDataInProcess + `?reportId=${reportId}`,
        { method: "GET", headers: headersList }
      );
      if (resp.ok) {
        const data = await resp.json();
        if (data.status_code === 200) {
          let dataCD = {
            crewmemberdata: [],
            email: DECRYPTED_DATA().emailId,
            firstName: data.data.details[0].employeeName.split(" ")[0],
            lastName: data.data.details[0].employeeName.split(" ")[1],
            phone: "",
            // uncommented below to active climber and nonclimber
            membertype: data.data.details[0].membertype,
            membertype_list: data.data.membertype_list,
          };
          data.data.details.map((obj, i) => {
            if (i !== 0) {
              dataCD.crewmemberdata.push({
                emailId: obj.emailId,
                employeeId: obj.employeeId,
                firstName: obj.employeeName.split(" ")[0],
                lastName: obj.employeeName.split(" ")[1],
                phoneNumber: "",
                // uncommented below to active climber and nonclimber
                membertype: obj.membertype,
              });
            }
          });
          setCrewLeadData(dataCD);
          setEditFormIjha(data1.data);
        }
      }
    }
  }
};
