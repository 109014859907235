import { Row, Col, Accordion } from "react-bootstrap";
import { useState, useEffect } from "react";
import { EMP_ID, DECRYPTED_DATA } from "../../utils/Variable";
import _ from "lodash";
import close from "../../assets/Icons/close.svg";
import check from "../../assets/Icons/check.svg";
export default function SummaryForSiteInspection({
  form_template,
  setProceed,
  setProceed1,
  getCrewLeadData,
  setCrewMenProceed,
  getCrewLead,
  setCrewLead,
  setCrewfill,
  setIsCompleted,
  setvalidationofsubmit,
  getIsCompleted,
  setDelCrew,
  setForm_template,
  setCrewLeadData,
  validationofsubmit,
  setSummarybutton,
  setSummarypage,
}) {
  const [getCount, setCount] = useState([]);
  const crewleadId = EMP_ID().replace(/['"]+/g, "");
  useEffect(() => {
    let _getCount = [];

    _getCount[crewleadId] = form_template.map((obj) => {
      let _fillcount = 0;
      let _totalcount = 0;

      (obj.section && obj.section[getCrewLead]
        ? obj.section[getCrewLead]
        : obj
      ).questions.map((s_obj, k) => {
        if (s_obj.visibility === true) {
          if (s_obj.questionType === "Radio+Signature") {
            !!s_obj.signature && _fillcount++;
          } else if (s_obj.questionType === "Table+Dropdown+Multi-select") {
            let _c = 0;
            s_obj.table_options.map((qobj, i) => {
              qobj.common && _fillcount++ && _totalcount++;
            });
            _c > 0 && _fillcount++;
          } else if (s_obj.questionType === "Sub-question") {
            let _c = 0;

            s_obj.subQuestion.map((sqobj) => {
              sqobj.answer !== "" && _c++;
            });
            _fillcount++;
          } else if (s_obj.questionType === "Multiple-questions") {
            obj.questions.map((qobj, i) => {
              qobj.answer !== "" && _fillcount++;
              _fillcount++;
            });
          } else if (s_obj.questionType === "Signature") {
            !!s_obj.signature && _fillcount++;
          } else if (s_obj.questionType === "Image") {
            !!s_obj.picture && _fillcount++;
          } else if (s_obj.questionType === "Description+Dropdown+Rearrange") {
            s_obj.options.map((qobj, i) => {
              qobj && _fillcount++;
              if (_fillcount > _totalcount) {
                _totalcount = _fillcount - 1;
              }
            });
          } else {
            !!s_obj.answer && _fillcount++;
          }
          _totalcount++;
        }
      });
      return {
        sectionId: obj.sectionId,
        visibility: obj.visibility,
        sectionName: obj.sectionName,
        fillcount: _fillcount,
        totalcount: _totalcount,
      };
    });

    setCount(_getCount);
  }, []);

  const crewLeadChangeHandler = (_key) => {
    let _getCrewLead = _.cloneDeep(getCrewLead);
    _getCrewLead = 0;
    setCrewLead(_getCrewLead);
    setProceed(_key);
    setProceed1(0);
  };
  return (
    <Accordion defaultActiveKey={[0]} alwaysOpen className="mb-3">
      <Accordion.Item eventKey={0}>
        <Accordion.Header>
          <div className="rounded border p-2 bd-highlight">
            <img
              src={DECRYPTED_DATA().profile_img_url}
              style={{ width: "60px" }}
              alt="profilePic"
            />
          </div>
          <div className="p-2 bd-highlight">
            {DECRYPTED_DATA().firstName + " " + DECRYPTED_DATA().lastName}
          </div>
          <img
            src={String(check)}
            className="ms-5 filter-green border border-success rounded-circle  "
            style={{ width: "22px", padding: "2px" }}
            alt="check-success"
          />
        </Accordion.Header>
        <Accordion.Body>
          <Row xs={12}>
            <Col md={4}>
              <ul className="list-group mt-3 mb-3">
                {!_.isEmpty(getCount[crewleadId]) &&
                  getCount[crewleadId].map((obj, i) => {
                    if (obj.visibility) {
                      return (
                        <div
                          className="ContainerSummary"
                          style={{
                            width: "180%",
                            maxWidth: "250px",
                            overflow: "inherit",
                          }}
                        >
                          <li
                            key={obj.sectionId}
                            className="list-group-item d-flex flex-row bd-highlight align-items-center"
                          >
                            <span
                              className={
                                obj.fillcount !== obj.totalcount
                                  ? "w-50 text-warning"
                                  : "w-50 text-success"
                              }
                              style={{ cursor: "pointer" }}
                              onClick={() => crewLeadChangeHandler(i)}
                            >
                              <b>{obj.sectionName}</b>
                            </span>
                            <span
                              className={
                                obj.fillcount !== obj.totalcount
                                  ? "ms-3 w-25 text-warning"
                                  : "ms-3 w-25 text-success"
                              }
                            >
                              ({obj.fillcount}/{obj.totalcount})
                            </span>
                            {obj.fillcount === obj.totalcount ? (
                              <img
                                src={String(check)}
                                className="ms-5 filter-green border border-success rounded-circle  "
                                style={{ width: "22px", padding: "2px" }}
                                alt="check-success"
                              />
                            ) : (
                              <img
                                src={String(close)}
                                className="ms-5 filter-red border border-danger rounded-circle  "
                                style={{ width: "22px", padding: "2px" }}
                                alt="check-failure"
                              />
                            )}
                          </li>
                        </div>
                      );
                    }
                  })}
              </ul>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
