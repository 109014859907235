import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { GetDropdownValues } from "../../api/DropdownValues.api";
import Select from "react-select";
import {
  Row,
  Col,
  Nav,
  Form,
  Button,
  ButtonGroup,
  ToggleButton,
  ProgressBar,
  OverlayTrigger,
  Tooltip,
  FormLabel,
} from "react-bootstrap";
import { UserGroupId } from "../../utils/Variable";

function DropdownForSubQuestion({
  s_obj,
  onChangeFormTemplate,
  sectionId,
  questions_obj,
  handleDropdownValueChange,
  UpdatedValueofDropdown,
  emailError,
  setEmailError,
  SelectedOptionofDropdown,
  setSelectedOptionofDropdown,
}) {
  const [dropdownoptions, setdropdownoptions] = useState([]);
  const [countryId, setdropdownID] = useState("");

  const [BlankTextField, setBlankTextField] = useState(false);
  const [newDropdownValue, setnewDropdownValue] = useState("");
  const [EmailErrorrRequired, setEmailErrorRequired] = useState("");

  useEffect(() => {
    if (SelectedOptionofDropdown) {
      handleDropdownValueChange(SelectedOptionofDropdown);
    }
  }, [SelectedOptionofDropdown]);
  // useEffect(() => {

  // }, [SelectedOptionofDropdown])

  const param1 = {
    filter: s_obj.userFilterSuggestion,
  };

  useEffect(() => {
    const getApiResponse = async () => {
      const response = await GetDropdownValues(param1);
      const getRes = await response;
      setdropdownoptions(await getRes);
    };
    getApiResponse();
  }, []);

  const handleDropdownChange = (SelectedOptionofDropdown) => {
    if (!BlankTextField) {
      setSelectedOptionofDropdown(null);
    }
    if (SelectedOptionofDropdown.value === "Other") {
      setEmailError("Please enter a valid email address");
      setSelectedOptionofDropdown(SelectedOptionofDropdown);
      setBlankTextField(true);
      // setdropdownoptions([...dropdownoptions, SelectedOptionofDropdown.value])
    } else {
      setEmailError("");
      setSelectedOptionofDropdown(SelectedOptionofDropdown);
      setdropdownID(SelectedOptionofDropdown.value);
      setBlankTextField(false);
      const emailRegex = /<(.+)>/;
      const match = SelectedOptionofDropdown.value.match(emailRegex);
      const email = match ? match[1] : SelectedOptionofDropdown.value;
      onChangeFormTemplate({
        sectionId,
        answer: email,
        questionId: questions_obj.questionId,
        subQuestionId: s_obj.questionId,
        updateSubQuestion: true,
      });

      // localStorage.setItem('SelectedOptionofDropdown', JSON.stringify(SelectedOptionofDropdown))
    }
  };

  useEffect(() => {
    if (BlankTextField) {
      onChangeFormTemplate({
        sectionId,
        answer: newDropdownValue,
        questionId: questions_obj.questionId,
        subQuestionId: s_obj.questionId,
        updateSubQuestion: true,
      });
    }
  }, [BlankTextField]);

  const handleAddnewValue = () => {
    if (newDropdownValue) {
      const emailPattern = /^\S+@\S+\.\S+$/; // Regex pattern to validate email
      if (
        s_obj.emailValidation === "Yes" &&
        !emailPattern.test(newDropdownValue)
      ) {
        setEmailError("Please enter a valid email address");
        setEmailErrorRequired("Please enter a valid email address");
        return;
      }

      const updateDropdown = [...dropdownoptions, newDropdownValue];
      setdropdownoptions(updateDropdown);
      setSelectedOptionofDropdown({
        value: newDropdownValue,
        label: newDropdownValue,
      });
      setdropdownID(newDropdownValue);
      setnewDropdownValue("");
      setBlankTextField(false);
      onChangeFormTemplate({
        sectionId,
        answer: newDropdownValue,
        questionId: questions_obj.questionId,
        subQuestionId: s_obj.questionId,
        updateSubQuestion: true,
      });
      setEmailError("");
      setEmailErrorRequired("");
      // localStorage.setItem('SelectedOptionofDropdownApi', JSON.stringify({ value: newDropdownValue, label: newDropdownValue }))
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: 32,
      fontSize: 14,
    }),
  };

  const options = [
    ...dropdownoptions.map((option) => ({ value: option, label: option })),
  ];

  // useEffect(() => {

  // }, [SelectedOptionofDropdown])
  return (
    <>
      <Form.Label>
        <span className="text-danger bold-font">
          {s_obj.state === "Required" ? "*" : ""}
        </span>
        {s_obj.question}
      </Form.Label>
      <Select
        options={options}
        value={
          s_obj.answer !== ""
            ? SelectedOptionofDropdown
            : SelectedOptionofDropdown &&
              SelectedOptionofDropdown.value === "Other"
            ? SelectedOptionofDropdown
            : { value: s_obj.answer, label: s_obj.answer }
        }
        // value={SelectedOptionofDropdown}
        onChange={handleDropdownChange}
        styles={customStyles}
        placeholder="--Select --"
        isSearchable
      />
      <br />
      {BlankTextField && (
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <Form.Control
            type="text"
            placeholder={s_obj.placeholder}
            value={newDropdownValue}
            onChange={(e) => setnewDropdownValue(e.target.value)}
            isInvalid={!!EmailErrorrRequired} // Set the isInvalid prop based on the emailError state variable
          />
          <Button
            onClick={handleAddnewValue}
            style={{ marginLeft: "10px", alignSelf: "flex-end" }}
          >
            Add
          </Button>
        </div>
      )}
      <Form.Control.Feedback type="invalid">
        {EmailErrorrRequired}
      </Form.Control.Feedback>
      <br />
    </>
  );
}

export default DropdownForSubQuestion;
