import { EnvironmentVariables } from "../api/ApiEndpointUrl.api";

// // // Prod
// export const tenantURI = "https://login.safeworker.ericsson.net/";
// export const tenantName = "login.safeworker.ericsson.net";
// export const tenantID = "313644fd-193b-4a96-8563-e59d23907f95";
// export const domain_url = "https://api.safeworker.ericsson.net";
// export const client_ID = "9ee7afec-71ab-4a00-b60f-094ddba80980";
// export const appURL = "https://dashboard.safeworker.ericsson.net";
// export const redirect_URI = "https://safeworker.ericsson.net";
// export const redirect_URI = "http://localhost:3000";

// // // Dev
// export const tenantURI = "https://login.dev.safeworker.ericsson.net/";
// export const tenantName = "login.dev.safeworker.ericsson.net";
// export const tenantID = "1f767369-66de-4ece-ba9f-42b8458bb798";
// export const domain_url = "https://api.dev.safeworker.ericsson.net";
// export const client_ID = "678e277a-ea1d-426f-a30d-11a7e681dc8e";
// export const appURL = "https://dashboard.dev.safeworker.ericsson.net";
// export const redirect_URI = "https://dev.safeworker.ericsson.net";
// export const redirect_URI = "http://localhost:3000"; 

// // // Acc
// export const tenantURI = "https://login.acc.safeworker.ericsson.net/";
// export const tenantName = "login.acc.safeworker.ericsson.net";
// export const tenantID = "bf7ce1de-3f84-4bc7-acdd-6777560485f5";
// export const domain_url = "https://api.acc.safeworker.ericsson.net";
// export const client_ID = "01c468fd-c14b-4b4e-8abb-4094dee34741";
// export const redirect_URI = "https://acc.safeworker.ericsson.net";
// export const appURL = "https://dashboard.acc.safeworker.ericsson.net";
// export const redirect_URI = "http://localhost:3000";

export const googleMapsKey = "AIzaSyDRKDDO62Em9_CVS5DMXkl6p6mlAPNY7dg";
export const ConfigurationValues = await EnvironmentVariables();
export const {
  client_ID,
  redirect_URI,
  tenantID,
  tenantName,
  tenantURI,
  report_url,
  domain_url,
  appURL,
} = ConfigurationValues;
export const URL = {
  Login: domain_url + "/login",
  SwitchUserGroup: domain_url + "/switch_usergroup",
  GetJWTToken: domain_url + "/get_jitsi_token",
  Register: domain_url + "/user_registration",
  ChangePassword: domain_url + "/change_password",
  Profile: domain_url + "/user_details",
  CertifiateOptionList: domain_url + "/certificate_list",
  EditProfile: domain_url + "/edit_profile",
  GetWeather: domain_url + "/get_weather",
  UploadMultiImage: domain_url + "/upload_multipleimg",
  DetectAI: domain_url + "/detect",
  SubmitIJHA: domain_url + "/submit_form",
  GetFrom: domain_url + "/get_form",
  GetSupportDropdown: domain_url + "/get_halo_featurelist",
  GetCrewMemberData: domain_url + "/get_crew_member_details",
  PostPrivacyCheck: domain_url + "/privacy_policy_check",
  GetDropdownValues: domain_url + "/get_users_by_filter_type",
  SendResetPasswordVerificationEmail:
    domain_url + "/send_reset_password_verification_email", //GET
  CheckOTPResetPassword: domain_url + "/reset_password_verify_otp", //GET
  ResetPassword: domain_url + "/reset_password", //PUT
  TraumaCentreLevels: domain_url + "/get_traumacentrelevels",
  TraumaCentreNames: domain_url + "/get_traumacentres",
  TraumaCentreCurrentLocation: domain_url + "/get_nearbysearch", //GET
  ProfilePicUpdate: domain_url + "/update_profilepic",
  AddCertifiate: domain_url + "/add_certificate",
  DeleteCertificate: domain_url + "/delete_certificate",
  UpdateCertifiate: domain_url + "/update_certificates",
  GetAddCrewMember: domain_url + "/add_crew_webapp", //GET,POST
  GetSuggestion: domain_url + "/get_autosuggestion",
  GetSafetyHazardTopic: domain_url + "/get_safety_hazard_topic",
  GetCrewReports: domain_url + "/get_crew_report_new", //POST
  GetInProcessReports: domain_url + "/get_incompletereports", //GET
  DeleteProcessReports: domain_url + "/delete_incompletereport", //PUT
  Getincompletereport: domain_url + "/view_incompletereport", //GET
  GetCrewMemberDataInProcess: domain_url + "/get_incomplete_reportcrewdetails", //GET
  GetHospitalDirectionFromTo: domain_url + "/hospital_change",
  Get911AddressInfo: domain_url + "/location_change_new",
  GetViewReportJSON: domain_url + "/view_userreport",
  GetEditCrewMember: domain_url + "/get_reportcrewdetails", //GET,POST
  SentOTP: domain_url + "/send_verification_email",
  VerifyOTP: domain_url + "/verify_email",
  ValidDomains: domain_url + "/get_valid_domains", // GET
  GetAllForms: domain_url + "/get_allforms",
  DetectAIALL: domain_url + "/get_all_ppe_ai_data",
  Support_Form: { GENERATE_DETAILS: domain_url + "/create_ticket_halo" },
  Image_Reuploads: {
    GETMETADATA: domain_url + "/get_image_metadata",
  },
  LastLoginDetails: domain_url + "/graph_last_logged",
};
export const SECRET_KEY = "my-secret-key@swwebapp2022";
// TEST_RECAPTCHA_SITE_KEY
export const RECAPTCHA_SITE_KEY = "6LfppAAhAAAAAMXNz6W48_SDPHGraeSHiKUbJ308";
// production
// export const RECAPTCHA_SITE_KEY = "6LdmhQEhAAAAAMcxON0etAzySQIHhfWtAYe4wFee";
// export const colourOptions = [
//     {value:'o'}
// ]
// export const HEADERS = {
//     "Accept": "*/*",
//     "Content-Type": "application/json"
// }
