import {
  Row,
  Col,
  Form,
  Button,
  ButtonGroup,
  ToggleButton,
  OverlayTrigger,
  Tooltip,
  Nav,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import ToastMessage from "../../components/ToastMessage/ToastMessage";
import Signature from "../../components/Signature/Signature";
import Figure from "react-bootstrap/Figure";
import ExampleImage from "../../assets/Icons/ExampleImage.png";
import EXIF from "exif-js";
import {
  getDataInFormat,
  removeTags,
  getUrlWithQueryString,
  formatAMPM,
  convertTemperature,
  convertMilesPerHour,
  getTemperatureColorFilter,
  getWindGustColorFilter,
} from "../../utils/Helper";
import { useState, useEffect, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment-timezone";
import {
  uploadMultiImage,
  detectAI,
  detectAIALL,
} from "../../api/CreateiJHA.api";
import ic_trauma_center from "../../assets/Icons/ic_trauma_center.png";
import { checkImageData } from "../../api/ImageMetaData.api";
import { DECRYPTED_DATA, EMP_ID, IMG_TOKEN } from "../../utils/Variable";
import Resizer from "react-image-file-resizer";
import trashcan from "../../assets/Icons/trashcan.svg";
import ic_ai from "../../assets/Icons/ic_ai.png";
import ic_partly_sunny from "../../assets/Icons/ic_partly_sunny.png";
import "./SectionCompForIJHA.scss";
import CryptoJS from "crypto-js";
import ic_rain from "../../assets/Icons/ic_rain.png";
import ic_sunny from "../../assets/Icons/ic_sunny.png";
import { v4 as uuidv4 } from "uuid";
import ic_temperature from "../../assets/Icons/ic_temperature.png";
import ic_wind from "../../assets/Icons/ic_wind.png";
import ic_uv_index from "../../assets/Icons/ic_uv_index.png";
import ic_sky from "../../assets/Icons/ic_sky.png";
import info from "../../assets/Icons/info.svg";
import ic_visibility from "../../assets/Icons/ic_visibility.png";
import ic_humidity from "../../assets/Icons/ic_humidity.png";
import ic_pressure from "../../assets/Icons/ic_pressure.png";
import ic_precipitation from "../../assets/Icons/ic_precipitation.png";
import position from "../../assets/Icons/position.svg";
import _, { debounce } from "lodash";
import { getWeatherApi } from "../../api/Common.api";
import map_directions from "../../assets/Icons/map-directions.svg";
import DailyTaskforIJHA from "../../components/DailyTask/DailyTaskforIJHA";

import MultiSelect from "../../components/MultiSelect/MultiSelect";
import MultiSelect1 from "../../components/MultiSelect1/MultiSelect1";
import MultiSelectforCrockets from "../../components/MultiSelect/MultiSelectforCrockets";
import MultiSelect1forCrockets from "../../components/MultiSelect1/MultiSelect1forCrockets";
import MultiSelect1forCrockets1 from "../../components/MultiSelect1/MultiSelect1forCrockets1";
import GenericQuestionTypes from "../../components/GenericQuestionTypes/GenericQuestionTypes";
export default function SectionCompForIJHA({
  questions_obj,
  sectionId,
  onChangeFormTemplate,
  sectionName,
  modalMapHandler,
  modalMapHandler1,
  modalMapDirectionHandler,
  getLongitude,
  getLatitude,
  getAddress,
  changeFormTemplateData,
  _weatherDetails,
  getCertificateOptionListD,
  setCertifyData,
  getCertifyData,
  onChangeCertificate,
  changeFormTemplateDataforSpecificSection,
  radioValue,
  setRadioValue,
  getLoc,
  setCertifySection,
  removeImgData,
  form_template_p,
  getAddMoreDailyTaskFunc,
  form_template,
  setbutton_control,
  getbutton_control,
  getProceed,
  setLoading,
  getLoading,
  setCustomername1,
  getCustomername1,
  setForm_template,
  getCrewLead,
  setRequired,
  getSiteLandLord,
  Options123,
  Options1,
  NewError,
  register,
  handleSubmit,
  setError,
  errors,
  clearErrors,
  ValuesForisVerified,
  setError2,
  getVerifiedValue,
  setVerifiedValue,
  getCrewLeadData,
  getIjhaform,
  setpbtn,
  getpbtn,
  weatherDetailsChange,
  sendData,
  entiresection,
  addMorePPEOther,
  setErrMsg,
  typeofstructure,
  getImgMetaData,
  setImgMetaData,
  setUpdateWeather,
  getUpdateWeather,
  wheather_radio,
  getUnit,
  questions_obj_key,
  getChecksumValue,
  setChecksumValue,
  getWeatherHours,
  setWeatherHours,
  getReportID,
}) {
  const [warningmessagerequired, setwarningmessagerequired] = useState({
    WindGust: "",
    Temperature: "",
    toastErrMsg: false,
  });
  const [getDataMdl1, setDataMdl1] = useState({
    heading: "",
    body: "",
    showClose: false,
    saveBtnName: "",
  });
  const loc = useLocation();
  const [getFieldType, setFieldType] = useState();
  const [showMdl1, setShowMdl1] = useState(false);
  const [getSignBase64Uri, setSignBase64Uri] = useState("");
  const [getHCMOptions, setHCMOptions] = useState([]);
  const [exifData, setExifData] = useState(null);
  const [getWeatherupdate, setWeatherUpdate] = useState(false);
  const [ErrorValues, setErrorValues] = useState();
  const [getHCMOptions12, setHCMOptions12] = useState([]);
  const [getHCMOptions1, setHCMOptions1] = useState([]);
  const [getSelectedOptionsMulti12, setSelectedOptionsMulti12] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const [getSelectedOptionsMulti1, setSelectedOptionsMulti1] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const [getSelectedOptionsMulti, setSelectedOptionsMulti] = useState([
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    [],
  ]);
  const [getCommentMulti1, setCommentMulti1] = useState([]);
  const [getImgAiDetectKeys2, setImgAiDetectKeys2] = useState({});
  const [getForecasts, setForecasts] = useState([]);
  const [getProceed12, setProceed12] = useState(0);
  const [getUploadedImgUrls, setUploadedImgUrls] = useState([]);
  const [getAddMoreDailyTask, setAddMoreDailyTask] = useState([]);

  const [getError1, setError1] = useState("");
  const [getImgAiDetectKeys, setImgAiDetectKeys] = useState({});
  const [getAIData, setAIData] = useState({});

  const [getUploadedImgUrls1, setUploadedImgUrls1] = useState([]);
  const [getUploadedImgUrls12, setUploadedImgUrls12] = useState([]);

  const [getSetP, setSetP] = useState(false);
  const [getState1, setState1] = useState(false);
  const [getState12, setState12] = useState(false);
  const [getImgUrls, setImgUrls] = useState({});
  const [getWeatherStatus, setWeatherStatus] = useState(false);

  useEffect(() => {
    if (getWeatherStatus === true) {
      getWeatherApi(setForm_template, form_template, sendData, setWeatherHours);
    }
  }, [radioValue]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1600,
        1600,
        "JPEG",
        90,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        300,
        300
      );
    });

  const getSignUrl = useCallback(() => {
    const sendDataImg = {
      files: [getSignBase64Uri],
      emailId: DECRYPTED_DATA().emailId,
      reportId: getReportID,
      upload_type: "JHA",
      section_names: sectionName,
      employeeId: EMP_ID(),
      setLoading: setLoading,
      setSetP: setSetP,
    };

    uploadMultiImage(setUploadedImgUrls, sendDataImg, setImgUrls);
  }, [getSignBase64Uri]);

  function getAddMoreDailyTaskFunc() {
    let _getAddMoreDailyTask = _.cloneDeep(getAddMoreDailyTask);
    let _form_template = _.cloneDeep(form_template);
    _form_template[4].questions[0].multipleQuestion.push({
      questions: [
        {
          description: "",
          question: "Task",
          questionType: "Description",
        },
        {
          description: "",
          question: "Hazards",
          questionType: "Description",
        },
        {
          description: "",
          question: "Mitigations",
          questionType: "Description",
        },
      ],
    });
    setForm_template(_form_template);
  }

  useEffect(() => {
    if (getImgAiDetectKeys.ai === "ai") {
      let category = questions_obj.category;
      detectAI(
        setAIData,
        getUploadedImgUrls12[0],
        category,
        getImgAiDetectKeys,
        setImgAiDetectKeys,
        changeFormTemplateDataforSpecificSection,
        setLoading,
        13,
        getImgAiDetectKeys.indexValue
      );
    } else if (getImgAiDetectKeys.ai === "") {
      onChangeFormTemplate([
        13,
        getUploadedImgUrls12[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
      ]);
    } else if (getImgAiDetectKeys.ai === "pic") {
      onChangeFormTemplate([
        19,
        getUploadedImgUrls12[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "picture",
      ]);
    } else if (getImgAiDetectKeys.ai === "sign") {
      changeFormTemplateData([
        19,
        getUploadedImgUrls12[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "signature",
      ]);
    } else if (getImgAiDetectKeys.ai === "subQuestionPic") {
      changeFormTemplateDataforSpecificSection([
        20,
        getUploadedImgUrls12[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "picture",
      ]);
    } else if (getImgAiDetectKeys.ai === "picNoSection") {
      changeFormTemplateData([
        21,
        getUploadedImgUrls12[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "picture",
      ]);
    } else if (getImgAiDetectKeys.ai === "PersonalFallArrestSystemMultiPic") {
      changeFormTemplateData([
        27,
        getUploadedImgUrls12[0],
        getImgAiDetectKeys.section,
        getImgAiDetectKeys.questions,
        "multiple_picture",
      ]);
    } else if (getImgAiDetectKeys.ai === "ai-hazard-control") {
      let category = questions_obj.category;
      detectAI(
        setAIData,
        getUploadedImgUrls12[0],
        category,
        getImgAiDetectKeys,
        setImgAiDetectKeys,
        changeFormTemplateDataforSpecificSection,
        setLoading,
        25
      );
    } else if (getImgAiDetectKeys.ai === "ai-all") {
      aiALLDetectJson(getUploadedImgUrls12[0]);
    }
  }, [
    getUploadedImgUrls12,
    getImgAiDetectKeys,
    getImgMetaData,
    getChecksumValue,
    getVerifiedValue,
  ]);

  let question1 = "Table+Dropdown+Multi-select";

  if (questions_obj.questionType.includes(question1)) {
    var HCMOptions12 = questions_obj.table_options.map((obj, i) => {
      return {
        value: obj.question,
        label: obj.question,
        color: "#00B8D9",
        isFixed: obj.selected_options.length ? true : false,
        _options: obj.options.map((s_obj, index) => {
          const key = Object.keys(s_obj)[0];
          return {
            value: key,
            label: key,
            color: "#00B8D9",
            _mitigationsoptions: obj.options,
            _s_key: index,
          };
        }),

        _key: 0,
        _s_key: i,
      };
    });
  }
  if (questions_obj.questionType.includes(question1)) {
    var HCMOptions = questions_obj.table_options.map((obj, i) => {
      return {
        value: obj.question,
        label: obj.question,
        color: "#00B8D9",
        isFixed: obj.selected_options.length ? true : false,
        _options: obj.options.map((s_obj) => {
          return {
            value: s_obj,
            label: s_obj,
            color: "#00B8D9",
          };
        }),
        _key: 0,
        _s_key: i,
      };
    });
  }

  const pageIndex = form_template.findIndex(
    (item) => item.sectionId === sectionId
  );

  const dailytaskQDel = (k) => {
    let _form_template = _.cloneDeep(form_template);
    const dropdownQuestionIndex = _form_template[
      getProceed
    ].questions.findIndex(
      (question) => question.questionType === "Description+Dropdown+Rearrange"
    );
    if (dropdownQuestionIndex !== -1) {
      _form_template[getProceed].questions[
        dropdownQuestionIndex
      ].options.splice(k, 1);
    }
    const multipleQuestionIndex = _form_template[
      getProceed + 1
    ].questions.findIndex(
      (question) => question.questionType === "Multiple-questions"
    );
    if (multipleQuestionIndex !== -1) {
      _form_template[getProceed + 1].questions[
        multipleQuestionIndex
      ].multipleQuestion.splice(k, 1);
    }
    setForm_template(_form_template);
  };

  const dailytaskQAdd = (e) => {
    let _form_template = _.cloneDeep(form_template);
    const dropdownQuestionIndex = _form_template[
      getProceed
    ].questions.findIndex(
      (question) => question.questionType === "Description+Dropdown+Rearrange"
    );
    if (dropdownQuestionIndex !== -1) {
      _form_template[getProceed].questions[dropdownQuestionIndex].options.push(
        ""
      );
    }
    let _multipleQuestionClone = _.cloneDeep(
      _form_template[getProceed + 1].questions[0].multipleQuestion[0]
    );
    _multipleQuestionClone.questions.forEach((question) => {
      if (question.description) {
        question.description = "";
      }
    });

    _form_template[getProceed + 1].questions[0].multipleQuestion.push(
      _multipleQuestionClone
    );
    setForm_template(_form_template);
  };

  useEffect(() => {
    if (getState1) {
      let _form_template = _.cloneDeep(form_template);
      const QuestionID = _form_template[getProceed].questions.findIndex(
        (item) => item.questionType === "Table+Dropdown+Multi-select"
      );
      let _getHCMOptions = getHCMOptions.map((obj) => obj.value);
      for (var i = 0; i < getHCMOptions.length; i++) {
        let _value = getSelectedOptionsMulti1[getHCMOptions[i]["_s_key"]].length
          ? getSelectedOptionsMulti1[getHCMOptions[i]["_s_key"]].map(
              (obj) => obj.value
            )
          : [];

        _form_template[getProceed].questions[QuestionID].table_options[
          getHCMOptions[i]["_s_key"]
        ].selected_options = _value;
        _form_template[getProceed].questions[QuestionID].table_options[
          getHCMOptions[i]["_s_key"]
        ].pre_selected_options = _value;
        _form_template[getProceed].questions[QuestionID].table_options[
          getHCMOptions[i]["_s_key"]
        ].comment_text = getCommentMulti1[getHCMOptions[i]["_s_key"]];
      }

      if (getHCMOptions.length !== 0) {
        for (
          let j = 0;
          j <
          _form_template[getProceed].questions[QuestionID].table_options.length;
          j++
        ) {
          if (
            _getHCMOptions.includes(
              _form_template[getProceed].questions[QuestionID].table_options[j]
                .question
            )
          ) {
            _form_template[getProceed].questions[QuestionID].table_options[
              j
            ].common = true;
          } else {
            _form_template[getProceed].questions[QuestionID].table_options[
              j
            ].common = false;
          }
        }
      } else {
        for (
          let j = 0;
          j <
          _form_template[getProceed].questions[QuestionID].table_options.length;
          j++
        ) {
          _form_template[getProceed].questions[QuestionID].table_options[
            j
          ].common = false;
        }
      }

      setForm_template(_form_template);
    }
  }, [getHCMOptions, getCommentMulti1, getSelectedOptionsMulti]);

  useEffect(() => {
    if (getState12) {
      let _form_template = _.cloneDeep(form_template);
      const QuestionID = _form_template[getProceed].questions.findIndex(
        (item) =>
          item.questionType === "Table+Dropdown+Multi-select" &&
          item.hasOwnProperty("dependant_question")
      );
      const commonIndexRequired = _form_template[getProceed].questions[
        QuestionID
      ].table_options.findIndex((option) => option.common === true);
      for (var i = 0; i < getHCMOptions12.length; i++) {
        let _value = getSelectedOptionsMulti1[getHCMOptions12[i]["_s_key"]]
          .length
          ? getSelectedOptionsMulti1[getHCMOptions12[i]["_s_key"]].map(
              (obj) => obj.value
            )
          : [];

        _form_template[getProceed].questions[QuestionID].table_options[
          commonIndexRequired
        ].selected_options[getHCMOptions12[i]["_s_key"]] = _value;

        _form_template[getProceed].questions[QuestionID].table_options[
          commonIndexRequired
        ].pre_selected_options[getHCMOptions12[i]["_s_key"]] = _value;
      }

      setForm_template(_form_template);
    }
  }, [getHCMOptions12, getHCMOptions1, getSelectedOptionsMulti1]);

  useEffect(() => {
    if (getState12) {
      let _form_template = _.cloneDeep(form_template);
      let _getHCMOptions = getHCMOptions1.map((obj) => obj.value);
      const QuestionID = _form_template[getProceed].questions.findIndex(
        (item) =>
          item.questionType === "Table+Dropdown+Multi-select" &&
          item.hasOwnProperty("dependant_question")
      );
      const commonIndex = _form_template[getProceed].questions[
        QuestionID
      ].table_options.findIndex((option) => option.common === true);

      for (var i = 0; i < getHCMOptions1.length; i++) {
        let _value = getSelectedOptionsMulti12[getHCMOptions1[i]["_s_key"]]
          .length
          ? getSelectedOptionsMulti12[getHCMOptions1[i]["_s_key"]].map(
              (obj) => obj.value
            )
          : [];

        _form_template[getProceed].questions[QuestionID].table_options[
          commonIndex
        ].mitigation_selected_options[getHCMOptions1[i]["_s_key"]] = _value;

        _form_template[getProceed].questions[QuestionID].table_options[
          commonIndex
        ].mitigation_pre_selected_options[getHCMOptions1[i]["_s_key"]] = _value;
      }

      setForm_template(_form_template);
    }
  }, [getSelectedOptionsMulti12]);

  useEffect(() => {
    if (getState1) {
      let _getSelectedOptionsMulti = _.cloneDeep(getSelectedOptionsMulti);
      let _getHCMOptions = _.cloneDeep(getHCMOptions);
      let _getCommentMulti1 = _.cloneDeep(getCommentMulti1);
      const QuestionID = form_template[getProceed].questions.findIndex(
        (item) => item.questionType === "Table+Dropdown+Multi-select"
      );
      form_template[getProceed].questions[QuestionID].table_options.map(
        (obj, i) => {
          if (obj.pre_selected_options.length) {
            let _optionsArr = obj.options.map((val) => {
              return { value: val, label: val, color: "#00B8D9" };
            });
            _getHCMOptions.push({
              value: obj.question,
              label: obj.question,
              color: "#00B8D9",
              isFixed: false,
              _options: _optionsArr,
              _s_key: i,
            });

            obj.selected_options.map((val, key) => {
              _getSelectedOptionsMulti[i].push({
                value: val,
                label: val,
                color: "#00B8D9",
              });
            });
            _getCommentMulti1[i] = obj.comment_text;
          }
        }
      );

      setHCMOptions(_getHCMOptions);
      setSelectedOptionsMulti(_getSelectedOptionsMulti);
      setCommentMulti1(_getCommentMulti1);
    }
  }, [getState1]);

  useEffect(() => {
    if (getState12) {
      let _getSelectedOptionsMulti1 = _.cloneDeep(getSelectedOptionsMulti1);
      let _getHCMOptions12 = _.cloneDeep(getHCMOptions1);
      const QuestionID = form_template[getProceed].questions.findIndex(
        (item) =>
          item.questionType === "Table+Dropdown+Multi-select" &&
          item.hasOwnProperty("dependant_question")
      );
      form_template[getProceed].questions[QuestionID].table_options.map(
        (obj, i) => {
          if (obj.pre_selected_options.length) {
            let _optionsArr = obj.options.map((val, indexOne) => {
              const key = Object.keys(val)[0];
              return {
                value: key,
                label: key,
                color: "#00B8D9",
                _mitigationsoptions: obj.options,
                _s_key: indexOne,
              };
            });
            _getHCMOptions12.push({
              value: obj.question,
              label: obj.question,
              color: "#00B8D9",
              isFixed: false,
              _options: _optionsArr,
              _mitigationsoptions: obj.options,
              _s_key: i,
            });

            obj.selected_options.forEach((val, key) => {
              const NewData = _optionsArr;

              if (Array.isArray(val) && val.length > 0) {
                let formattedOptions = val.map((val1, index) => {
                  const selectedHazard = NewData.find(
                    (option) => option.value === val1
                  );
                  const _s_key = selectedHazard ? selectedHazard._s_key : 0;
                  return {
                    value: val1,
                    label: val1,
                    color: "#00B8D9",
                    _mitigationsoptions: obj.options,
                    _s_key: _s_key,
                  };
                });

                _getSelectedOptionsMulti1[i] = formattedOptions;
              }
            });
            const totalIndexes = 10;
            const updatedValues = Array.from(
              { length: totalIndexes },
              (_, index) => {
                return obj.mitigation_selected_options[index]
                  ? obj.mitigation_selected_options[index].map(
                      (option, indexnew) => ({
                        label: option,
                        value: option,
                        _s_key: indexnew,
                      })
                    )
                  : [];
              }
            );
            setSelectedOptionsMulti12(updatedValues);
          }
          const filledArrays = _getSelectedOptionsMulti1.flatMap((array) =>
            array.map((hazard, index) => ({
              ...hazard,
            }))
          );
          setHCMOptions1(filledArrays);
        }
      );
      setHCMOptions12(_getHCMOptions12);
      setSelectedOptionsMulti1(_getSelectedOptionsMulti1);
    }
  }, [getState12]);

  const HMCAllMultiSelect1 = getHCMOptions1.map((obj, k) => {
    const selectedHazardObj =
      obj._mitigationsoptions.find(
        (option) => Object.keys(option)[0] === obj.value
      ) || {};
    const selectedMitigations = Object.values(selectedHazardObj)[0] || [];
    const transformedMitigations = selectedMitigations.map(
      (mitigation, index) => ({
        label: mitigation,
        value: mitigation,
        _s_key: index,
      })
    );

    return (
      <Form.Group
        controlId={`form-7-1- ${obj._s_key} `}
        key={k}
        className={
          /^(Other|Other - )/.test(obj.value)
            ? "mb-3 shadow-lg p-3 mb-3 rounded otherHazards"
            : "mb-3 shadow-lg p-3 mb-3 rounded"
        }
      >
        <label
          className={
            /^(Other|Other - )/.test(obj.value)
              ? "customeOther form-label"
              : "form-label"
          }
        >
          <span
            className={/^(Other|Other - )/.test(obj.value) ? "edit" : "d-none"}
            id={"edit12" + k}
            onClick={() => customeOtherLabelMulti1(k)}
          >
            &#9998;
          </span>
          {/^(Other|Other - )/.test(obj.value) ? (
            <>
              {Object.keys(obj.value).length !== 0 && (
                <>
                  <span>{obj.value.split("-")[0]} &nbsp;-&nbsp;</span>
                  <input
                    type="text"
                    className="HazardLabel"
                    id={"customeOtheredit12" + k}
                    s_key={obj._s_key}
                    name={obj.value}
                    onChange={(e) => editLabelMulti1(e, k, obj._s_key)}
                    value={obj.value.split("-")[1]}
                    disabled
                  />
                  <span
                    className="check d-none"
                    id={"check12" + k}
                    onClick={() => customeOtherLabelMulti1(k)}
                  >
                    &#10004;
                  </span>
                  <span
                    className="close d-none"
                    id={"close12" + k}
                    onClick={() => customeOtherLabelMulti1(k)}
                  >
                    x
                  </span>
                </>
              )}
            </>
          ) : (
            obj.value
          )}
        </label>

        <MultiSelect1forCrockets1
          options={transformedMitigations}
          getOption={getSelectedOptionsMulti12}
          setOption={setSelectedOptionsMulti12}
          _s_key={obj._s_key}
        />
      </Form.Group>
    );
  });
  const HMCAllMultiSelect12 = getHCMOptions12.map((obj, k) => {
    return (
      <div class="Hazards" key={obj._s_key} id={k}>
        <Form.Group
          controlId={`form-7-1- ${obj._s_key} `}
          key={k}
          className={
            /^(Other|Other - )/.test(obj.value)
              ? "mb-3 shadow-lg p-3 mb-3 rounded otherHazards"
              : "mb-3 shadow-lg p-3 mb-3 rounded"
          }
        >
          <label
            className={
              /^(Other|Other - )/.test(obj.value)
                ? "customeOther form-label"
                : "form-label"
            }
          >
            <span
              className={
                /^(Other|Other - )/.test(obj.value) ? "edit" : "d-none"
              }
              id={"edit" + k}
              onClick={() => customeOtherLabelMulti(k)}
            >
              &#9998;
            </span>

            {/^(Other|Other - )/.test(obj.value) ? (
              <>
                {Object.keys(obj.value).length !== 0 && (
                  <>
                    <span>{obj.value.split("-")[0]} &nbsp;-&nbsp;</span>
                    <input
                      type="text"
                      className="HazardLabel"
                      id={"customeOtheredit1" + k}
                      s_key={obj._s_key}
                      name={obj.value}
                      onChange={(e) => editLabelMulti(e)}
                      value={obj.value.split("-")[1]}
                      disabled
                    />
                    <span
                      className="check d-none"
                      id={"check" + k}
                      onClick={() => customeOtherLabelMulti(k)}
                    >
                      &#10004;
                    </span>
                    <span
                      className="close d-none"
                      id={"close" + k}
                      onClick={() => customeOtherLabelMulti(k)}
                    >
                      x
                    </span>
                  </>
                )}
              </>
            ) : (
              obj.value
            )}
          </label>
          <MultiSelect1forCrockets
            options={obj._options}
            getOption={getSelectedOptionsMulti1}
            setOption={setSelectedOptionsMulti1}
            setHCMOptions1={setHCMOptions1}
            getHCMOptions1={getHCMOptions1}
            _s_key={obj._s_key}
          />
          {HMCAllMultiSelect1}
        </Form.Group>
      </div>
    );
  });
  const HMCAllMultiSelect = getHCMOptions.map((obj, k) => {
    return (
      <div className="Hazards" key={k}>
        <Form.Group
          controlId="form-7-1"
          key={k}
          className={
            obj._s_key === 9 || "other" in obj
              ? "mb-3 shadow-lg p-3 mb-3 rounded otherHazards"
              : "mb-3 shadow-lg p-3 mb-3 rounded"
          }
        >
          <label
            className={
              obj._s_key === 9 || "other" in obj
                ? "customeOther form-label"
                : "form-label"
            }
          >
            <span
              className={obj._s_key === 9 || "other" in obj ? "edit" : "d-none"}
              id={"edit" + k}
              onClick={() => customeOtherLabelEdit(k)}
            >
              &#9998;
            </span>
            {obj._s_key === 9 || "other" in obj ? (
              <>
                {Object.keys(obj.value).length !== 0 && (
                  <>
                    <span>{obj.value.split("-")[0]} &nbsp;-&nbsp;</span>
                    <input
                      type="text"
                      className="HazardLabel"
                      id={"customeOtheredit" + k}
                      s_key={obj._s_key}
                      name={obj.value}
                      onChange={(e) => editLabel(e)}
                      value={obj.value.split("-")[1]}
                      disabled
                    />
                    <span
                      className="check d-none"
                      id={"check" + k}
                      onClick={() => customeOtherLabelEdit(k)}
                    >
                      &#10004;
                    </span>
                    <span
                      className="close d-none"
                      id={"close" + k}
                      onClick={() => customeOtherLabelEdit(k)}
                    >
                      x
                    </span>
                  </>
                )}
              </>
            ) : (
              obj.value
            )}
          </label>
          <MultiSelect1
            options={obj._options}
            getOption={getSelectedOptionsMulti}
            setOption={setSelectedOptionsMulti}
            _s_key={obj._s_key}
            getCommentMulti1={getCommentMulti1}
            setCommentMulti1={setCommentMulti1}
          />
        </Form.Group>
      </div>
    );
  });

  const addHazards = (k) => {
    let _form_template = _.cloneDeep(form_template);
    let _getSelectedOptionsMulti = _.cloneDeep(getSelectedOptionsMulti);
    let _getHCMOptions = _.cloneDeep(getHCMOptions);
    const QuestionID = form_template[getProceed].questions.findIndex(
      (item) => item.questionType === "Table+Dropdown+Multi-select"
    );
    let newOther;
    let _optionsArr;
    for (
      var i = 0;
      i < _form_template[getProceed].questions[QuestionID].table_options.length;
      i++
    ) {
      if (
        getIjhaform.form_template[getProceed].questions[QuestionID]
          .table_options.length > i
      ) {
        if (
          getIjhaform.form_template[getProceed].questions[QuestionID]
            .table_options[i].question === "Other"
        ) {
          newOther = _.cloneDeep(
            getIjhaform.form_template[getProceed].questions[QuestionID]
              .table_options[i]
          );
          newOther.question = "Other -";
          _optionsArr = newOther.options.map((val) => {
            return { value: val, label: val, color: "#00B8D9" };
          });
        }
      }
    }
    _form_template[getProceed].questions[QuestionID].table_options.push(
      newOther
    );
    _getSelectedOptionsMulti.push([]);

    setForm_template(_form_template);
    _getHCMOptions.push({
      value: newOther.question,
      label: newOther.question,
      color: "#00B8D9",
      isFixed: false,
      other: true,
      _options: _optionsArr,
      _s_key:
        _form_template[getProceed].questions[QuestionID].table_options.length -
        1,
    });
    newOther.selected_options.map((val, key) => {
      _getSelectedOptionsMulti[key].push({
        value: val,
        label: val,
        color: "#00B8D9",
      });
    });
    setHCMOptions(_getHCMOptions);
    setSelectedOptionsMulti(_getSelectedOptionsMulti);
  };

  const customeOtherLabelMulti1 = (k) => {
    let _edit = document.getElementById("edit12" + k);
    let _check = document.getElementById("check12" + k);
    let _close = document.getElementById("close12" + k);
    let ele = document.getElementById("customeOtheredit12" + k);
    if (ele.classList.contains("activeLabel")) {
      ele.disabled = true;
      ele.classList.remove("activeLabel");
      _edit.classList.remove("d-none");
      _check.classList.add("d-none");
      _close.classList.add("d-none");

      let _getHCMOptions1 = _.cloneDeep(getHCMOptions1);

      _getHCMOptions1.map((i, k) => {
        if (i.value === ele.name) {
          i.value = "Other - " + ele.value.trimStart();
          i.label = "Other - " + ele.value.trimStart();
        }
      });
      setHCMOptions1(_getHCMOptions1);
      ele.name = "Other -" + ele.value;
    } else {
      ele.disabled = false;
      ele.classList.add("activeLabel");
      _edit.classList.add("d-none");
      _check.classList.remove("d-none");
      _close.classList.remove("d-none");
    }
  };

  const editLabelMulti1 = (e, k, index) => {
    if (e.target.value.length >= 1) {
      let _getHCMOptions1 = _.cloneDeep(getHCMOptions1);
      let _getHCMOptions12 = _.cloneDeep(getHCMOptions12);
      let _form_template = _.cloneDeep(form_template);
      setSelectedOptionsMulti1((prevOptions) => {
        return prevOptions.map((optionsArray, arrayIndex) => {
          return optionsArray.map((option, optionIndex) => {
            if (optionIndex === k) {
              return {
                ...option,
                value: "Other - " + e.target.value.trimStart(),
                label: "Other - " + e.target.value.trimStart(),
              };
            }
            return option;
          });
        });
      });
      _getHCMOptions12[0]._options.map((i, k) => {
        if (i._s_key === parseInt(e.target.attributes.s_key.value)) {
          i.value = "Other - " + e.target.value.trimStart();
          i.label = "Other - " + e.target.value.trimStart();
        }
      });
      setHCMOptions12(_getHCMOptions12);
      const mitigationOptionsArray = _getHCMOptions1[0]._mitigationsoptions;
      if (
        Array.isArray(mitigationOptionsArray) &&
        mitigationOptionsArray.length > 0
      ) {
        mitigationOptionsArray[index] = Object.entries(
          mitigationOptionsArray[index]
        ).reduce((acc, [key, value]) => {
          const updatedKey = "Other - " + e.target.value.trimStart();
          acc[updatedKey] = value;
          return acc;
        }, {});
      }
      const QuestionID = form_template[getProceed].questions.findIndex(
        (item) => item.questionType === "Table+Dropdown+Multi-select"
      );
      const commonIndexRequired = _form_template[getProceed].questions[
        QuestionID
      ].table_options.findIndex((option) => option.common === true);
      _form_template[getProceed].questions[QuestionID].table_options[
        commonIndexRequired
      ].options = _getHCMOptions1[0]._mitigationsoptions;

      setForm_template(_form_template);
      setHCMOptions1(_getHCMOptions1);
    }
  };

  const customeOtherLabelMulti = (k) => {
    let _edit = document.getElementById("edit" + k);
    let _check = document.getElementById("check" + k);
    let _close = document.getElementById("close" + k);
    let ele = document.getElementById("customeOtheredit1" + k);
    if (ele.classList.contains("activeLabel")) {
      ele.disabled = true;
      ele.classList.remove("activeLabel");
      _edit.classList.remove("d-none");
      _check.classList.add("d-none");
      _close.classList.add("d-none");
      let _form_template = _.cloneDeep(form_template);
      const QuestionID = form_template[getProceed].questions.findIndex(
        (item) =>
          item.questionType === "Table+Dropdown+Multi-select" &&
          item.hasOwnProperty("dependant_question")
      );
      _form_template[getProceed].questions[QuestionID].table_options.map(
        (obj, k) => {
          if (obj.question === ele.name) {
            _form_template[getProceed].questions[QuestionID].table_options[
              k
            ].question = "Other - " + ele.value.trimStart();
          }
        }
      );
      setForm_template(_form_template);
      let _getHCMOptions12 = _.cloneDeep(getHCMOptions12);
      _getHCMOptions12.map((i, k) => {
        if (i.value === ele.name) {
          i.value = "Other - " + ele.value.trimStart();
          i.label = "Other - " + ele.value.trimStart();
        }
      });

      setHCMOptions12(_getHCMOptions12);
      ele.name = "Other -" + ele.value;
    } else {
      ele.disabled = false;
      ele.classList.add("activeLabel");
      _edit.classList.add("d-none");
      _check.classList.remove("d-none");
      _close.classList.remove("d-none");
    }
  };

  const editLabelMulti = (e) => {
    let _form_template = _.cloneDeep(form_template);
    const QuestionID = form_template[getProceed].questions.findIndex(
      (item) =>
        item.questionType === "Table+Dropdown+Multi-select" &&
        item.hasOwnProperty("dependant_question")
    );
    _form_template[getProceed].questions[QuestionID].table_options.map(
      (obj, k) => {
        if (k === parseInt(e.target.attributes.s_key.value)) {
          _form_template[getProceed].questions[QuestionID].table_options[
            k
          ].question = "Other - " + e.target.value.trimStart();
        }
      }
    );
    setForm_template(_form_template);
    if (e.target.value.length >= 1) {
      let _getHCMOptions12 = _.cloneDeep(getHCMOptions12);
      _getHCMOptions12.map((i, k) => {
        if (i._s_key === parseInt(e.target.attributes.s_key.value)) {
          i.value = "Other - " + e.target.value.trimStart();
          i.label = "Other - " + e.target.value.trimStart();
        }
      });
      setHCMOptions12(_getHCMOptions12);
    }
  };

  const customeOtherLabelEdit = (k) => {
    const QuestionID = form_template[getProceed].questions.findIndex(
      (item) => item.questionType === "Table+Dropdown+Multi-select"
    );
    let _edit = document.getElementById("edit" + k);
    let _check = document.getElementById("check" + k);
    let _close = document.getElementById("close" + k);
    let ele = document.getElementById("customeOtheredit" + k);
    if (ele.classList.contains("activeLabel")) {
      ele.disabled = true;
      ele.classList.remove("activeLabel");
      _edit.classList.remove("d-none");
      _check.classList.add("d-none");
      _close.classList.add("d-none");

      let _form_template = _.cloneDeep(form_template);
      _form_template[getProceed].questions[QuestionID].table_options.map(
        (obj, k) => {
          if (obj.question === ele.name) {
            _form_template[getProceed].questions[QuestionID].table_options[
              k
            ].question = "Other - " + ele.value.trimStart();
          }
        }
      );
      setForm_template(_form_template);
      let _getHCMOptions = _.cloneDeep(getHCMOptions);
      _getHCMOptions.map((i, k) => {
        if (i.value === ele.name) {
          i.value = "Other - " + ele.value.trimStart();
          i.label = "Other - " + ele.value.trimStart();
        }
      });
      setHCMOptions(_getHCMOptions);
      ele.name = "Other -" + ele.value;
    } else {
      ele.disabled = false;
      ele.classList.add("activeLabel");
      _edit.classList.add("d-none");
      _check.classList.remove("d-none");
      _close.classList.remove("d-none");
    }
  };

  const editLabel = (e) => {
    if (e.target.value.length >= 1) {
      let _form_template = _.cloneDeep(form_template);
      const QuestionID = form_template[getProceed].questions.findIndex(
        (item) => item.questionType === "Table+Dropdown+Multi-select"
      );
      _form_template[getProceed].questions[QuestionID].table_options.map(
        (obj, k) => {
          if (k === parseInt(e.target.attributes.s_key.value)) {
            _form_template[getProceed].questions[QuestionID].table_options[
              k
            ].question = "Other - " + e.target.value.trimStart();
          }
        }
      );
      setForm_template(_form_template);

      let _getHCMOptions = _.cloneDeep(getHCMOptions);
      _getHCMOptions.map((i, k) => {
        if (i._s_key === parseInt(e.target.attributes.s_key.value)) {
          i.value = "Other - " + e.target.value.trimStart();
          i.label = "Other - " + e.target.value.trimStart();
        }
      });
      setHCMOptions(_getHCMOptions);
    }
  };

  const getUploadImageUrl = (fileObj) => {
    const sendDataImg = {
      files: [fileObj],
      emailId: DECRYPTED_DATA().emailId,
      reportId: getReportID,
      upload_type: "JHA",
      section_names: sectionName,
      employeeId: EMP_ID(),
      setLoading: setLoading,
      setSetP: setSetP,
    };
    uploadMultiImage(setUploadedImgUrls1, sendDataImg, setImgUrls);
  };

  const aiALLDetectJson = async (pic_url, valueCategory) => {
    const ai_all = await detectAIALL(pic_url, setLoading, setErrMsg);
    let message = "";
    let _form_template = _.cloneDeep(form_template);
    let filteredCategories = _form_template[getProceed].section[
      getCrewLead
    ].questions.filter((question) => {
      return (
        question.questionType === "Radio+Image" && question.methodType === "AI"
      );
    });
    let categoriesmedd = filteredCategories.map(
      (question) => question.category
    );
    let _ai_all = categoriesmedd;
    Object.keys(ai_all.data).filter((obj_ai) => obj_ai !== "fire-ext");
    _form_template[getProceed].section[getCrewLead].questions = _form_template[
      getProceed
    ].section[getCrewLead].questions.map((obj, i) => {
      _ai_all.map((obj_ai) => {
        if (obj.category === obj_ai) {
          if (ai_all.data[obj_ai]) {
            obj.answer = ai_all.data[obj_ai].status;
            obj.confidence = ai_all.data[obj_ai].confidence;
            obj.threshold = ai_all.data[obj_ai].threshold;
            obj.ai_approved = ai_all.data[obj_ai].ai_approved;
            obj.image_metadata = getImgMetaData;
            obj.CheckSumValueOfImage = getChecksumValue;
            obj.checkSumVerified = getVerifiedValue;
            obj.picture = pic_url;
          } else {
            if (ai_all.data["other"]) {
              const otherCategory = ai_all.data["other"].find(
                (item) => item.category === obj.category
              );
              if (otherCategory) {
                obj.answer = otherCategory.status;
                obj.confidence = otherCategory.confidence;
                obj.threshold = otherCategory.threshold;
                obj.ai_approved = otherCategory.ai_approved;
                obj.image_metadata = getImgMetaData;
                obj.CheckSumValueOfImage = getChecksumValue;
                obj.checkSumVerified = getVerifiedValue;
                obj.picture = pic_url;
              } else {
                obj.answer = "";
                obj.confidence = "";
                obj.threshold = "";
                obj.picture = "";
                obj.CheckSumValueOfImage = "";
                obj.checkSumVerified = "";
                obj.ai_approved = "";
              }
            } else {
              obj.answer = "";
              obj.confidence = "";
              obj.threshold = "";
              obj.picture = "";
              obj.CheckSumValueOfImage = "";
              obj.checkSumVerified = "";
              obj.ai_approved = "";
            }
          }
        }
      });
      return obj;
    });
    setForm_template(_form_template);
  };
  const getImageDateTime = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const exif = EXIF.readFromBinaryFile(data);
        if (exif && exif.DateTimeOriginal) {
          resolve(exif.DateTimeOriginal);
        } else {
          resolve("");
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };
  const convertExiftoMinutes = (exifDateTime) => {
    if (exifDateTime === "") {
      return "";
    }

    const [date, time] = exifDateTime.split(" ");
    const [year, month, day] = date.split(":");
    const [hour, minute, second] = time.split(":");
    const formattedDateTime = `${year}-${month}-${day}T${hour}:${minute}:${second}`;
    return new Date(formattedDateTime);
  };

  const CheckImageMetaData = async (_value, _key, _arr_key, fieldType) => {
    const file = _value[0];

    try {
      const dateTime = await getImageDateTime(file);
      const formattedValue = convertExiftoMinutes(dateTime);
      const currentDate = new Date()
        .toISOString()
        .substring(0, 19)
        .replace("T", " ");
      const timeDiff =
        formattedValue instanceof Date
          ? Math.abs(formattedValue.getTime() - new Date(currentDate).getTime())
          : "";
      const hoursOld =
        formattedValue === "" ? "" : Math.floor(timeDiff / (1000 * 60 * 60));

      if (fieldType === "fire-ext") {
        let _form_template = _.cloneDeep(form_template);

        let Timeconversion = hoursOld;
        let message = "";
        if (Timeconversion >= 12) {
          message =
            "The image is older than 12 hours. Please upload the image captured in last 1 hour only.";
          setError(fieldType, {
            message,
          });
          setImgMetaData(message);
        } else if (Timeconversion === "") {
          message =
            "Unable to identify date/time the photograph was taken on. If this photograph was not taken within the last 1 hour you must replace it with a recent photograph.";
          setError(fieldType, {
            message,
          });
          setImgMetaData(message);
        } else {
          message = "Time Verified";
          setError(fieldType, {
            message1: message,
          });
          setImgMetaData(message);
        }

        const indexToEdit = _form_template[getProceed].questions.findIndex(
          (question) => question.questionId === _value[2]
        );
        _form_template[getProceed].questions[indexToEdit] = {
          ..._form_template[getProceed].questions[indexToEdit],
          image_metadata: message,
          checkSumVerified: getVerifiedValue,
        };
        setFieldType(message);
        setForm_template(_form_template);
      } else if (fieldType === "hard-hat" && _key.detectallppe === "Yes") {
        let Timeconversion = hoursOld;
        let message = "";

        if (Timeconversion >= 12) {
          message =
            "The image is older than 12 hours. Please upload the image captured in last 1 hour only.";
          setError(fieldType, {
            message,
          });
          setImgMetaData(message);
        } else if (Timeconversion === "") {
          message =
            "Unable to identify date/time the photograph was taken on. If this photograph was not taken within the last 1 hour you must replace it with a recent photograph.";
          setError(fieldType, {
            message,
          });
          setImgMetaData(message);
        } else {
          message = "Time Verified";
          setError(fieldType, {
            message1: message,
          });
          setImgMetaData(message);
        }
        setFieldType(message);
      } else {
        let _form_template = _.cloneDeep(form_template);
        let Timeconversion = hoursOld;
        let message = "";
        if (Timeconversion >= 12) {
          message =
            "The image is older than 12 hours. Please upload the image captured in last 1 hour only.";
          setError(fieldType, {
            message,
          });
          setImgMetaData(message);
        } else if (Timeconversion === "") {
          message =
            "Unable to identify date/time the photograph was taken on. If this photograph was not taken within the last 1 hour you must replace it with a recent photograph.";
          setError(fieldType, {
            message,
          });
          setImgMetaData(message);
        } else {
          message = "Time Verified";
          setError(fieldType, {
            message1: message,
          });
          setImgMetaData(message);
        }
        const section = _form_template[getProceed].section;
        const indexToEdit = section
          ? section[getCrewLead].questions.findIndex(
              (question) => question.questionId === _value[2]
            )
          : _form_template[getProceed].questions.findIndex(
              (question) => question.questionId === _value[2]
            );

        const targetArray = section
          ? section[getCrewLead].questions
          : _form_template[getProceed].questions;

        targetArray[indexToEdit] = {
          ...targetArray[indexToEdit],
          image_metadata: message,
          checkSumVerified: getVerifiedValue,
        };

        setFieldType(message);
        setForm_template(_form_template);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getUploadSingleImage = async (
    _value,
    _key,
    _arr_key,
    fieldType,
    isAiImage = false,
    sectionsToUpdate
  ) => {
    let _getImgUrls = _.cloneDeep(getImgUrls);

    const sendDataImg = {
      files: [_value[0]],
      emailId: DECRYPTED_DATA().emailId,
      reportId: getReportID,
      upload_type: "JHA",
      section_names: sectionName,
      employeeId: EMP_ID(),
      setLoading: setLoading,
      setSetP: setSetP,
    };

    if (isAiImage) {
      const response = await checkImageData(
        {
          doc_file: _value[0],
        },
        setImgMetaData
      );

      if (fieldType === "fire-ext") {
        let _form_template = _.cloneDeep(form_template);

        let Timeconversion = parseInt(response.time_diff);
        let message = "";
        if (Timeconversion >= 720) {
          message =
            "The image is older than 12 hours. Please upload the image captured in last 1 hour only.";
          setError(fieldType, {
            message,
          });
        } else if (response.time_diff === "Time not get extracted") {
          message =
            "Unable to identify date/time the photograph was taken on. If this photograph was not taken within the last 1 hour you must replace it with a recent photograph.";
          setError(fieldType, {
            message,
          });
        } else {
          message = "Time Verified";
          setError(fieldType, {
            message1: message,
          });
        }

        const indexToEdit = _form_template[getProceed].questions.findIndex(
          (question) => question.questionId === _value[2]
        );
        _form_template[getProceed].questions[indexToEdit] = {
          ..._form_template[getProceed].questions[indexToEdit],
          image_metadata: message,
          checkSumVerified: getVerifiedValue,
        };
        setFieldType(message);
        setForm_template(_form_template);
      } else if (fieldType === "hard-hat" && _key.detectallppe === "Yes") {
        let Timeconversion = parseInt(response.time_diff);
        let message = "";

        if (Timeconversion >= 720) {
          message =
            "The image is older than 12 hours. Please upload the image captured in last 1 hour only.";
          setError(fieldType, {
            message,
          });
        } else if (response.time_diff === "Time not get extracted") {
          message =
            "Unable to identify date/time the photograph was taken on. If this photograph was not taken within the last 1 hour you must replace it with a recent photograph.";
          setError(fieldType, {
            message,
          });
        } else {
          message = "Time Verified";
          setError(fieldType, {
            message1: message,
          });
        }
        setFieldType(message);
      } else {
        let _form_template = _.cloneDeep(form_template);

        let Timeconversion = parseInt(response.time_diff);
        let message = "";
        if (Timeconversion >= 720) {
          message =
            "The image is older than 12 hours. Please upload the image captured in last 1 hour only.";
          setError(fieldType, {
            message,
          });
        } else if (response.time_diff === "Time not get extracted") {
          message =
            "Unable to identify date/time the photograph was taken on. If this photograph was not taken within the last 1 hour you must replace it with a recent photograph.";
          setError(fieldType, {
            message,
          });
        } else {
          message = "Time Verified";
          setError(fieldType, {
            message1: message,
          });
        }
        const questionstoUpdate =
          _form_template[getProceed].questions ||
          _form_template[getProceed].section[getCrewLead].questions;

        const indexToEdit = questionstoUpdate.findIndex(
          (question) => question.questionId === _value[2]
        );
        questionstoUpdate[indexToEdit] = {
          ...questionstoUpdate[indexToEdit],
          image_metadata: message,
          checkSumVerified: getVerifiedValue,
        };
        setFieldType(message);
        setForm_template(_form_template);
      }
    }
    await uploadMultiImage(setUploadedImgUrls12, sendDataImg, setImgUrls);
    _value[0] !== "" &&
      setImgAiDetectKeys({
        section: _value[1],
        question12: _value[4],
        questions: _value[2],
        ai: _value[3],
        index: _key,
      });
  };

  function getSecondPart(str) {
    return str.split("-")[1];
  }
  function getFirstPart(str) {
    return str.split("-")[0];
  }

  const weatherIcon = (iconPhrase) => {
    if (iconPhrase !== null && iconPhrase === "clear") return ic_wind;
    else if (iconPhrase !== null && iconPhrase === "sunny") return ic_sunny;
    else if (
      (iconPhrase !== null && iconPhrase === "Mostly cloudy") ||
      iconPhrase === "Partly cloudy" ||
      iconPhrase === "Mostly sunny" ||
      iconPhrase === "Mostly clear"
    )
      return ic_partly_sunny;
    else if (iconPhrase !== null && iconPhrase === "rain") return ic_rain;
    else return ic_partly_sunny;
  };

  useEffect(() => {
    getSignBase64Uri !== "" && getSignUrl();
  }, [getSignUrl]);
  useEffect(() => {
    getUploadedImgUrls.length &&
      onChangeFormTemplate({
        sectionId: sectionId,
        signature: getUploadedImgUrls[0],
        questionId: questions_obj.questionId,
      });
  }, [getUploadedImgUrls]);
  useEffect(() => {
    getUploadedImgUrls1.length &&
      onChangeFormTemplate({
        sectionId: sectionId,
        multiple_picture: getUploadedImgUrls1[0],
        questionId: questions_obj.questionId,
      });
  }, [getUploadedImgUrls1]);
  useEffect(() => {
    getUploadedImgUrls12.length &&
      onChangeFormTemplate({
        sectionId: sectionId,
        picture: getUploadedImgUrls12[0],
        questionId: questions_obj.questionId,
      });
  }, [getUploadedImgUrls12]);
  const getDateObject = (strDate) => {
    let dateArr = strDate.split("/");
    return new Date(dateArr[2], dateArr[1] - 1, dateArr[0]);
  };
  const MyContainer = ({ className, children }) => {
    return (
      <div>
        <div className={className}>
          <div style={{ position: "relative" }}>{children}</div>
        </div>
      </div>
    );
  };

  const DescriptionQuestionDel = (k, value1) => {
    let _form_template = _.cloneDeep(form_template);
    const pageIndex = form_template.findIndex(
      (item) => item.sectionId === sectionId
    );
    _form_template[pageIndex].section[getCrewLead].questions[
      value1
    ].options.splice(k, 1);
    setForm_template(_form_template);
  };

  const DescriptionQuestionAdd = (Value, value1) => {
    let _form_template = _.cloneDeep(form_template);
    const pageIndex = form_template.findIndex(
      (item) => item.sectionId === sectionId
    );
    _form_template[pageIndex].section[getCrewLead].questions[
      value1
    ].options.push("");
    setForm_template(_form_template);
  };

  const CheckSumValueOfImageofAll = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const wordArray = CryptoJS.lib.WordArray.create(e.target.result);
      const getChecksumValue = CryptoJS.MD5(wordArray).toString();

      setChecksumValue(getChecksumValue);
      let _form_template = _.cloneDeep(form_template);
      let filteredCategories = _form_template[getProceed].section[
        getCrewLead
      ].questions.filter((question) => {
        return (
          question.questionType === "Radio+Image" &&
          question.methodType === "AI"
        );
      });
      let categoriesmedd = filteredCategories.map(
        (question) => question.category
      );
      let _ai_all = categoriesmedd;

      _form_template[getProceed].section[getCrewLead].questions =
        _form_template[getProceed].section[getCrewLead].questions.map(
          (obj, i) => {
            _ai_all.map((obj_ai) => {
              if (obj.category === obj_ai) {
                if (getChecksumValue) {
                  obj.CheckSumValueOfImage = getChecksumValue;
                } else {
                  obj.CheckSumValueOfImage = "";
                }
              }
            });
            return obj;
          }
        );

      setForm_template(_form_template);
      if (getCrewLeadData.crewmemberdata.length != 0 && getCrewLead > 0) {
        ComparingofValues(_form_template);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const CheckSumValueOfImagePPE = (file, value) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const wordArray = CryptoJS.lib.WordArray.create(e.target.result);
      const getChecksumValue = CryptoJS.MD5(wordArray).toString();

      setChecksumValue(getChecksumValue);

      let _form_template = _.cloneDeep(form_template);
      const questionstoUpdate =
        _form_template[getProceed].questions ||
        _form_template[getProceed].section[getCrewLead].questions;

      const indexToEdit = questionstoUpdate.findIndex(
        (question) => question.questionId === value
      );

      questionstoUpdate[indexToEdit].CheckSumValueOfImage = getChecksumValue;

      setForm_template(_form_template);

      if (getCrewLeadData.crewmemberdata.length != 0 && getCrewLead > 0) {
        ComparingofValues(_form_template);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const ComparingofValues = (Valueoftemplate) => {
    let _form_template = _.cloneDeep(Valueoftemplate);
    let arr = _form_template[getProceed].section.map((section) =>
      section.questions.reduce((acc, question) => {
        return [...acc, question.CheckSumValueOfImage];
      }, [])
    );
    let AreDistictArrays = true;
    for (const [index, arrayToCompare] of arr.entries()) {
      if (index === 0) continue;
      const restArray = arr
        .filter((element, currentIndex) => currentIndex !== index)
        .reduce((acc, element) => [...acc, ...element], []);
      const sectionToEdit = _form_template[getProceed].section[index];
      const curruptDataIndexes = arrayToCompare.reduce(
        (acc, value, dataIndex) => {
          if (value && restArray.includes(value)) {
            const getVerifiedValue = true;
            setVerifiedValue(getVerifiedValue);

            sectionToEdit.questions[dataIndex].checkSumVerified =
              getVerifiedValue;

            AreDistictArrays = false;

            return [...acc, dataIndex];
          } else if (
            "CheckSumValueOfImage" in sectionToEdit.questions[dataIndex]
          ) {
            const getVerifiedValue = false;
            setVerifiedValue(getVerifiedValue);
            sectionToEdit.questions[dataIndex].checkSumVerified =
              getVerifiedValue;
          }

          return acc;
        },
        []
      );

      if (!AreDistictArrays) {
        setError2("Two Images cannot be same. Please upload a new image.");
      } else {
        setError2("");
      }
    }
    setForm_template(_form_template);
  };

  const return_control = useMemo(() => {
    switch (questions_obj.questionType) {
      case "Date":
        return (
          questions_obj.visibility && (
            <div>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              <br />
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}

              <DatePicker
                className="rounded-0 form-control"
                placeholderText="MM/DD/YYYY"
                required={questions_obj.state === "Required" ? true : false}
                selected={!_.isEmpty(questions_obj.answer) ? getDateObject(questions_obj.answer) : null}
                onChange={(date) =>
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: getDataInFormat(date),
                    questionId: questions_obj.questionId,
                  })
                }
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                todayButton="Today"
                calendarContainer={MyContainer}
              />
            </div>
          )
        );
      //////////////////////////////////////////

      //////////////////////////////////////////
      case "Image+Number":
        return (
          questions_obj.visibility && (
            <div
              key={`inline-radio-${questions_obj.questionId}`}
              className="mb-3"
            >
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Control
                className="rounded-0 mt-3"
                type="number"
                value={questions_obj.answer}
                onChange={(e) => {
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");

                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                  });
                }}
              />
              <br />
              {questions_obj.pictureRequired && (
                <Form.Control
                  className="rounded-0 custom-file-input mb-3"
                  type="file"
                  onChange={(e) => {
                    getUploadImageUrl(e.target.files[0]);
                    e.target.value = null;
                  }}
                />
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <div className="mb-3">
                  {"Uploaded Image Count:" +
                    questions_obj.multiple_picture.length}
                </div>
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                  {questions_obj.multiple_picture.map((obj, i) => (
                    <Col key={i} className="item-w m-1  imgDiv">
                      <img
                        src={getUrlWithQueryString(obj) + IMG_TOKEN()}
                        alt={i}
                        className="shadow-sm bg-light rounded img-size_preview"
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          )
        );
      //////////////////////////////////////////

      case "Image":
        return (
          questions_obj.visibility &&
          (questions_obj.detectallppe === "Yes" ? (
            <div>
              <Row>
                <Col>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                    {questions_obj.tooltip_message !== "" &&
                      questions_obj.tooltip_message !== undefined && (
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              {questions_obj.tooltip_message}
                            </Tooltip>
                          }
                        >
                          <span className="icon-info">i</span>
                        </OverlayTrigger>
                      )}
                  </Form.Label>

                  <br />

                  <Form.Control
                    className="rounded-0 custom-file-input"
                    type="file"
                    onChange={async (e) => {
                      // let fileSize = e.target.files[0]['size'];
                      let fileType = e.target.files[0]["type"];
                      let validImageTypes = [
                        "image/gif",
                        "image/jpeg",
                        "image/png",
                      ];

                      const file = e.target.files[0];
                      const image = await resizeFile(file);

                      getUploadSingleImage(
                        [image, sectionId, questions_obj.questionId, "ai-all"],
                        questions_obj,
                        "0",
                        questions_obj.category
                      );
                      CheckSumValueOfImageofAll(file, questions_obj.questionId);
                      CheckImageMetaData(
                        [file, sectionId, questions_obj.questionId, "ai-all"],
                        questions_obj_key,
                        "0",
                        questions_obj.category
                      );
                      e.target.value = null;
                    }}
                  />
                </Col>
                {questions_obj.hasOwnProperty("sample_image") &&
                !_.isEmpty(questions_obj.sample_image) ? (
                  <Col>
                    <div className="ExampleImageinPPE d-flex flex-row justify-content-end">
                      <Figure className="ImageProps">
                        <a href={String(ExampleImage)} target="_blank">
                          <Figure.Image
                            alt={
                              questions_obj.picName !== ""
                                ? questions_obj.picName
                                : null
                            }
                            src={questions_obj.sample_image}
                            className="ImageProperties"
                          />
                        </a>
                        {questions_obj.hasOwnProperty("sample_image_title") &&
                        !_.isEmpty(questions_obj.sample_image_title) ? (
                          <Figure.Caption className="caption ">
                            {questions_obj.sample_image_title}
                          </Figure.Caption>
                        ) : null}
                      </Figure>
                    </div>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </div>
          ) : (
            <div
              key={`inline-radio-${questions_obj.questionId}`}
              className="mb-3"
            >
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              <br />
              {questions_obj.pictureRequired && (
                <Form.Control
                  className="rounded-0 custom-file-input mb-3"
                  type="file"
                  onChange={(e) => {
                    getUploadImageUrl(e.target.files[0]);
                    e.target.value = null;
                  }}
                />
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <div className="mb-3">
                  {"Uploaded Image Count:" +
                    questions_obj.multiple_picture.length}
                </div>
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                  {questions_obj.multiple_picture.map((obj, i) => (
                    <Col key={i} className="item-w m-1  imgDiv">
                      <div
                        className="removeImg"
                        onClick={() =>
                          removeImgData({
                            sectionId: sectionId,
                            questionId: questions_obj.questionId,
                            idx: i,
                          })
                        }
                      >
                        x
                      </div>
                      <img
                        src={getUrlWithQueryString(obj) + IMG_TOKEN()}
                        alt={i}
                        className="shadow-sm bg-light rounded img-size_preview"
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          ))
        );

      //////////////////////////////////////////

      /////////////////////////////////////////////////
      case "Description+Dropdown+Rearrange":
        return (
          questions_obj.visibility &&
          (questions_obj.questionRearrange === true ? (
            <div>
              <Row xs={12}>
                <Col>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  <Form.Group className="mb-3">
                    <div className="dailytaskQ">
                      {questions_obj.options.map((obj, k) => {
                        return (
                          <div key={k} className="dailytaskQDivMain">
                            <div className="dailytaskQDiv" key={k}>
                              <Form.Control
                                id={"form-3-2-" + k}
                                placeholder={
                                  questions_obj.hasOwnProperty("placeholder")
                                    ? questions_obj.placeholder
                                    : "Please add a Protective Equipment."
                                }
                                className="rounded-0"
                                type="text"
                                value={obj}
                                onChange={(e) => {
                                  {
                                    changeFormTemplateDataforSpecificSection([
                                      55,
                                      e.target.value,
                                      questions_obj_key,
                                      k,
                                      sectionId,
                                    ]);
                                  }
                                }}
                              />
                              {questions_obj.options.length !== 1 ? (
                                <div className="d-flex dailytaskQDel">
                                  <img
                                    className="icon-red-color pointer"
                                    onClick={() =>
                                      DescriptionQuestionDel(
                                        k,
                                        questions_obj_key
                                      )
                                    }
                                    src={String(trashcan)}
                                    alt="delete icon"
                                  />
                                </div>
                              ) : null}
                            </div>
                            <Form.Label className="text-danger">
                              {obj === "" && "This field is required"}
                            </Form.Label>
                          </div>
                        );
                      })}
                      <div className="d-flex justify-content-center m-3">
                        <Button
                          className=" bd-highlight"
                          size="sm"
                          onClick={(e) =>
                            DescriptionQuestionAdd(
                              entiresection,
                              questions_obj_key
                            )
                          }
                        >
                          + Add More
                        </Button>
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <Row xs={12}>
                <Col>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  <Form.Group className="mb-3">
                    <div className="dailytaskQ">
                      {questions_obj.options.map((obj, k) => {
                        return (
                          <div key={k} className="dailytaskQDivMain">
                            <div className="dailytaskQDiv" key={k}>
                              <Form.Control
                                id={"form-3-2-" + k}
                                placeholder="Please add your task."
                                className="rounded-0"
                                type="text"
                                value={obj}
                                onChange={(e) => {
                                  changeFormTemplateDataforSpecificSection([
                                    36,
                                    e.target.value,
                                    questions_obj_key,
                                    k,
                                  ]);
                                }}
                              />
                              {questions_obj.options.length !== 1 ? (
                                <div className="d-flex dailytaskQDel">
                                  <img
                                    className="icon-red-color pointer"
                                    onClick={() => dailytaskQDel(k)}
                                    src={String(trashcan)}
                                    alt="delete icon"
                                  />
                                </div>
                              ) : null}
                            </div>
                            <Form.Label className="text-danger">
                              {obj === "" && "This field is required"}
                            </Form.Label>
                          </div>
                        );
                      })}
                      <div className="d-flex justify-content-center m-3">
                        <Button
                          className=" bd-highlight"
                          size="sm"
                          onClick={(e) => dailytaskQAdd(e)}
                        >
                          + Add More
                        </Button>
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          ))
        );

      case "Radio+Multiple-Image":
        return (
          questions_obj.visibility && (
            <div>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <br />

              {questions_obj.methodType === "AI" ? (
                <div>
                  <div key="inline-radio" className="mb-3 mt-3">
                    <img
                      src={String(ic_ai)}
                      alt="ai-img"
                      className="me-2"
                      style={{ width: "32px" }}
                    />
                    <Form.Check
                      inline
                      label="Yes"
                      name="group9-0"
                      type="radio"
                      id="inline-radio-9-1"
                      checked={
                        questions_obj.overridden === "Yes"
                          ? questions_obj.overridden === "Yes"
                          : questions_obj.answer === "Yes"
                      }
                      onClick={() =>
                        changeFormTemplateDataforSpecificSection([
                          26,
                          "Yes",
                          sectionId,
                          questions_obj.questionId,
                        ])
                      }
                      disabled={questions_obj.confidence === ""}
                    />
                    <Form.Check
                      inline
                      label="No"
                      name="group9-1"
                      type="radio"
                      id="inline-radio-9-2"
                      checked={
                        questions_obj.overridden === "Yes"
                          ? questions_obj.overridden === "No"
                          : questions_obj.answer === "No"
                      }
                      onClick={() =>
                        changeFormTemplateDataforSpecificSection([
                          26,
                          "No",
                          sectionId,
                          questions_obj.questionId,
                        ])
                      }
                    />
                  </div>
                  <Form.Control
                    className="rounded-0 custom-file-input"
                    type="file"
                    onChange={async (e) => {
                      let fileType = e.target.files[0]["type"];
                      let validImageTypes = [
                        "image/gif",
                        "image/jpeg",
                        "image/png",
                      ];

                      const file = e.target.files[0];
                      const image = await resizeFile(file);

                      getUploadSingleImage(
                        [
                          image,
                          sectionId,
                          questions_obj.questionId,
                          "ai-hazard-control",
                        ],
                        questions_obj_key,
                        "0",
                        questions_obj.category
                      );
                      CheckImageMetaData(
                        [
                          file,
                          sectionId,
                          questions_obj.questionId,
                          "ai-hazard-control",
                        ],
                        questions_obj_key,
                        "0",
                        questions_obj.category
                      );
                      e.target.value = null;
                    }}
                  />

                  {getFieldType === "Time Verified"
                    ? questions_obj.confidence !== "" && (
                        <div className="mt-3 mb-1 text-success">
                          {getFieldType}
                        </div>
                      )
                    : questions_obj.confidence !== "" && (
                        <div className="mt-3 mb-1 text-warning">
                          {getFieldType}
                        </div>
                      )}

                  {questions_obj.picture !== "" &&
                  typeof questions_obj.picture !== "undefined" ? (
                    <div className="p-1 m-1 imgDiv">
                      <div
                        className="removeImg"
                        onClick={() =>
                          changeFormTemplateDataforSpecificSection([
                            38,
                            questions_obj.category,
                            sectionId,
                            questions_obj.questionId,
                          ])
                        }
                      >
                        x
                      </div>
                      <img
                        src={
                          getUrlWithQueryString(questions_obj.picture) +
                          IMG_TOKEN()
                        }
                        alt={
                          questions_obj.picName !== ""
                            ? questions_obj.picName
                            : null
                        }
                        className="shadow-sm bg-light rounded img-size_preview"
                      />
                    </div>
                  ) : null}
                </div>
              ) : (
                <div>
                  <Form.Check
                    inline
                    label="Yes"
                    name={`group - ${questions_obj.questionId} `}
                    type="radio"
                    className="mb-3"
                    id="inline-radio-2"
                    checked={questions_obj.answer === "Yes"}
                    required={questions_obj.state === "Required" ? true : false}
                    onClick={(e) => {
                      onChangeFormTemplate({
                        sectionId: sectionId,
                        answer: "Yes",
                        questionId: questions_obj.questionId,
                        required: questions_obj.state,
                      });
                    }}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name={`group - ${questions_obj.questionId} `}
                    type="radio"
                    className="mb-3"
                    id="inline-radio-2"
                    checked={questions_obj.answer === "No"}
                    required={questions_obj.state === "Required" ? true : false}
                    onClick={(e) => {
                      onChangeFormTemplate({
                        sectionId: sectionId,
                        answer: "No",
                        questionId: questions_obj.questionId,
                        required: questions_obj.state,
                      });
                    }}
                  />
                  {questions_obj.pictureRequired && (
                    <Form.Control
                      className="rounded-0 custom-file-input mb-3"
                      type="file"
                      onChange={(e) => {
                        getUploadImageUrl(e.target.files[0]);
                        e.target.value = null;
                      }}
                    />
                  )}
                  {questions_obj.multiple_picture.length !== 0 && (
                    <div className="mb-3">
                      {"Uploaded Image Count:" +
                        questions_obj.multiple_picture.length}
                    </div>
                  )}
                  {questions_obj.multiple_picture.length !== 0 && (
                    <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                      {questions_obj.multiple_picture.map((obj, i) => (
                        <Col key={i} className="item-w m-1  imgDiv">
                          <div
                            className="removeImg"
                            onClick={() =>
                              removeImgData({
                                sectionId: sectionId,
                                questionId: questions_obj.questionId,
                                idx: i,
                              })
                            }
                          >
                            x
                          </div>
                          <img
                            src={getUrlWithQueryString(obj) + IMG_TOKEN()}
                            alt={i}
                            className="shadow-sm bg-light rounded img-size_preview"
                          />
                        </Col>
                      ))}
                    </Row>
                  )}
                </div>
              )}
            </div>
          )
        );
      //////////////////////////////////////

      case "Radio+Image":
        return (
          questions_obj.visibility &&
          (questions_obj.hasOwnProperty("methodType") &&
          questions_obj.methodType === "AI" ? (
            <div>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <br />
              <Form.Control
                className="rounded-0 custom-file-input"
                type="file"
                onChange={async (e) => {
                  let fileType = e.target.files[0]["type"];
                  let validImageTypes = [
                    "image/gif",
                    "image/jpeg",
                    "image/png",
                  ];

                  const file = e.target.files[0];
                  const image = await resizeFile(file);
                  getUploadSingleImage(
                    [image, sectionId, questions_obj.questionId, "ai"],
                    questions_obj_key,
                    "0",
                    questions_obj.category
                  );
                  e.target.value = null;
                  CheckSumValueOfImagePPE(file, questions_obj.questionId);
                  CheckImageMetaData(
                    [file, sectionId, questions_obj.questionId, "ai"],
                    questions_obj_key,
                    "0",
                    questions_obj.category
                  );
                }}
              />
              {questions_obj.image_metadata === "Time Verified"
                ? questions_obj.confidence !== "" && (
                    <div className="mt-3 mb-1 text-success">
                      {questions_obj.image_metadata}
                    </div>
                  )
                : questions_obj.confidence !== "" && (
                    <div className="mt-3 mb-1 text-warning">
                      {questions_obj.image_metadata}
                    </div>
                  )}

              {questions_obj.picture !== "" &&
              typeof questions_obj.picture !== "undefined" ? (
                <>
                  <div className="p-1 m-1 imgDiv">
                    <div
                      className="removeImg"
                      onClick={() =>
                        changeFormTemplateDataforSpecificSection([
                          32,
                          questions_obj.category,
                          sectionId,
                          questions_obj.questionId,
                        ])
                      }
                    >
                      x
                    </div>
                    <img
                      src={
                        getUrlWithQueryString(questions_obj.picture) +
                        IMG_TOKEN()
                      }
                      alt={
                        questions_obj.picName !== ""
                          ? questions_obj.picName
                          : null
                      }
                      className="shadow-sm bg-light rounded img-size_preview"
                    />
                  </div>
                </>
              ) : null}

              <div key="inline-radio" className="mb-3 mt-3">
                {questions_obj.methodType !== "" &&
                questions_obj.methodType === "AI" ? (
                  <img
                    src={String(ic_ai)}
                    alt="ai-img"
                    className="me-2"
                    style={{ width: "32px" }}
                  />
                ) : null}

                <Form.Check
                  inline
                  label="Yes"
                  name={`group-${questions_obj.questionId}`}
                  type="radio"
                  className="mb-3"
                  id={`inline-radio-${questions_obj.questionId}`}
                  checked={
                    questions_obj.overridden === "Yes"
                      ? questions_obj.overridden === "Yes"
                      : questions_obj.answer === "Yes"
                  }
                  onClick={() =>
                    changeFormTemplateDataforSpecificSection([
                      7,
                      "Yes",
                      sectionId,
                      questions_obj.questionId,
                    ])
                  }
                  disabled={questions_obj.confidence === ""}
                />
                <Form.Check
                  inline
                  label="No"
                  name={`group-${questions_obj.questionId}`}
                  type="radio"
                  className="mb-3"
                  id={`inline-radio-${questions_obj.questionId}`}
                  checked={
                    questions_obj.overridden === "Yes"
                      ? questions_obj.overridden === "No"
                      : questions_obj.answer === "No"
                  }
                  onClick={() =>
                    changeFormTemplateDataforSpecificSection([
                      7,
                      "No",
                      sectionId,
                      questions_obj.questionId,
                    ])
                  }
                />
              </div>

              <div></div>
            </div>
          ) : (
            <div
              key={`inline-radio-${questions_obj.questionId}`}
              className="mb-3"
            >
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              <br />

              {questions_obj.radio_options.values.map((obj_v, i) => {
                return (
                  <Form.Check
                    inline
                    label={obj_v}
                    name={`group-${questions_obj.questionId}`}
                    type="radio"
                    className="mb-3"
                    id={`inline-radio-${i}-${questions_obj.questionId}`}
                    defaultChecked={questions_obj.answer === obj_v}
                    required={questions_obj.state === "Required" ? true : false}
                    onClick={(e) => {
                      onChangeFormTemplate({
                        sectionId: sectionId,
                        answer: obj_v,
                        questionId: questions_obj.questionId,
                        required: questions_obj.state,
                      });
                    }}
                  />
                );
              })}

              {
                <Form.Control
                  className="rounded-0 custom-file-input mb-3"
                  type="file"
                  onChange={(e) => {
                    getUploadImageUrl(e.target.files[0]);
                    e.target.value = null;
                  }}
                />
              }
              {questions_obj.multiple_picture.length !== 0 && (
                <div className="mb-3">
                  {"Uploaded Image Count:" +
                    questions_obj.multiple_picture.length}
                </div>
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                  {questions_obj.multiple_picture.map((obj, i) => (
                    <Col className="item-w m-1  imgDiv">
                      <div
                        className="removeImg"
                        onClick={() =>
                          removeImgData({
                            sectionId: sectionId,
                            questionId: questions_obj.questionId,
                            idx: i,
                          })
                        }
                      >
                        x
                      </div>
                      <img
                        src={getUrlWithQueryString(obj) + IMG_TOKEN()}
                        alt={i}
                        className="shadow-sm bg-light rounded"
                      />
                    </Col>
                  ))}
                </Row>
              )}
              {questions_obj.answer === "No" &&
              questions_obj.hasOwnProperty("description_model")
                ? questions_obj.description_model[0].visible && (
                    <Form.Control
                      className="rounded-0 mb-3"
                      as="textarea"
                      rows={5}
                      id={`inline-description-${questions_obj.questionId}`}
                      value={questions_obj.description_model[0].text}
                      onChange={(e) => {
                        onChangeFormTemplate({
                          sectionId: sectionId,
                          desc_text: e.target.value,
                          questionId: questions_obj.questionId,
                          required: questions_obj.state,
                        });
                      }}
                    />
                  )
                : null}
            </div>
          ))
        );

      /////////////////////////////////////////////////

      case "Image+Text+Other":
        return (
          questions_obj.visibility && (
            <>
              <Row xs={12}>
                <Col>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  <Form.Group
                    controlId={`form-10- ${questions_obj.questionId}`}
                    className="mb-1"
                  >
                    <Form.Control
                      className="rounded-0"
                      type="text"
                      value={questions_obj.answer}
                      onChange={(e) =>
                        onChangeFormTemplate({
                          sectionId: sectionId,
                          answer: "No",
                          questionId: questions_obj.questionId,
                        })
                      }
                    />
                    <br />
                    <Form.Control
                      className="rounded-0 custom-file-input"
                      type="file"
                      onChange={async (e) => {
                        let fileType = e.target.files[0]["type"];
                        let validImageTypes = [
                          "image/gif",
                          "image/jpeg",
                          "image/png",
                        ];
                        const file = e.target.files[0];
                        const image = await resizeFile(file);
                        getUploadSingleImage([
                          image,
                          sectionId,
                          questions_obj.questionId,
                        ]);
                      }}
                    />
                    {questions_obj.picture !== "" &&
                    typeof questions_obj.picture !== "undefined" ? (
                      <div className="p-1 m-1 imgDiv">
                        <img
                          src={
                            getUrlWithQueryString(questions_obj.picture) +
                            IMG_TOKEN()
                          }
                          alt={
                            questions_obj.picName !== ""
                              ? questions_obj.picName
                              : null
                          }
                          className="shadow-sm bg-light rounded img-size_preview"
                        />
                      </div>
                    ) : null}

                    {entiresection.section[getCrewLead].questions.findIndex(
                      (question) => question.questionType === "Image+Text+Other"
                    ) ===
                    entiresection.section[getCrewLead].questions.length - 1 ? (
                      <div className="mb-4">
                        <Button
                          size="sm"
                          className="mt-3"
                          onClick={addMorePPEOther}
                        >
                          + Add More
                        </Button>
                      </div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
            </>
          )
        );

      //////////////////////////////////

      /////////////////////////////////////////////////

      case "Image+Text":
        return (
          questions_obj.visibility && (
            <>
              <Row xs={12}>
                <Col>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  <Form.Group
                    controlId={`form-10- ${questions_obj.questionId}`}
                    className="mb-1"
                  >
                    <Form.Control
                      className="rounded-0"
                      type="text"
                      value={questions_obj.answer}
                      onChange={(e) =>
                        onChangeFormTemplate({
                          sectionId: sectionId,
                          answer: "No",
                          questionId: questions_obj.questionId,
                        })
                      }
                    />
                    <br />
                    <Form.Control
                      className="rounded-0 custom-file-input"
                      type="file"
                      onChange={async (e) => {
                        let fileType = e.target.files[0]["type"];
                        let validImageTypes = [
                          "image/gif",
                          "image/jpeg",
                          "image/png",
                        ];
                        const file = e.target.files[0];
                        const image = await resizeFile(file);
                        getUploadSingleImage([
                          image,
                          sectionId,
                          questions_obj.questionId,
                        ]);
                      }}
                    />
                    {questions_obj.picture !== "" &&
                    typeof questions_obj.picture !== "undefined" ? (
                      <div className="p-1 m-1 imgDiv">
                        <img
                          src={
                            getUrlWithQueryString(questions_obj.picture) +
                            IMG_TOKEN()
                          }
                          alt={
                            questions_obj.picName !== ""
                              ? questions_obj.picName
                              : null
                          }
                          className="shadow-sm bg-light rounded img-size_preview"
                        />
                      </div>
                    ) : null}

                    {entiresection.section[getCrewLead].questions.findIndex(
                      (question) => question.questionType === "Image+Text"
                    ) ===
                    entiresection.section[getCrewLead].questions.length - 1 ? (
                      <div className="mb-4">
                        <Button
                          size="sm"
                          className="mt-3"
                          onClick={addMorePPEOther}
                        >
                          + Add More
                        </Button>
                      </div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
            </>
          )
        );

      //////////////////////////////////

      //////////////////////////////////
      case "Sub-question":
        return (
          <>
            <Form.Label>
              <span className="text-danger bold-font">
                {questions_obj.state === "Required" ? "*" : ""}
              </span>
              {questions_obj.question}
            </Form.Label>
            {questions_obj.tooltip_message !== "" &&
              questions_obj.tooltip_message !== undefined && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      {questions_obj.tooltip_message}
                    </Tooltip>
                  }
                >
                  <span className="icon-info">i</span>
                </OverlayTrigger>
              )}
            <Form.Group controlId="form-4-8-2" className="mb-3">
              <Form.Label className="Subquestion">
                <span className="text-danger bold-font">*</span>
                {questions_obj.subQuestion[getProceed12].question}
              </Form.Label>
              <Form.Select
                className="rounded-0 SubquestionText"
                type="text"
                defaultValue={questions_obj.subQuestion[getProceed12].answer}
                required={questions_obj.state === "Required" ? true : false}
                onChange={(e) => {
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");

                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                    subQuestion: true,
                    subQuestionNo: 0,
                  });
                }}
              >
                {questions_obj.subQuestion[getProceed12].options.map(
                  (obj, k) => (
                    <option key={k} value={obj}>
                      {obj}
                    </option>
                  )
                )}
              </Form.Select>

              <Form.Label className="Subquestion">
                <span className="text-danger bold-font">*</span>
                {questions_obj.subQuestion[1].question}
              </Form.Label>
              <Form.Select
                className="rounded-0 SubquestionText"
                type="text"
                defaultValue={
                  questions_obj.subQuestion[1].answer !== "" &&
                  getFirstPart(questions_obj.subQuestion[1].answer) !== "Other "
                    ? questions_obj.subQuestion[1].answer
                    : getFirstPart(questions_obj.subQuestion[1].answer).replace(
                        /^\s+|\s+$/gm,
                        ""
                      )
                }
                required={questions_obj.state === "Required" ? true : false}
                onChange={(e) => {
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");

                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                    subQuestion: true,
                    subQuestionNo: 1,
                  });
                }}
              >
                {questions_obj.subQuestion[1].options.map((obj, k) => (
                  <option key={k} value={obj}>
                    {obj}
                  </option>
                ))}
              </Form.Select>
              {getFirstPart(questions_obj.subQuestion[1].answer).replace(
                /^\s+|\s+$/gm,
                ""
              ) === "Other" && (
                <>
                  <Form.Control
                    placeholder="Please add other name."
                    className="rounded-0 SubquestionText"
                    type="text"
                    value={getSecondPart(questions_obj.subQuestion[1].answer)}
                    onChange={(e) => {
                      e.target.value === "" &&
                      questions_obj.state === "Required"
                        ? setError1("This field is required")
                        : setError1("");
                      changeFormTemplateDataforSpecificSection([
                        45,
                        e.target.value,
                        questions_obj.questionId,
                      ]);
                    }}
                  />
                  <Form.Label className="mt-2 text-danger SubquestionText">
                    {getError1}
                  </Form.Label>
                </>
              )}
            </Form.Group>
          </>
        );
      /////////////////////////////
      case "Radio+Weather":
        setWeatherStatus(true);
        return (
          questions_obj.visibility && (
            <>
              <Row xs={12}>
                <Col>
                  <Form.Group controlId="form-6-1" className="mb-3">
                    <Form.Label>
                      <span className="text-danger bold-font">
                        {questions_obj.state === "Required" ? "*" : ""}
                      </span>
                      {questions_obj.question}
                    </Form.Label>
                    <div key="inline-radio" className="mb-3">
                      <Form.Check
                        inline
                        label="Yes"
                        name={`group - ${questions_obj.questionId} `}
                        type="radio"
                        className="mb-3"
                        id="inline-radio-2"
                        checked={questions_obj.answer === "Yes"}
                        required={
                          questions_obj.state === "Required" ? true : false
                        }
                        onClick={(e) => {
                          onChangeFormTemplate({
                            sectionId: sectionId,
                            answer: "Yes",
                            questionId: questions_obj.questionId,
                            required: questions_obj.state,
                          });
                        }}
                      />
                      <Form.Check
                        inline
                        label="No"
                        name={`group - ${questions_obj.questionId} `}
                        type="radio"
                        className="mb-3"
                        id="inline-radio-2"
                        checked={questions_obj.answer === "No"}
                        required={
                          questions_obj.state === "Required" ? true : false
                        }
                        onClick={(e) => {
                          onChangeFormTemplate({
                            sectionId: sectionId,
                            answer: "No",
                            questionId: questions_obj.questionId,
                            required: questions_obj.state,
                          });
                        }}
                      />
                    </div>

                    <div className="d-flex justify-content-end">
                      <ButtonGroup>
                        {wheather_radio.map((radio, idx) => (
                          <ToggleButton
                            key={idx}
                            id={`radio-${idx}`}
                            type="radio"
                            variant={
                              idx % 2 ? "outline-danger" : "outline-danger"
                            }
                            name="radio"
                            value={radio.value}
                            checked={radioValue === radio.value}
                            onChange={(e) =>
                              setRadioValue(e.currentTarget.value)
                            }
                            className="ps-5 pe-5"
                          >
                            {radio.name}
                          </ToggleButton>
                        ))}
                      </ButtonGroup>
                    </div>
                    <>
                      <div
                        className="wrapper-w shadow p-2 m-2  rounded mt-3"
                        style={{ background: "lavender" }}
                      >
                        {!_.isEmpty(getWeatherHours) &&
                          getWeatherHours.forecasts.map((obj, k) => (
                            <Col
                              key={k}
                              lg={1}
                              md={1}
                              className=" shadow p-1 m-1 bg-white rounded bd-highlight item-w"
                            >
                              <div className="p-2 bd-highlight d-flex  justify-content-center">
                                {formatAMPM(obj.date)}
                              </div>
                              <div className="p-2 bd-highlight d-flex  justify-content-center">
                                <img
                                  style={{ width: "3em" }}
                                  className=""
                                  src={String(weatherIcon(obj.iconPhrase))}
                                  alt={obj.iconPhrase}
                                />
                              </div>
                              <div className="p-1 bd-highlight d-flex  justify-content-center">
                                {obj.temperature.value}{" "}
                                {wheather_radio[0].value === radioValue
                                  ? wheather_radio[0].name
                                  : wheather_radio[1].name}
                              </div>
                            </Col>
                          ))}
                      </div>
                      <Row className="mt-4">
                        <Col>
                          <div>
                            {questions_obj.weather_imperial.hasOwnProperty(
                              "weather_settings"
                            ) &&
                            !_.isEmpty(
                              questions_obj.weather_imperial.weather_settings
                            ) ? (
                              <div className="d-flex justify-content-center">
                                <img
                                  src={String(ic_temperature)}
                                  alt="img temperature"
                                  style={{
                                    width: "64px",
                                    filter: getTemperatureColorFilter(
                                      convertTemperature(
                                        questions_obj.weather_imperial
                                          .weather_settings.temperature.min,
                                        questions_obj.weather_imperial
                                          .weather_settings.temperature.unit,
                                        wheather_radio[0].value === radioValue
                                      ),
                                      convertTemperature(
                                        questions_obj.weather_imperial
                                          .weather_settings.temperature.max,
                                        questions_obj.weather_imperial
                                          .weather_settings.temperature.unit,
                                        wheather_radio[0].value === radioValue
                                      ),
                                      questions_obj.weather_imperial.results[
                                        getProceed12
                                      ].temperature.value,
                                      setwarningmessagerequired,
                                      warningmessagerequired
                                    ),
                                  }}
                                />
                                {warningmessagerequired.toastErrMsg ? (
                                  <div className="toast_message">
                                    <ToastMessage
                                      value={warningmessagerequired}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            ) : (
                              <div className="d-flex justify-content-center">
                                <img
                                  src={String(ic_temperature)}
                                  alt="img temperature"
                                  style={{ width: "64px" }}
                                />
                              </div>
                            )}
                            <div className="d-flex justify-content-center">
                              Temperature:{" "}
                              {
                                questions_obj.weather_imperial.results[
                                  getProceed12
                                ].temperature.value
                              }{" "}
                              {wheather_radio[0].value === radioValue
                                ? wheather_radio[0].name
                                : wheather_radio[1].name}
                              {questions_obj.weather_imperial.hasOwnProperty(
                                "weather_settings"
                              ) &&
                              !_.isEmpty(
                                questions_obj.weather_imperial.weather_settings
                              ) ? (
                                <div className="d-flex justify-content-center ">
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={
                                      <Tooltip
                                        id="tooltip-disabled"
                                        className="text_info"
                                      >
                                        Max:{" "}
                                        {convertTemperature(
                                          questions_obj.weather_imperial
                                            .weather_settings.temperature.max,
                                          questions_obj.weather_imperial
                                            .weather_settings.temperature.unit,
                                          wheather_radio[0].value === radioValue
                                        )}{" "}
                                        {wheather_radio[0].value === radioValue
                                          ? wheather_radio[0].name
                                          : wheather_radio[1].name}{" "}
                                        and Min:{" "}
                                        {convertTemperature(
                                          questions_obj.weather_imperial
                                            .weather_settings.temperature.min,
                                          questions_obj.weather_imperial
                                            .weather_settings.temperature.unit,
                                          wheather_radio[0].value === radioValue
                                        )}{" "}
                                        {wheather_radio[0].value === radioValue
                                          ? wheather_radio[0].name
                                          : wheather_radio[1].name}
                                      </Tooltip>
                                    }
                                  >
                                    <img
                                      src={String(info)}
                                      className="icon-info"
                                    />
                                  </OverlayTrigger>
                                </div>
                              ) : null}
                            </div>

                            <div
                              className="d-flex justify-content-center"
                              style={{ fontSize: "13px" }}
                            >
                              Feel:{" "}
                              {
                                questions_obj.weather_imperial.results[
                                  getProceed12
                                ].realFeelTemperature.value
                              }
                              {wheather_radio[0].value === radioValue
                                ? wheather_radio[0].name
                                : wheather_radio[1].name}{" "}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            {questions_obj.weather_imperial.hasOwnProperty(
                              "weather_settings"
                            ) &&
                            !_.isEmpty(
                              questions_obj.weather_imperial.weather_settings
                            ) ? (
                              <div className="d-flex justify-content-center">
                                <img
                                  src={String(ic_wind)}
                                  alt="img wind"
                                  style={{
                                    width: "64px",
                                    filter: getWindGustColorFilter(
                                      convertMilesPerHour(
                                        questions_obj.weather_imperial
                                          .weather_settings.wind_gust.min,
                                        questions_obj.weather_imperial
                                          .weather_settings.wind_gust.unit,
                                        wheather_radio[0].value === radioValue
                                      ),
                                      convertMilesPerHour(
                                        questions_obj.weather_imperial
                                          .weather_settings.wind_gust.max,
                                        questions_obj.weather_imperial
                                          .weather_settings.wind_gust.unit,
                                        wheather_radio[0].value === radioValue
                                      ),
                                      questions_obj.weather_imperial.results[
                                        getProceed12
                                      ].wind.speed.value,
                                      setwarningmessagerequired,
                                      warningmessagerequired
                                    ),
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="d-flex justify-content-center">
                                <img
                                  src={String(ic_wind)}
                                  alt="img wind"
                                  style={{ width: "64px" }}
                                />
                              </div>
                            )}
                            <div className="d-flex justify-content-center">
                              Wind:{" "}
                              {
                                questions_obj.weather_imperial.results[
                                  getProceed12
                                ].wind.speed.value
                              }{" "}
                              {
                                questions_obj.weather_imperial.results[
                                  getProceed12
                                ].wind.speed.unit
                              }{" "}
                              {questions_obj.weather_imperial.hasOwnProperty(
                                "weather_settings"
                              ) &&
                              !_.isEmpty(
                                questions_obj.weather_imperial.weather_settings
                              ) ? (
                                <div className="d-flex justify-content-center">
                                  <OverlayTrigger
                                    placement="right"
                                    overlay={
                                      <Tooltip
                                        id="tooltip-disabled"
                                        className="text_info"
                                      >
                                        Max:{" "}
                                        {convertMilesPerHour(
                                          questions_obj.weather_imperial
                                            .weather_settings.wind_gust.max,
                                          questions_obj.weather_imperial
                                            .weather_settings.wind_gust.unit,
                                          wheather_radio[0].value === radioValue
                                        )}{" "}
                                        {
                                          questions_obj.weather_imperial
                                            .results[getProceed12].windGust
                                            .speed.unit
                                        }{" "}
                                      </Tooltip>
                                    }
                                  >
                                    <img
                                      src={String(info)}
                                      className="icon-info"
                                    />
                                  </OverlayTrigger>
                                </div>
                              ) : null}
                            </div>
                            <div
                              className="d-flex justify-content-center"
                              style={{ fontSize: "13px" }}
                            >
                              Gust:{" "}
                              {
                                questions_obj.weather_imperial.results[
                                  getProceed12
                                ].windGust.speed.value
                              }{" "}
                              {
                                questions_obj.weather_imperial.results[
                                  getProceed12
                                ].windGust.speed.unit
                              }
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col>
                          <div>
                            <div className="d-flex justify-content-center">
                              <img
                                src={String(ic_sky)}
                                alt="img sky"
                                style={{ width: "64px" }}
                              />
                            </div>
                            <div className="d-flex justify-content-center">
                              Cloud Cover:{" "}
                              {
                                questions_obj.weather_imperial.results[
                                  getProceed12
                                ].cloudCover
                              }
                              %
                            </div>
                            <div className="d-flex justify-content-center"></div>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <div className="d-flex justify-content-center">
                              <img
                                src={String(ic_visibility)}
                                alt="img visibility"
                                style={{ width: "64px" }}
                              />
                            </div>

                            <div className="d-flex justify-content-center">
                              Visibility:{" "}
                              {
                                questions_obj.weather_imperial.results[
                                  getProceed12
                                ].visibility.value
                              }{" "}
                              {
                                questions_obj.weather_imperial.results[
                                  getProceed12
                                ].visibility.unit
                              }
                            </div>
                            <div className="d-flex justify-content-center"></div>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col>
                          <div>
                            <div className="d-flex justify-content-center">
                              <img
                                src={String(ic_humidity)}
                                alt="img humidity"
                                style={{ width: "64px" }}
                              />
                            </div>
                            <div className="d-flex justify-content-center">
                              Humidity:{" "}
                              {
                                questions_obj.weather_imperial.results[
                                  getProceed12
                                ].relativeHumidity
                              }
                              %
                            </div>
                            <div
                              className="d-flex justify-content-center"
                              style={{ fontSize: "13px" }}
                            >
                              Dew Point:{" "}
                              {
                                questions_obj.weather_imperial.results[
                                  getProceed12
                                ].dewPoint.value
                              }{" "}
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <div className="d-flex justify-content-center">
                              <img
                                src={String(ic_pressure)}
                                alt="img pressure"
                                style={{ width: "64px" }}
                              />
                            </div>

                            <div className="d-flex justify-content-center">
                              Pressure:{" "}
                              {
                                questions_obj.weather_imperial.results[
                                  getProceed12
                                ].pressure.value
                              }{" "}
                              {
                                questions_obj.weather_imperial.results[
                                  getProceed12
                                ].pressure.unit
                              }{" "}
                            </div>
                            <div className="d-flex justify-content-center"></div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-2 mb-3">
                        <Col>
                          <div>
                            <div className="d-flex justify-content-center">
                              <img
                                src={String(ic_precipitation)}
                                alt="img precipitation"
                                style={{ width: "64px" }}
                              />
                            </div>
                            <div className="d-flex justify-content-center">
                              Precipitation: 0%
                            </div>
                            <div className="d-flex justify-content-center"></div>
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <div className="d-flex justify-content-center">
                              <img
                                src={String(ic_uv_index)}
                                alt="img uv index"
                                style={{ width: "64px" }}
                              />
                            </div>

                            <div className="d-flex justify-content-center">
                              UV Index:{" "}
                              {
                                questions_obj.weather_imperial.results[
                                  getProceed12
                                ].uvIndex
                              }{" "}
                            </div>
                            <div className="d-flex justify-content-center"></div>
                          </div>
                        </Col>
                      </Row>
                    </>
                  </Form.Group>
                </Col>
              </Row>
            </>
          )
        );

      //////////////////////////////////////////////////
      case "Description":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Control
                className="rounded-0"
                as="textarea"
                rows={5}
                value={questions_obj.answer}
                required={questions_obj.state === "Required" ? true : false}
                onChange={(e) => {
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");

                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                  });
                }}
              />
              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          )
        );

      //////////////////////////////////////////////////
      case "Radio+Description":
        return (
          questions_obj.visibility &&
          (questions_obj.hasOwnProperty("radio_options") ? (
            <div
              key={`inline-radio-${questions_obj.questionId}`}
              className="mb-3"
            >
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              <br />

              {questions_obj.radio_options.values.map((obj_v, i) => {
                return (
                  <Form.Check
                    inline
                    label={obj_v}
                    name={`group-${questions_obj.questionId}`}
                    type="radio"
                    className="mb-3"
                    id={`inline-radio-${i}-${questions_obj.questionId}`}
                    defaultChecked={questions_obj.answer === obj_v}
                    required={questions_obj.state === "Required" ? true : false}
                    onClick={(e) => {
                      onChangeFormTemplate({
                        sectionId: sectionId,
                        answer: obj_v,
                        questionId: questions_obj.questionId,
                        required: questions_obj.state,
                      });
                    }}
                  />
                );
              })}

              {questions_obj.imageUpload && (
                <Form.Control
                  className="rounded-0 custom-file-input mb-3"
                  type="file"
                  onChange={(e) => {
                    getUploadImageUrl(e.target.files[0]);
                    e.target.value = null;
                  }}
                />
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <div className="mb-3">
                  {"Uploaded Image Count:" +
                    questions_obj.multiple_picture.length}
                </div>
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                  {questions_obj.multiple_picture.map((obj, i) => (
                    <Col className="item-w m-1  imgDiv">
                      <div
                        className="removeImg"
                        onClick={() =>
                          removeImgData({
                            sectionId: sectionId,
                            questionId: questions_obj.questionId,
                            idx: i,
                          })
                        }
                      >
                        x
                      </div>
                      <img
                        src={getUrlWithQueryString(obj) + IMG_TOKEN()}
                        alt={i}
                        className="shadow-sm bg-light rounded"
                      />
                    </Col>
                  ))}
                </Row>
              )}
              {questions_obj.answer === "No" &&
              questions_obj.hasOwnProperty("description_model")
                ? questions_obj.description_model[0].visible && (
                    <Form.Control
                      className="rounded-0 mb-3"
                      as="textarea"
                      rows={5}
                      id={`inline-description-${questions_obj.questionId}`}
                      value={questions_obj.description_model[0].text}
                      onChange={(e) => {
                        onChangeFormTemplate({
                          sectionId: sectionId,
                          desc_text: e.target.value,
                          questionId: questions_obj.questionId,
                          required: questions_obj.state,
                        });
                      }}
                    />
                  )
                : null}
            </div>
          ) : (
            <div
              key={`inline - radio - ${questions_obj.questionId} `}
              className="mb-3"
            >
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <br />
              <Form.Check
                inline
                label="Yes"
                name={`group - ${questions_obj.questionId} `}
                type="radio"
                className="mb-3"
                id="inline-radio-2"
                checked={questions_obj.answer === "Yes"}
                required={questions_obj.state === "Required" ? true : false}
                onClick={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: "Yes",
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                    visibility: questions_obj.visibility,
                  });
                }}
              />
              <Form.Check
                inline
                label="No"
                name={`group - ${questions_obj.questionId} `}
                type="radio"
                className="mb-3"
                id="inline-radio-2"
                checked={questions_obj.answer === "No"}
                required={questions_obj.state === "Required" ? true : false}
                onClick={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: "No",
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                    visibility: questions_obj.visibility,
                  });
                }}
              />
            </div>
          ))
        );

      //////////////////////////////////////////////////

      //////////////////////////////////////////////////
      case "Radio+Signature":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <br />
              <Form.Check
                inline
                label="No"
                name={`group - ${questions_obj.questionId} `}
                type="radio"
                className="mb-3"
                id="inline-radio-2"
                defaultChecked={questions_obj.answer === "No"}
                required={questions_obj.state === "Required" ? true : false}
                onClick={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: "No",
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                }}
              />
              <Form.Check
                inline
                label="Yes"
                name={`group - ${questions_obj.questionId} `}
                type="radio"
                className="mb-3"
                id="inline-radio-2"
                defaultChecked={questions_obj.answer === "Yes"}
                required={questions_obj.state === "Required" ? true : false}
                onClick={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: "Yes",
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                }}
              />

              <div className="mt-3 mb-1 text-warning">
                Please upload a self portrait and sign in the grey area
              </div>
              {questions_obj.pictureRequired && (
                <Form.Control
                  className="rounded-0 custom-file-input mb-3"
                  type="file"
                  onChange={(e) => {
                    getUploadImageUrl(e.target.files[0]);
                    e.target.value = null;
                  }}
                />
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <div className="mb-3">
                  {"Uploaded Image Count:" +
                    questions_obj.multiple_picture.length}
                </div>
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                  {questions_obj.multiple_picture.map((obj, i) => (
                    <Col key={i} className="item-w m-1  imgDiv">
                      <img
                        src={getUrlWithQueryString(obj) + IMG_TOKEN()}
                        alt={i}
                        className="shadow-sm bg-light rounded img-size_preview"
                      />
                    </Col>
                  ))}
                </Row>
              )}

              {questions_obj.multiple_picture.length !== 0 ? (
                <div className="col-3">
                  <Signature
                    setSignBase64Uri={setSignBase64Uri}
                    imgName="signature.png"
                    resetSign={(e) => {
                      onChangeFormTemplate({
                        sectionId: sectionId,
                        signature: "",
                        questionId: questions_obj.questionId,
                        required: questions_obj.state,
                      });
                    }}
                  />

                  {questions_obj.signature !== "" && (
                    <div className="p-1 m-1">
                      <img
                        src={
                          getUrlWithQueryString(questions_obj.signature) +
                          IMG_TOKEN()
                        }
                        alt={`Signature - ${questions_obj.questionId} `}
                        className="shadow-sm bg-light rounded img-size_preview"
                      />
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </>
          )
        );

      //////////////////////////////////////////////////
      //////////////////////////////////////////////////
      case "Description+VoicetoText":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Control
                className="rounded-0"
                as="textarea"
                rows={5}
                value={removeTags(questions_obj.answer)}
                required={questions_obj.state === "Required" ? true : false}
                onChange={(e) => {
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");

                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                  });
                }}
              />
            </>
          )
        );
      //////////////////////////////////////////////////

      //////////////////////////////////////////////////
      case "Text+VoicetoText":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Control
                className="rounded-0"
                as="textarea"
                rows={5}
                value={removeTags(questions_obj.answer)}
                required={questions_obj.state === "Required" ? true : false}
                onChange={(e) => {
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");

                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                  });
                }}
              />
              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          )
        );

      //////////////////////////////////////////////////

      //////////////////////////////////////////////////

      case "Description+Hospital":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>

              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Group controlId="form-4-2" className="mb-3">
                <div className="position-relative ">
                  <div className="position-absolute top-0 end-0 sig-controls me-3">
                    <Button
                      variant="light"
                      size="sm"
                      onClick={() =>
                        modalMapHandler1(
                          questions_obj.question,
                          getAddress,
                          changeFormTemplateData,
                          sectionId,
                          questions_obj.questionId,
                          questions_obj.lat,
                          questions_obj.long
                        )
                      }
                    >
                      <img
                        src={String(ic_trauma_center)}
                        alt="reset"
                        style={{ width: "18px" }}
                      />
                    </Button>
                  </div>
                  <Form.Control
                    className="rounded-0"
                    as="textarea"
                    rows={5}
                    value={removeTags(questions_obj.answer)}
                    onChange={(e) => {
                      e.target.value === "" &&
                      questions_obj.state === "Required"
                        ? setError1("This field is required")
                        : setError1("");
                      onChangeFormTemplate({
                        sectionId: sectionId,
                        answer: e.target.value,
                        questionId: questions_obj.questionId,
                        required: questions_obj.state,
                      });
                    }}
                  />

                  <Form.Label className="mt-2 text-danger">
                    {getError1}
                  </Form.Label>
                </div>
              </Form.Group>
            </>
          )
        );
      //////////////////////////////////////////////////
      //////////////////////////////////////////////////
      case "Description+Policestation":
        return (
          questions_obj.visibility && (
            <>
              <Row xs={12}>
                <Col>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  <Form.Group controlId="form-4-4" className="mb-3">
                    <div className="position-relative mb-1">
                      <div className="position-absolute top-0 end-0 sig-controls me-3 btnClick">
                        {questions_obj.answer === "Not Available" && (
                          <Button
                            variant="light"
                            size="sm"
                            onClick={() =>
                              changeFormTemplateDataforSpecificSection([
                                15,
                                questions_obj.question,
                                questions_obj_key,
                              ])
                            }
                          >
                            <img
                              src={String(position)}
                              alt="reset"
                              style={{ width: "18px" }}
                            />
                          </Button>
                        )}
                        {questions_obj.answer !== "Not Available" && (
                          <Nav.Link
                            className="link"
                            target="_blank"
                            href={
                              "https://www.google.com/maps/dir/" +
                              getLatitude +
                              "," +
                              getLongitude +
                              "/" +
                              questions_obj.lat +
                              "," +
                              questions_obj.long +
                              "/@" +
                              getLatitude +
                              "," +
                              getLongitude +
                              ",12z"
                            }
                          >
                            <img
                              src={String(map_directions)}
                              alt="reset"
                              style={{ width: "18px" }}
                            />
                          </Nav.Link>
                        )}
                        {questions_obj.phone_number !== "" && (
                          <Nav.Link
                            className="link"
                            href={"tel:" + questions_obj.phone_number}
                          >
                            <span className="icon-call">&#128381;</span>
                          </Nav.Link>
                        )}
                      </div>

                      <Form.Control
                        className="rounded-0"
                        as="textarea"
                        rows={5}
                        value={removeTags(questions_obj.answer)}
                        onChange={(e) => {
                          onChangeFormTemplate({
                            sectionId: sectionId,
                            answer: e.target.value,
                            questionId: questions_obj.questionId,
                            required: questions_obj.state,
                          });
                        }}
                      />
                    </div>
                    <Form.Label className="mt-2 text-danger">
                      {getError1}
                    </Form.Label>
                    <div>Distance: {questions_obj.distance}</div>
                    <div>Duration: {questions_obj.duration}</div>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          )
        );

      //////////////////////////////////////////////////

      //////////////////////////////////////////////////
      case "Description+ToDirection":
        return (
          questions_obj.visibility && (
            <>
              <Row xs={12}>
                <Col>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  <Form.Group controlId="form-4-4" className="mb-3">
                    <div className="position-relative mb-1">
                      <div className="position-absolute top-0 end-0 sig-controls me-3">
                        <Button
                          variant="light"
                          size="sm"
                          onClick={() =>
                            changeFormTemplateDataforSpecificSection([
                              16,
                              [
                                questions_obj.question,
                                [getLatitude, getLongitude],
                                [questions_obj.lat, questions_obj.long],
                                questions_obj_key,
                              ],
                              3,
                            ])
                          }
                        >
                          <img
                            src={String(map_directions)}
                            alt="reset"
                            style={{ width: "18px" }}
                          />
                        </Button>
                      </div>
                      <Form.Control
                        className="rounded-0"
                        as="textarea"
                        rows={5}
                        value={removeTags(questions_obj.answer)}
                        onChange={(e) => {
                          e.target.value === "" &&
                          questions_obj.state === "Required"
                            ? setError1("This field is required")
                            : setError1("");
                          onChangeFormTemplate({
                            sectionId: sectionId,
                            answer: e.target.value,
                            questionId: questions_obj.questionId,
                            required: questions_obj.state,
                          });
                        }}
                      />
                    </div>
                    <Form.Label className="mt-2 text-danger">
                      {getError1}
                    </Form.Label>
                    <div>Distance: {questions_obj.distance}</div>
                    <div>Duration: {questions_obj.duration}</div>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          )
        );

      //////////////////////////////////////////////////

      //////////////////////////////////////////////////
      case "Description+FromDirection":
        return (
          questions_obj.visibility && (
            <>
              <Row xs={12}>
                <Col>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  <Form.Group controlId="form-4-4" className="mb-3">
                    <div className="position-relative mb-1">
                      <div className="position-absolute top-0 end-0 sig-controls me-3">
                        <Button
                          variant="light"
                          size="sm"
                          onClick={() =>
                            changeFormTemplateDataforSpecificSection([
                              16,
                              [
                                questions_obj.question,
                                [questions_obj.lat, questions_obj.long],
                                [getLatitude, getLongitude],
                              ],
                              3,
                            ])
                          }
                        >
                          <img
                            src={String(map_directions)}
                            alt="reset"
                            style={{ width: "18px" }}
                          />
                        </Button>
                      </div>
                      <Form.Control
                        className="rounded-0"
                        as="textarea"
                        rows={5}
                        value={removeTags(questions_obj.answer)}
                        onChange={(e) => {
                          e.target.value === "" &&
                          questions_obj.state === "Required"
                            ? setError1("This field is required")
                            : setError1("");
                          onChangeFormTemplate({
                            sectionId: sectionId,
                            answer: e.target.value,
                            questionId: questions_obj.questionId,
                            required: questions_obj.state,
                          });
                        }}
                      />
                    </div>
                    <Form.Label className="mt-2 text-danger">
                      {getError1}
                    </Form.Label>
                    <div>Distance: {questions_obj.distance}</div>
                    <div>Duration: {questions_obj.duration}</div>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          )
        );

      //////////////////////////////////////////////////

      //////////////////////////////////////////////////
      case "Description+Firestation":
        return (
          questions_obj.visibility && (
            <>
              <Row xs={12}>
                <Col>
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  <Form.Group controlId="form-4-4" className="mb-3">
                    <div className="position-relative mb-1">
                      <div className="position-absolute top-0 end-0 sig-controls me-3 btnClick">
                        {questions_obj.answer === "Not Available" && (
                          <Button
                            variant="light"
                            size="sm"
                            onClick={() =>
                              changeFormTemplateDataforSpecificSection([
                                15,
                                questions_obj.question,
                                questions_obj_key,
                              ])
                            }
                          >
                            <img
                              src={String(position)}
                              alt="reset"
                              style={{ width: "18px" }}
                            />
                          </Button>
                        )}
                        {questions_obj.answer !== "Not Available" && (
                          <Nav.Link
                            className="link"
                            target="_blank"
                            href={
                              "https://www.google.com/maps/dir/" +
                              getLatitude +
                              "," +
                              getLongitude +
                              "/" +
                              questions_obj.lat +
                              "," +
                              questions_obj.long +
                              "/@" +
                              getLatitude +
                              "," +
                              getLongitude +
                              ",12z"
                            }
                          >
                            <img
                              src={String(map_directions)}
                              alt="reset"
                              style={{ width: "18px" }}
                            />
                          </Nav.Link>
                        )}
                        {questions_obj.phone_number !== "" && (
                          <Nav.Link
                            className="link"
                            href={"tel:" + questions_obj.phone_number}
                          >
                            <span className="icon-call">&#128381;</span>
                          </Nav.Link>
                        )}
                      </div>
                      <Form.Control
                        className="rounded-0"
                        as="textarea"
                        rows={5}
                        value={removeTags(questions_obj.answer)}
                        onChange={(e) => {
                          onChangeFormTemplate({
                            sectionId: sectionId,
                            answer: e.target.value,
                            questionId: questions_obj.questionId,
                            required: questions_obj.state,
                          });
                          e.target.value === "" &&
                          questions_obj.state === "Required"
                            ? setError1("This field is required")
                            : setError1("");
                        }}
                      />
                    </div>
                    <Form.Label className="mt-2 text-danger">
                      {getError1}
                    </Form.Label>
                    <div>Distance: {questions_obj.distance}</div>
                    <div>Duration: {questions_obj.duration}</div>
                  </Form.Group>
                </Col>
              </Row>
            </>
          )
        );

      //////////////////////////////////////////////////

      case "Text":
        // GetDialogueTopicValues()

        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}

              <Form.Control
                className="rounded-0"
                type="text"
                disabled={questions_obj.readonly === true ? true : false}
                value={questions_obj.answer}
                required={questions_obj.state === "Required" ? true : false}
                onChange={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");
                }}
              />
              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          )
        );

      case "Radio":
        return (
          questions_obj.visibility &&
          (questions_obj.hasOwnProperty("radio_options") ? (
            <div
              key={`inline-radio-${questions_obj.questionId}`}
              className="mb-3"
            >
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              <br />

              {questions_obj.radio_options.values.map((obj_v, i) => {
                return (
                  <Form.Check
                    inline
                    label={obj_v}
                    name={`group-${questions_obj.questionId}`}
                    type="radio"
                    className="mb-3"
                    id={`inline-radio-${i}-${questions_obj.questionId}`}
                    defaultChecked={questions_obj.answer === obj_v}
                    required={questions_obj.state === "Required" ? true : false}
                    onClick={(e) => {
                      onChangeFormTemplate({
                        sectionId: sectionId,
                        answer: obj_v,
                        questionId: questions_obj.questionId,
                        required: questions_obj.state,
                      });
                    }}
                  />
                );
              })}

              {questions_obj.imageUpload && (
                <Form.Control
                  className="rounded-0 custom-file-input mb-3"
                  type="file"
                  onChange={(e) => {
                    getUploadImageUrl(e.target.files[0]);
                    e.target.value = null;
                  }}
                />
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <div className="mb-3">
                  {"Uploaded Image Count:" +
                    questions_obj.multiple_picture.length}
                </div>
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                  {questions_obj.multiple_picture.map((obj, i) => (
                    <Col className="item-w m-1  imgDiv">
                      <div
                        className="removeImg"
                        onClick={() =>
                          removeImgData({
                            sectionId: sectionId,
                            questionId: questions_obj.questionId,
                            idx: i,
                          })
                        }
                      >
                        x
                      </div>
                      <img
                        src={getUrlWithQueryString(obj) + IMG_TOKEN()}
                        alt={i}
                        className="shadow-sm bg-light rounded"
                      />
                    </Col>
                  ))}
                </Row>
              )}
              {questions_obj.answer === "No" &&
              questions_obj.hasOwnProperty("description_model")
                ? questions_obj.description_model[0].visible && (
                    <Form.Control
                      className="rounded-0 mb-3"
                      as="textarea"
                      rows={5}
                      id={`inline-description-${questions_obj.questionId}`}
                      value={questions_obj.description_model[0].text}
                      onChange={(e) => {
                        onChangeFormTemplate({
                          sectionId: sectionId,
                          desc_text: e.target.value,
                          questionId: questions_obj.questionId,
                          required: questions_obj.state,
                        });
                      }}
                    />
                  )
                : null}
            </div>
          ) : (
            <div
              key={`inline - radio - ${questions_obj.questionId} `}
              className="mb-3"
            >
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <br />
              <Form.Check
                inline
                label="Yes"
                name={`group - ${questions_obj.questionId} `}
                type="radio"
                className="mb-3"
                id={`inline-radio-2 - ${questions_obj.questionId}`}
                checked={questions_obj.answer === "Yes"}
                required={questions_obj.state === "Required" ? true : false}
                onClick={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: "Yes",
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                    visibility: questions_obj.visibility,
                  });
                }}
              />
              <Form.Check
                inline
                label="No"
                name={`group - ${questions_obj.questionId} `}
                type="radio"
                className="mb-3"
                id={`inline-radio-2 - ${questions_obj.questionId}`}
                checked={questions_obj.answer === "No"}
                required={questions_obj.state === "Required" ? true : false}
                onClick={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: "No",
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                    visibility: questions_obj.visibility,
                  });
                }}
              />
            </div>
          ))
        );
      case "Signature":
        return (
          questions_obj.visibility && (
            <div className="col-3">
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}

              <Signature
                setSignBase64Uri={setSignBase64Uri}
                imgName="signature.png"
                resetSign={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    signature: "",
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                }}
              />
              {questions_obj.signature !== "" && (
                <div className="p-1 m-1">
                  <img
                    src={questions_obj.signature + IMG_TOKEN()}
                    alt={
                      questions_obj.picName !== ""
                        ? questions_obj.picName
                        : null
                    }
                    className="shadow-sm bg-light rounded img-size_preview"
                  />
                </div>
              )}
            </div>
          )
        );

      case "Terms&condition":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Check
                  inline
                  name={`group1-${questions_obj.questionId}`}
                  checked={questions_obj.checkbox_model[0].value}
                  label={questions_obj.checkbox_model[0].text}
                  type="checkbox"
                  id={`inline-checkbox-1-${questions_obj.questionId}`}
                  onChange={(e) => {
                    changeFormTemplateDataforSpecificSection([
                      24,
                      e.target.checked,
                      getCrewLead,
                      questions_obj.questionId,
                      questions_obj_key
                    ]);

                    e.target.checked === "" &&
                    questions_obj.state === "Required"
                      ? setError1("This field is required")
                      : setError1("");
                  }}
                />
              </Form.Group>
              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          )
        );
      case "Dropdown+Image+Comment":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Select
                aria-label={`select - ${questions_obj.questionId} `}
                className="rounded-0"
                defaultValue={questions_obj.answer}
                required={questions_obj.state === "Required" ? true : false}
                onChange={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");
                }}
              >
                <option value="">Select</option>
                {questions_obj.options.map((obj, k) => (
                  <option key={k} value={obj}>
                    {obj}
                  </option>
                ))}
              </Form.Select>
              <br />
              {questions_obj.pictureRequired && (
                <Form.Control
                  className="rounded-0 custom-file-input mb-3"
                  type="file"
                  onChange={(e) => {
                    getUploadImageUrl(e.target.files[0]);
                    e.target.value = null;
                  }}
                />
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <div className="mb-3">
                  {"Uploaded Image Count:" +
                    questions_obj.multiple_picture.length}
                </div>
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                  {questions_obj.multiple_picture.map((obj, i) => (
                    <Col key={i} className="item-w m-1  imgDiv">
                      <img
                        src={getUrlWithQueryString(obj) + IMG_TOKEN()}
                        alt={i}
                        className="shadow-sm bg-light rounded img-size_preview"
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </>
          )
        );

      //////////////////////////////
      case "Multiple-questions":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Group controlId="form-3-2" className="mb-3">
                <DailyTaskforIJHA
                  questions_obj_key={questions_obj_key}
                  form_template={form_template}
                  setForm_template={setForm_template}
                  setError={setError}
                  clearErrors={clearErrors}
                  errors={errors}
                  sectionId={sectionId}
                  getProceed={getProceed}
                />
                <div className="d-flex justify-content-center m-3"></div>
              </Form.Group>
            </>
          )
        );

      case "Table+Dropdown+Multi-select":
        return questions_obj.visibility && questions_obj.dependant_question ? (
          <>
            {setState12(true)}
            <Form.Label>
              <span className="text-danger bold-font">
                {questions_obj.state === "Required" ? "*" : ""}
              </span>
              {questions_obj.question}
            </Form.Label>
            {questions_obj.tooltip_message !== "" &&
              questions_obj.tooltip_message !== undefined && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      {questions_obj.tooltip_message}
                    </Tooltip>
                  }
                >
                  <span className="icon-info">i</span>
                </OverlayTrigger>
              )}
            <div className="HazardsDiv">
              <Form.Group controlId="form-7-1" className="mb-3">
                <MultiSelectforCrockets
                  options={HCMOptions12}
                  getOption={getHCMOptions12}
                  setOption={setHCMOptions12}
                  setHCMOptions1={setHCMOptions1}
                  setSelectedOptionsMulti12={setSelectedOptionsMulti12}
                  setSelectedOptionsMulti1={setSelectedOptionsMulti1}
                  form_template={form_template}
                  setForm_template={setForm_template}
                  getProceed={getProceed}
                />
              </Form.Group>

              {HMCAllMultiSelect12}
            </div>
          </>
        ) : (
          <>
            {setState1(true)}
            <Form.Label>
              <span className="text-danger bold-font">
                {questions_obj.state === "Required" ? "*" : ""}
              </span>
              {questions_obj.question}
            </Form.Label>
            {questions_obj.tooltip_message !== "" &&
              questions_obj.tooltip_message !== undefined && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      {questions_obj.tooltip_message}
                    </Tooltip>
                  }
                >
                  <span className="icon-info">i</span>
                </OverlayTrigger>
              )}
            <div className="HazardsDiv">
              <Form.Group controlId="form-7-1" className="mb-3">
                <MultiSelect
                  options={HCMOptions}
                  getOption={getHCMOptions}
                  setOption={setHCMOptions}
                />
              </Form.Group>
              {HMCAllMultiSelect}
            </div>
          </>
        );

      case "Dropdown+Description":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Select
                aria-label={`select - ${questions_obj.questionId} `}
                className="rounded-0"
                defaultValue={questions_obj.answer}
                required={questions_obj.state === "Required" ? true : false}
                onChange={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");
                }}
              >
                <option value="">Select</option>
                {questions_obj.options.map((obj, k) => (
                  <option key={k} value={obj}>
                    {obj}
                  </option>
                ))}
              </Form.Select>
              <br />
              {questions_obj.hasOwnProperty("description_model")
                ? questions_obj.description_model[0].visible && (
                    <Form.Control
                      className="rounded-0 mb-3"
                      as="textarea"
                      rows={5}
                      id={`inline-description-${questions_obj.questionId}`}
                      value={questions_obj.description_model[0].text}
                      onChange={(e) => {
                        onChangeFormTemplate({
                          sectionId: sectionId,
                          desc_text: e.target.value,
                          questionId: questions_obj.questionId,
                          required: questions_obj.state,
                        });
                      }}
                    />
                  )
                : null}
            </>
          )
        );

      case "Dropdown+Image":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              {
                <Form.Group controlId="form-2-4" className="mb-3">
                  <Form.Select
                    aria-label="typeofstructure"
                    className="rounded-0"
                    defaultValue={
                      questions_obj.answer !== "" &&
                      getFirstPart(questions_obj.answer) !== "Other "
                        ? questions_obj.answer
                        : getFirstPart(questions_obj.answer).replace(
                            /^\s+|\s+$/gm,
                            ""
                          )
                    }
                    onChange={(e) => {
                      changeFormTemplateDataforSpecificSection([
                        43,
                        e.target.value,
                        questions_obj.questionId,
                      ]);
                      e.target.value === "" &&
                      questions_obj.state === "Required"
                        ? setError1("This field is required")
                        : setError1("");
                    }}
                  >
                    <Form.Label className="mt-2 text-danger">
                      {getError1}
                    </Form.Label>
                    <option value="">Select</option>
                    {questions_obj.options.map((obj, k) => (
                      <option key={k} value={obj}>
                        {obj}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              }

              {questions_obj.pictureRequired && (
                <Form.Control
                  className="rounded-0 custom-file-input mb-3"
                  type="file"
                  onChange={(e) => {
                    getUploadImageUrl(e.target.files[0]);
                    e.target.value = null;
                  }}
                />
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <div className="mb-3">
                  {"Uploaded Image Count:" +
                    questions_obj.multiple_picture.length}
                </div>
              )}
              {questions_obj.multiple_picture.length !== 0 && (
                <Row className=" shadow p-1 m-1 rounded mb-3 wrapper-w">
                  {questions_obj.multiple_picture.map((obj, i) => (
                    <Col key={i} className="item-w m-1  imgDiv">
                      <img
                        src={getUrlWithQueryString(obj) + IMG_TOKEN()}
                        alt={i}
                        className="shadow-sm bg-light rounded img-size_preview"
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </>
          )
        );
      case "Radio+Image+Document":
        return (
          <GenericQuestionTypes
            questions_obj={questions_obj}
            onChangeFormTemplate={onChangeFormTemplate}
            sectionId={sectionId}
            getUploadSingleImage={getUploadSingleImage}
          />
        );

      //////////////////////////////////////////
      case "Radio+Sub-question":
        return (
          questions_obj.visibility && (
            <>
              <Col>
                <Form.Label>
                  <span className="text-danger bold-font">
                    {questions_obj.state === "Required" ? "*" : ""}
                  </span>
                  {questions_obj.question}
                </Form.Label>
                {questions_obj.tooltip_message !== "" &&
                  questions_obj.tooltip_message !== undefined && (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          {questions_obj.tooltip_message}
                        </Tooltip>
                      }
                    >
                      <span className="icon-info">i</span>
                    </OverlayTrigger>
                  )}

                <Form.Group controlId="form-3-4" className="mb-3">
                  <div key="inline-radio" className="mb-3">
                    <Form.Check
                      inline
                      label="Yes"
                      name={`group-${questions_obj.questionId}`}
                      type="radio"
                      className="mb-3"
                      id={`inline-radio-1-${questions_obj.questionId}`}
                      checked={questions_obj.answer === "Yes"}
                      required={
                        questions_obj.state === "Required" ? true : false
                      }
                      onClick={(e) => {
                        onChangeFormTemplate({
                          sectionId: sectionId,
                          answer: "Yes",
                          questionId: questions_obj.questionId,
                          required: questions_obj.state,
                        });
                      }}
                    />
                    <Form.Check
                      inline
                      label="No"
                      name={`group-${questions_obj.questionId}`}
                      type="radio"
                      className="mb-3"
                      id={`inline-radio-2-${questions_obj.questionId}`}
                      checked={questions_obj.answer === "No"}
                      required={
                        questions_obj.state === "Required" ? true : false
                      }
                      onClick={(e) => {
                        onChangeFormTemplate({
                          sectionId: sectionId,
                          answer: "No",
                          questionId: questions_obj.questionId,
                          required: questions_obj.state,
                        });
                      }}
                    />
                  </div>
                </Form.Group>
              </Col>
              {questions_obj.answer === "Yes" ? (
                <>
                  <Row xs={12} className="m-2">
                    <Col>
                      <Form.Group controlId="form-3-3-1" className="mb-3">
                        <Form.Label>
                          <span className="text-danger bold-font">*</span>
                          {questions_obj.subQuestion[0].question}
                        </Form.Label>
                        <div key="inline-radio" className="mb-3">
                          <Form.Check
                            inline
                            label="Yes"
                            name={`group3-1-${questions_obj.subQuestion[0].questionId}`}
                            type="radio"
                            id={`inline-radio-3-1-${questions_obj.subQuestion[0].questionId}`}
                            checked={
                              questions_obj.subQuestion[0].answer === "Yes"
                            }
                            onClick={(e) =>
                              onChangeFormTemplate({
                                sectionId: sectionId,
                                answer: (questions_obj.subQuestion[0].answer =
                                  "Yes"),
                                questionId:
                                  questions_obj.subQuestion[0].questionId,
                                required: questions_obj.state,
                              })
                            }
                          />
                          <Form.Check
                            inline
                            label="No"
                            name={`group3-1-${questions_obj.subQuestion[0].questionId}`}
                            type="radio"
                            id={`inline-radio-3-2-${questions_obj.subQuestion[0].questionId}`}
                            checked={
                              questions_obj.subQuestion[0].answer === "No"
                            }
                            onClick={(e) =>
                              onChangeFormTemplate({
                                sectionId: sectionId,
                                answer: (questions_obj.subQuestion[0].answer =
                                  "No"),
                                questionId:
                                  questions_obj.subQuestion[0].questionId,
                                required: questions_obj.state,
                              })
                            }
                          />
                        </div>

                        {questions_obj.subQuestion[0].answer === "Yes" &&
                        questions_obj.subQuestion[0].pictureRequired ===
                          "Yes" ? (
                          <>
                            {questions_obj.pictureRequired && (
                              <Form.Control
                                className="rounded-0 custom-file-input mb-3"
                                type="file"
                                onChange={async (e) => {
                                  const file = e.target.files[0];

                                  getUploadSingleImage(
                                    [
                                      file,
                                      sectionId,
                                      questions_obj.questionId,
                                      "subQuestionPic",
                                    ],
                                    questions_obj
                                  );
                                }}
                              />
                            )}

                            <Form.Label className="mt-2 mb-3">
                              <b>
                                {questions_obj.subQuestion[0].picture !== "" &&
                                  "1 File Uploaded"}
                              </b>
                            </Form.Label>
                          </>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              ) : null}
            </>
          )
        );

      //////////////////////////////////
      case "Dropdown":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              {questions_obj.question === "Type of Structure" &&
              questions_obj.questionId === "79" ? (
                <>
                  <Form.Select
                    aria-label={`select - ${questions_obj.questionId} `}
                    className="rounded-0"
                    defaultValue={
                      questions_obj.answer !== "" &&
                      getFirstPart(questions_obj.answer) !== "Other "
                        ? questions_obj.answer
                        : getFirstPart(questions_obj.answer).replace(
                            /^\s+|\s+$/gm,
                            ""
                          )
                    }
                    required={questions_obj.state === "Required" ? true : false}
                    onChange={(e) => {
                      changeFormTemplateDataforSpecificSection([
                        35,
                        e.target.value,
                        questions_obj.questionId,
                      ]);

                      e.target.value === "" &&
                      questions_obj.state === "Required"
                        ? setError1("This field is required")
                        : setError1("");
                    }}
                  >
                    <option value="">Select</option>
                    {questions_obj.options.map((obj, k) => (
                      <option key={k} value={obj}>
                        {obj}
                      </option>
                    ))}
                  </Form.Select>
                  {typeofstructure ? (
                    <>
                      <Form.Control
                        className="rounded-0 mt-3"
                        type="text"
                        value={getSecondPart(questions_obj.answer)}
                        onChange={(e) => {
                          e.target.value === "" &&
                          questions_obj.state === "Required"
                            ? setError1("This field is required")
                            : setError1("");
                          changeFormTemplateDataforSpecificSection([
                            44,
                            e.target.value,
                            questions_obj.questionId,
                          ]);
                        }}
                      />
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <Form.Select
                    className="rounded-0"
                    defaultValue={
                      questions_obj.answer !== "" &&
                      getFirstPart(questions_obj.answer) !== "Other "
                        ? questions_obj.answer
                        : getFirstPart(questions_obj.answer).replace(
                            /^\s+|\s+$/gm,
                            ""
                          )
                    }
                    required={questions_obj.state === "Required" ? true : false}
                    id={uuidv4()}
                    onChange={(e) => {
                      onChangeFormTemplate({
                        sectionId: sectionId,
                        Dropdown_answer: e.target.value,
                        questionId: questions_obj.questionId,
                        required: questions_obj.state,
                      });
                      e.target.value === "" &&
                      questions_obj.state === "Required"
                        ? setError1("This field is required")
                        : setError1("");
                    }}
                  >
                    <option value="">Select</option>
                    {questions_obj.options.map((obj, k) => (
                      <option key={k} value={obj}>
                        {obj}
                      </option>
                    ))}
                  </Form.Select>
                  {questions_obj.answer !== "" &&
                  /^Other-/.test(questions_obj.answer) ? (
                    <>
                      <Form.Control
                        className="rounded-0 mt-3"
                        type="text"
                        value={getSecondPart(questions_obj.answer)}
                        id={uuidv4()}
                        onChange={(e) => {
                          onChangeFormTemplate({
                            sectionId: sectionId,
                            OtherOption: e.target.value,
                            questionId: questions_obj.questionId,
                            required: questions_obj.state,
                          });
                          e.target.value === "" &&
                          questions_obj.state === "Required"
                            ? setError1("This field is required")
                            : setError1("");
                        }}
                      />
                    </>
                  ) : null}
                </>
              )}
              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          )
        );

      case "Dropdown+Text":
        return (
          questions_obj.visibility && (
            <Row xs={12}>
              <Col>
                <Form.Label>
                  <span className="text-danger bold-font">
                    {questions_obj.state === "Required" ? "*" : ""}
                  </span>
                  {questions_obj.question}
                </Form.Label>
                {questions_obj.tooltip_message !== "" &&
                  questions_obj.tooltip_message !== undefined && (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          {questions_obj.tooltip_message}
                        </Tooltip>
                      }
                    >
                      <span className="icon-info">i</span>
                    </OverlayTrigger>
                  )}
                <>
                  <Form.Select
                    className="rounded-0"
                    controlId={uuidv4()}
                    defaultValue={
                      getFirstPart(questions_obj.answer) !== "Other"
                        ? questions_obj.answer
                        : getFirstPart(questions_obj.answer)
                    }
                    required={questions_obj.state === "Required" ? true : false}
                    onChange={(e) => {
                      onChangeFormTemplate({
                        sectionId: sectionId,
                        Dropdown_answer: e.target.value,
                        questionId: questions_obj.questionId,
                        required: questions_obj.state,
                      });
                      e.target.value === "" &&
                      questions_obj.state === "Required"
                        ? setError1("This field is required")
                        : setError1("");
                    }}
                  >
                    <option value="">Select</option>
                    {questions_obj.options.map((obj, k) => (
                      <option key={k} value={obj}>
                        {obj}
                      </option>
                    ))}
                  </Form.Select>
                  {questions_obj.answer !== "" &&
                  /^Other-/.test(questions_obj.answer) ? (
                    <>
                      <Form.Control
                        controlId={uuidv4()}
                        className="rounded-0 mt-3"
                        type="text"
                        value={getSecondPart(questions_obj.answer)}
                        onChange={(e) => {
                          onChangeFormTemplate({
                            sectionId: sectionId,
                            OtherOption: e.target.value,
                            questionId: questions_obj.questionId,
                            required: questions_obj.state,
                          });
                          e.target.value === "" &&
                          questions_obj.state === "Required"
                            ? setError1("This field is required")
                            : setError1("");
                        }}
                      />
                    </>
                  ) : null}
                </>
              </Col>
            </Row>
          )
        );

      ///////////////////////////////////////////////////
      case "Multi-select":
        return (
          questions_obj.visibility && (
            <>
              <Form.Label>
                <span className="text-danger bold-font">
                  {questions_obj.state === "Required" ? "*" : ""}
                </span>
                {questions_obj.question}
              </Form.Label>
              {questions_obj.tooltip_message !== "" &&
                questions_obj.tooltip_message !== undefined && (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {questions_obj.tooltip_message}
                      </Tooltip>
                    }
                  >
                    <span className="icon-info">i</span>
                  </OverlayTrigger>
                )}
              <Form.Control
                className="rounded-0"
                type="text"
                value={questions_obj.answer}
                required={questions_obj.state === "Required" ? true : false}
                onChange={(e) => {
                  onChangeFormTemplate({
                    sectionId: sectionId,
                    answer: e.target.value,
                    questionId: questions_obj.questionId,
                    required: questions_obj.state,
                  });
                  e.target.value === "" && questions_obj.state === "Required"
                    ? setError1("This field is required")
                    : setError1("");
                }}
              />
              <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
            </>
          )
        );

      case "Description+Location":
        return (
          questions_obj.visibility && (
            <Row xs={12}>
              <Col>
                <Form.Group controlId="form-2-2" className="mb-3">
                  <Form.Label>
                    <span className="text-danger bold-font">
                      {questions_obj.state === "Required" ? "*" : ""}
                    </span>
                    {questions_obj.question}
                  </Form.Label>
                  {questions_obj.tooltip_message !== "" &&
                    questions_obj.tooltip_message !== undefined && (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {questions_obj.tooltip_message}
                          </Tooltip>
                        }
                      >
                        <span className="icon-info">i</span>
                      </OverlayTrigger>
                    )}
                  {questions_obj.readonly === true ? (
                    <div className="position-relative ">
                      <Form.Control
                        className="rounded-0"
                        disabled
                        as="textarea"
                        rows={5}
                        value={removeTags(questions_obj.answer)}
                        required={
                          questions_obj.state === "Required" ? true : false
                        }
                        onChange={(e) => {
                          e.target.value === "" &&
                          questions_obj.state === "Required"
                            ? setError1("This field is required")
                            : setError1("");
                          changeFormTemplateDataforSpecificSection([
                            1,
                            e.target.value,
                            questions_obj.questionId,
                          ]);
                        }}
                      />
                      <Form.Label className="mt-2 text-danger">
                        {getError1}
                      </Form.Label>
                    </div>
                  ) : (
                    <div className="position-relative ">
                      <div className="position-absolute top-0 end-0 sig-controls">
                        <Button
                          variant="light"
                          size="sm"
                          onClick={() =>
                            changeFormTemplateDataforSpecificSection([
                              15,
                              questions_obj.question,
                              questions_obj_key,
                            ])
                          }
                        >
                          <img
                            src={String(position)}
                            alt={
                              questions_obj.picName !== ""
                                ? questions_obj.picName
                                : null
                            }
                            style={{ width: "18px" }}
                          />
                        </Button>
                      </div>

                      <Form.Control
                        className="rounded-0"
                        as="textarea"
                        rows={5}
                        value={removeTags(questions_obj.answer)}
                        required={
                          questions_obj.state === "Required" ? true : false
                        }
                        onChange={(e) => {
                          e.target.value === "" &&
                          questions_obj.state === "Required"
                            ? setError1("This field is required")
                            : setError1("");
                          changeFormTemplateDataforSpecificSection([
                            1,
                            e.target.value,
                            questions_obj.questionId,
                          ]);
                        }}
                      />
                      <Form.Label className="mt-2 text-danger">
                        {getError1}
                      </Form.Label>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
          )
        );
      default:
        return (
          <>
            <Form.Label>
              <span className="text-danger bold-font">
                {questions_obj.state === "Required" ? "*" : ""}
              </span>
              {questions_obj.question}
            </Form.Label>
            {questions_obj.tooltip_message !== "" &&
              questions_obj.tooltip_message !== undefined && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      {questions_obj.tooltip_message}
                    </Tooltip>
                  }
                >
                  <span className="icon-info">i</span>
                </OverlayTrigger>
              )}
            <Form.Control
              className="rounded-0"
              type="text"
              value={questions_obj.answer}
              required={questions_obj.state === "Required" ? true : false}
              onChange={(e) => {
                onChangeFormTemplate({
                  sectionId: sectionId,
                  answer: e.target.value,
                  questionId: questions_obj.questionId,
                  required: questions_obj.state,
                });
                e.target.value === "" && questions_obj.state === "Required"
                  ? setError1("This field is required")
                  : setError1("");
              }}
            />
            <Form.Label className="mt-2 text-danger">{getError1}</Form.Label>
          </>
        );
    }
  }, [questions_obj, getWeatherHours]);

  return (
    <>
      <Form.Group
        controlId={`form - ${questions_obj.questionId} `}
        className={questions_obj.visibility ? "mb-3" : ""}
      >
        {return_control}
      </Form.Group>
    </>
  );
}
