import React from "react";
import { useLogout } from "../../api/Login.api";

function UserDetailError({user_details_error,setuser_details_error}) {
    const [logout] = useLogout();
    return (
        <div className="sessionPopup">
        <div className="content">
          <div className="body">
            <div>{user_details_error}</div>
          </div>
          <div className="bottom">
            <button
              id="btnSubmit"
              className="btn warning"
              onClick={() => {
                setuser_details_error("");
                logout();
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    );
}

export default UserDetailError;
